import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Animated, Platform } from 'react-native';
import coordParser from 'utils/coordParser';
import { SPOT_WIDTH, GAME_CHECK_THROTTLE } from 'utils/constants';
import { throttle } from 'lodash';
import { colors } from 'theme';

const GameSpot = ({ data, isColliding, animSpeed, setRealCoords }) => {

  // note: #animationPerformance see // https://eveningkid.medium.com/the-basics-of-react-native-animations-fb00a8ccc178
  const spotPos = useRef(new Animated.ValueXY()).current;

  const liveStyles = {
    gameSpot: {
      borderColor: isColliding ? colors.green : colors.blue,
      borderWidth: isColliding ? 3 : 2,
    },
  };

  useEffect(() => {
    if (!setRealCoords) {
      return;
    }

    spotPos.addListener(
      throttle(
        (spotPos) => {
          setRealCoords(spotPos);
        },
        GAME_CHECK_THROTTLE,
        {
          leading: true,
        }
      )
    );

    return () => {
      spotPos.stopAnimation();
      spotPos.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    Animated.timing(spotPos, {
      toValue: coordParser.parseSpotCoords(data),
      duration: animSpeed,
      useNativeDriver: Platform.OS !== 'web',
    }).start();

    return () => {
      spotPos.stopAnimation();
    };
  }, [data.x, data.y]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        transform: spotPos.getTranslateTransform(),
      }}>
      <View style={[styles.gameSpot, liveStyles.gameSpot]} />
    </Animated.View>
  );
};

const styles = {
  gameSpot: {
    position: 'absolute',
    borderRadius: SPOT_WIDTH,
    borderWidth: 2,
    /* border default. Override with live data above.*/
    borderColor: colors.blue,
    width: SPOT_WIDTH,
    height: SPOT_WIDTH,
    transform: [{ translateY: -SPOT_WIDTH / 2 }, { translateX: -SPOT_WIDTH / 2 }],
  },
};

export default GameSpot;
