import { SPOT_CONSTRAINT_RATIO } from 'utils/constants';

const MAX = SPOT_CONSTRAINT_RATIO;

// (Sine of 30 degrees)
const HALF = MAX / 2;

//'sqrt(3) / 2' constant to make plotting simpler. (Cosine of 30 degrees)
const SQT3 = 0.866 * MAX;

// arbitrary
const NUM_RANDOM_COORDS = 122;

//Logic for the prescribed motion of the "Spot" - This assumes a radius of 1 for the game area.
const zero = [0, 0];
const one = [HALF, SQT3];
const two = [SQT3, HALF];
const three = [MAX, 0];
const four = [SQT3, -HALF];
const five = [HALF, -SQT3];
const six = [0, -MAX];
const seven = [-HALF, -SQT3];
const eight = [-SQT3, -HALF];
const nine = [-MAX, 0];
const ten = [-SQT3, HALF];
const eleven = [-HALF, SQT3];
const twelve = [0, MAX];

//Stage A: 0,12
const stageA = {
  name: 'A',
  coordPairs: [zero, twelve],
};

//Stage B: 0,6
const stageB = {
  name: 'B',
  coordPairs: [zero, six],
};

//Stage C: 0,12,0,6
const stageC = {
  name: 'C',
  coordPairs: [zero, twelve, zero, six],
};

//Stage D: 0,3
const stageD = {
  name: 'D',
  coordPairs: [zero, three],
};

//Stage E: 0,9
const stageE = {
  name: 'E',
  coordPairs: [zero, nine],
};

//Stage F: 0,3,0,9
const stageF = {
  name: 'F',
  coordPairs: [zero, three, zero, nine],
};

//Stage G: 0,6,5,4,3,4,5,6
const stageG = {
  name: 'G',
  coordPairs: [zero, six, five, four, three, four, five, six],
};

//Stage H: 0,3,2,1,12,1,2,3
const stageH = {
  name: 'H',
  coordPairs: [zero, three, two, one, twelve, one, two, three],
};

//Stage I: 0,6,5,4,3,2,1,12
const stageI = {
  name: 'I',
  coordPairs: [zero, six, five, four, three, two, one, twelve],
};

//Stage J: 0,12,1,2,3,4,5,6,0,12
const stageJ = {
  name: 'J',
  coordPairs: [zero, twelve, one, two, three, four, five, six, zero, twelve],
};

//Stage K: 0,3,0,9
const stageK = {
  name: 'K',
  coordPairs: [zero, three, zero, nine],
};

//Stage L: 0,6,7,8,9,8,7,6
const stageL = {
  name: 'L',
  coordPairs: [zero, six, seven, eight, nine, eight, seven, six],
};

//Stage M: 0,9,10,11,12,11,10,9
const stageM = {
  name: 'M',
  coordPairs: [zero, nine, ten, eleven, twelve, eleven, ten, nine],
};

//Stage N: 0,6,7,8,9,10,11,12,0,6,7,8,9,10,11,12,0,6,7,8,9,10,11,12,0,6,7,8,9,10,11,12
const stageN = {
  name: 'N',
  coordPairs: [
    zero,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    zero,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    zero,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    zero,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
  ],
};

//Stage O: 0,12,11,10,9,8,7,6,0,12
const stageO = {
  name: 'O',
  coordPairs: [zero, twelve, eleven, ten, nine, eight, seven, six, zero, twelve],
};

//Stage P: 0,3,0,9
const stageP = {
  name: 'P',
  coordPairs: [zero, three, zero, nine],
};

//Stage Q: 0,6,0,12
const stageQ = {
  name: 'Q',
  coordPairs: [zero, six, zero, twelve],
};

//Stage R: 0,12,11,10,9,8,7,6,5,4,3,2,1,12
const stageR = {
  name: 'R',
  coordPairs: [zero, twelve, eleven, ten, nine, eight, seven, six, five, four, three, two, one, twelve],
};

//Stage S: 0,1,2,3,4,5,6,7,8,9,10,11,12
const stageS = {
  name: 'S',
  coordPairs: [zero, one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve],
};

//Stage T: 0,12,0,6
const stageT = {
  name: 'T',
  coordPairs: [zero, twelve, zero, six],
};

//Stage U: 0,3,0,9
const stageU = {
  name: 'U',
  coordPairs: [zero, three, zero, nine],
};

//Stage V: 0,12,1,12,1,12,1,12,1,0,7,6,7,6,7,6,7
const stageV = {
  name: 'V',
  coordPairs: [
    zero,
    twelve,
    one,
    twelve,
    one,
    twelve,
    one,
    twelve,
    one,
    zero,
    seven,
    six,
    seven,
    six,
    seven,
    six,
    seven,
  ],
};

//Stage W: 0,9,10,9,10,9,10,0,4,3,4,3,4,3,4
const stageW = {
  name: 'W',
  coordPairs: [zero, nine, ten, nine, ten, nine, ten, zero, four, three, four, three, four, three, four],
};

//Stage X: 0,8,9,8,9,8,9,0,2,3,2,3,2,3,2
const stageX = {
  name: 'X',
  coordPairs: [
    zero,
    eight,
    nine,
    eight,
    nine,
    eight,
    nine,
    zero,
    two,
    three,
    two,
    three,
    two,
    three,
    two,
  ],
};

//Stage Y: 0,10,11,10,11,10,11,0,4,5,4,5,4,5
const stageY = {
  name: 'Y',
  coordPairs: [zero, ten, eleven, ten, eleven, ten, eleven, zero, four, five, four, five, four, five],
};

//Stage Z: 0,1,2,3,4,5,6,7,8,9 10,11,12,11,10,9,8,7,6,5,4,3,2,1
const stageZ = {
  name: 'Z',
  coordPairs: [
    zero,
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    eleven,
    ten,
    nine,
    eight,
    seven,
    six,
    five,
    four,
    three,
    two,
    one,
    zero,
  ],
};

// Create array of stages for enumeration.
const gameStages = [
  stageA,
  stageB,
  stageC,
  stageD,
  stageE,
  stageF,
  stageG,
  stageH,
  stageI,
  stageJ,
  stageK,
  stageL,
  stageM,
  stageN,
  stageO,
  stageP,
  stageQ,
  stageR,
  stageS,
  stageT,
  stageU,
  stageV,
  stageW,
  stageX,
  stageY,
  stageZ,
];

const getDummyMovement = () => {
  // generate an array of coordPairs of random vals between 0 and MAX
  let i = 0;
  const fullData = [];

  while (i < NUM_RANDOM_COORDS) {
    const angle = Math.random() * 2 * Math.PI;
    const amplitude = Math.random() * MAX;

    fullData.push({
      x: Math.cos(angle) * amplitude,
      y: Math.sin(angle) * amplitude,
      name: 'Random',
    });
    i++;
  }
  return fullData;
};

export { getDummyMovement };
export default gameStages;
