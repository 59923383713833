import React from 'react';
import { useSelector } from 'react-redux';
import { View, Text } from 'react-native';
import { sizes, colors } from 'theme';
import StatHighlightBox from 'components/StatHighlightBox';
import { STAT_HIGHLIGHT_BOX_TYPES } from 'utils/constants';

const StatHighlightBoxGroup = () => {
  const {sessions, totalCals} = useSelector((state) => state.chairSessions);

  return (
    <View style={styles.root}>
      <Text style={styles.h1}>Cumulative Metrics</Text>
      <View style={styles.highlightBoxWrap}>
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.calories} />
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.milesBiked} />
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.minutesRan} />
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.steps} />
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.milesWalked} />
        <StatHighlightBox calories={totalCals} type={STAT_HIGHLIGHT_BOX_TYPES.lifetimeMinutes} />
      </View>
    </View>
  );
};

const styles = {
  root: {
    width: '100%',
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
    textAlign: 'center',
  },
  highlightBoxWrap: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
};

export default StatHighlightBoxGroup;
