import { getFormatedTime } from 'utils/calendarUtils';
const defaultSampleRate = 1000;

const TimerManager = (options={}) => {
  const noop = (() => {});
  const onChange = options.onChange || noop;
  const sampleRate = options.sampleRate || defaultSampleRate;

  const _getFormatedTime = (time=0) => {
    const milliseconds = time * sampleRate;

    return getFormatedTime(milliseconds);
  };

  const _getFormatedData = (time=0) => {
    return {
      time: time,
      formattedTime: _getFormatedTime(time),
    };
  };

  let myInterval = null;
  let data = _getFormatedData();

  const _setData = (time=0) => {
    data = _getFormatedData(time);

    return data;
  };

  const _subscribe = () => {
    myInterval = setInterval(() => {
      const newTime = data.time + 1;

      _setData(newTime);

      onChange(data);
    }, sampleRate);
  };

  const _unsubscribe = () => {
    return clearInterval(myInterval);
  };

  const self = {
    start: (startTime) => {
      // ensure we always restart
      if (myInterval) {
        self.stop();
      }

      _setData(startTime || 0);
      _subscribe();
      return self;
    },
    stop: () => {
      _unsubscribe();
      _setData();

      onChange(data);
      return self;
    },
    getData: () => data,
    getFormated: () => {
      return _getFormatedTime(data.time);
    },
  };

  return self;
};

export default TimerManager;
