import { version } from '../../app.config.js';

const companyName = `QOR360`;
const brandName = `FitterSitter`;
const brandNamePortal = `${brandName} Portal`;
const PortalDomain = `https://fittersitter-portal.qor360.com`;
// const brandName = companyName;
const companyBio = `${companyName} is a Vermont startup that makes the award winning Ariel active chair. Founded by Dr. Turner Osler, a trauma surgeon, ${companyName} aims to make sitting healthier for people by encouraging movement. The Ariel chair has a patented mechanism that allows the seat to move in all directions, thus strengthening the core and making sitting more metabolically active. ${companyName} chairs also improve posture and relieve back pain. ${companyName} chairs are the healthiest way to sit.`;
const companyQuote = `"We believe everyone can benefit from active sitting, and that it can make our communities healthier, as a whole. We’re pretty sure you’re going to love active sitting."`;
const companyQuoteAttr = `- Dr. Tuner Osler and his son Lex`;
const aboutHowItStartedTitle = `How It All Started`;
const tutorialPageTitle = `Getting Started Video`;
const aboutIntroPart1 = `After 30 years as a surgeon at the University of Vermont’s trauma center, Dr. Osler left the operating room and began a career as a research epidemiologist. This switch required hours of sitting at a computer, and despite trying a dozen different chairs, sitting all day caused him unrelenting back pain.`;
const aboutIntroPart2 = `And he knew he wasn’t alone. Americans, on average, sit for nearly 10 hours a day, and 80% suffer from sitting-induced back pain. We sit to eat, watch TV, work at a desk… sitting is designed into our lives.`;
const aboutIntroPart3 = `To Dr. Osler, sitting better meant being able to adjust his position freely, naturally, and comfortably in real time – moment to moment movement without adjustment knobs. Such movement would relieve back pain, as well as prevent the body from being passive.`;
const aboutIntroPart4 = `Long story short: finding no such chair, he invented one.`;
const sitAndPlay = `Sit & Play`;
const calibrateTitle = `Calibrate`;
const goToCalibrateCTA = `${calibrateTitle}`;
const goToGameCTA = `Play Game`;
const currentPosition = `Current Position`;
const highScore = `High Score`;
const score = `Score`;
const logout = `Log Out`;
const versionNotice = `${brandName} version: ${version}`;
const datadump = `Data Log`;
const downloadCSV = `Download CSV`;
const currentStage = `Current Stage:`;
const noDataNotice = `Track Your Calories`;
const noDataStartSessionCTA = `Start Session`;
const trackOrPlay = `Calibrate your device, or Play a Game`;
const alertText = `Logging out will remove all saved data. Continue?`;
const alertTitle = `Are you sure?`;
const viewScreen = `View Screen`;
const pleaseEnterCode = `Please enter your ${brandName} app code`;
const portalModalOnApp = `For a better app experience, visit ${PortalDomain} on a different device, and enter your unique code:`;
const portalModalOnPortal = `To screencast to this Portal, copy the unique code from your ${brandName} mobile app.`;
const pelvicClock = `Pelvic Clock`;
const greetingWithNoName = `Good Afternoon`;
const calibrate = `Calibrate`;
const syncedWithAppCode = `Synced with app code:`;
const btnStop = `Stop`;
const btnStart = `Start`;
const calibrateInstructions = `Press each arrow button while leaning fully in that direction.`;
const messagePortalNotConnected = `Portal not connected. Please go to homepage and enter your ${brandName} app code.`;

const siteCopy = {
  companyName,
  brandNamePortal,
  brandName,
  companyBio,
  companyQuote,
  companyQuoteAttr,
  aboutHowItStartedTitle,
  tutorialPageTitle,
  aboutIntroPart1,
  aboutIntroPart2,
  aboutIntroPart3,
  aboutIntroPart4,
  sitAndPlay,
  calibrateTitle,
  goToCalibrateCTA,
  goToGameCTA,
  currentPosition,
  highScore,
  score,
  logout,
  datadump,
  versionNotice,
  downloadCSV,
  noDataNotice,
  noDataStartSessionCTA,
  trackOrPlay,
  currentStage,
  alertText,
  alertTitle,
  viewScreen,
  pleaseEnterCode,
  portalModalOnApp,
  portalModalOnPortal,
  pelvicClock,
  greetingWithNoName,
  calibrate,
  syncedWithAppCode,
  btnStop,
  btnStart,
  calibrateInstructions,
  messagePortalNotConnected,
};

export default siteCopy;
