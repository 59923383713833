// colors from the styleguide.
const primary = {
  red: '#e82c2e',
  gray: '#595a5c',
  grayLight: '#7f8285',
  grayLighter: '#bebfbf',
};

const secondary = {
  blue: '#204099',
  yellow: '#ffdc17',
  red: '#9c1c21',
  blueLight: '#3e6bb5',
  blueSavedState: '#3a424b',
  yellowLight: '#fffaad', // don't use
  grayLightest: '#eaeaea',
};

const colors = {
  blue: secondary.blue,
  red: primary.red,
  redSecondary: secondary.red,
  bluePrimary: secondary.blueLight,
  blueSecondary: secondary.blueLight,
  blueSavedState: secondary.blueSavedState,
  green: '#00c000',
  greenSoft: '#4f9a67',
  greenLightest: '#d1efd3',
  gray: primary.gray,
  grayLight: primary.grayLight,
  grayLighter: primary.grayLighter,
  grayLightest: secondary.grayLightest,
  blackTrue: '#000000',
  black: '#222222',
  white: '#ffffff',
  modalBackground: '#666666ee',
};

export default colors;
