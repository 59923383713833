// a module to help auto-size images based on the aspect ratio.
// Defaults to paddingBottom 100% which will give a square aspectRatio
import React from 'react';
import { View, Image } from 'react-native';

const ImageWrap = (props) => {
  const ratio = props.ratio || 1;
  const percentageStr = `${ratio * 100}%`;

  return (
    <View
      style={[
        styles.wrap,
        props.style,
        {
          paddingBottom: percentageStr,
        },
      ]}>
      <Image source={props.source} style={[styles.img, props.imageStyle, {
        paddingBottom: percentageStr,
      }]} />
    </View>
  );
};

const styles = {
  wrap: {},
  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
};

export default ImageWrap;
