import React, {useState} from 'react';
import PropTypes from 'prop-types';
import fonts from 'theme/fonts';
import { colors, sizes, images } from 'theme';
import { Pressable, Text } from 'react-native';

const styles = {
  root: {
    paddingVertical: sizes.buttonPaddingV,
    paddingHorizontal: sizes.buttonPaddingH,
    borderRadius: sizes.borderRadiusBtn,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: sizes.base,
  },
  text: {
    fontSize: sizes.base,
    fontFamily: fonts.fontFamily,
  },
};

const Button = ({
  title,
  width,
  height,
  color,
  backgroundColor,
  onPress,
  children,
  style,
  textStyle,
}) => {
  const [isBeingPressed, setIsBeingPressed] = useState(false);
  const btnStyle = [styles.root, { width, height, backgroundColor }, style];
  const txtStyle = [styles.text, { color: style.color }, textStyle];
  return (
    <Pressable
      style={[btnStyle, {opacity: isBeingPressed ? .8 : 1}]}
      onPress={onPress}
      onPressIn={() => {
        setIsBeingPressed(true);
      }}
      onPressOut={() => {
        setIsBeingPressed(false);
      }}
    >
      {title && <Text style={txtStyle}>{title}</Text>}
      {children}
    </Pressable>
  );
};

Button.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  onPress: PropTypes.func,
  style: PropTypes.any, // should really be an array or an object
};

Button.defaultProps = {
  title: null,
  width: 'auto',
  height: 'auto',
  color: 'black',
  backgroundColor: colors.grayLighter,
  onPress: () => {},
  children: null,
  style: {},
};

export default Button;
