const SOCKET_IO_DOMAIN = process.env.SOCKET_IO_DOMAIN;
const IS_PORTAL = process.env.IS_PORTAL;

const SOCKET_IO_APP_ID = process.env.SOCKET_IO_APP_ID;
const SOCKET_IO_PORTAL_AUTH_KEY =
  process.env.SOCKET_IO_PORTAL_AUTH_KEY;
const SOCKET_IO_KEY = IS_PORTAL ? SOCKET_IO_PORTAL_AUTH_KEY : SOCKET_IO_APP_ID;

// WARNING: Only increment if you want to force clear all data.
// This is a temp, blunt tool to quickly fix schema migrations.
const DATASTORE_VERSION = 22;
const FORMATED_TIME_DEFAULT = '00:00:00';

const DEFAULT_COORDS = {
  x: 0,
  y: 0,
  z: 0,
};

const STAT_HIGHLIGHT_BOX_TYPES = {
  calories: 'calories',
  milesBiked: 'milesBiked',
  milesWalked: 'milesWalked',
  minutesRan: 'minutesRan',
  steps: 'steps',
  lifetimeMinutes: 'lifetimeMinutes',
};

const APP_TYPES = {
  app: 'app',
  portal: 'portal',
};

const APP_CONNECTION_STATUS = {
  status_200: 200,
  status_500: 500,
};

const DIRECTION_TYPES = {
  left: 'left',
  right: 'right',
  up: 'up',
  down: 'down',
};

const GAME_TYPES = {
  pelvicClock: 'pelvicClock',
  random: 'random',
  pong: 'pong',
};

const CALORIES_NAV_TYPES = {
  realtime: 'realtime',
  week: 'week',
  month: 'month',
};

const GAME_DIFFICULTY_TYPES = {
  normal: 'normal',
  hard: 'hard',
};

const CHART_TYPES = {
  realtime: 'realtime',
  week: 'week',
  twoweek: 'twoweek',
  month: 'month',
};

const CHART_DATA_TYPES = {
  calories: 'calories',
  minutesActive: 'minutesActive',
};

const TIME_TYPES = {
  day: 'day',
  hour: 'hour',
};

const SOCKET_ACTIONS = {
  appStateUpdated: 'appStateUpdated',
  chairSessionsStateUpdated: 'chairSessionsStateUpdated',
  realtimeCalsUpdated: 'realtimeCalsUpdated',
  sessionUpdated: 'sessionUpdated',
  motionUpdated: 'motionUpdated',
  gameUpdated: 'gameUpdated',
  routeUpdated: 'routeUpdated',
  subRouteUpdated: 'subRouteUpdated',
  requestDataState: 'requestDataState',
  requestSaveCalibration: 'requestSaveCalibration',
  doSaveCalibration: 'doSaveCalibration',
  dispatchProxy: 'dispatchProxy',
};

const DUMMY_CHART_DATA = [
  {
    calories: 63,
    secondsActive: 10771,
  },
  {
    calories: 23,
    secondsActive: 3864,
  },
  {
    calories: 28.1,
    secondsActive: 4459,
  },
  {
    calories: 97.5,
    secondsActive: 15476,
  },
  {
    calories: 93,
    secondsActive: 14763,
  },
  {
    calories: 125,
    secondsActive: 19841,
  },
  {
    calories: 70.4,
    secondsActive: 11171,
  },
];

const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;
const DAYS_IN_TWOWEEK = 14;
// of course a month is not always 30 days. But this shows approx a month of days.
const DAYS_IN_MONTH = 30;
// This is the data capture rate from the accelerometer and the duration of each animation.
// But then we animate between data points so it will look smoother.
const DEFAULT_SAMPLE_RATE = 100;
const ANIM_DURATION = DEFAULT_SAMPLE_RATE;
const MOCK_MOTION_SAMPLE_RATE = DEFAULT_SAMPLE_RATE;
const REALTIME_CHART_INTERVAL = 500;
// The rate between Game Spot transitions.
const GAME_SPOT_MOVE_DURATION = 2400;
// This is the size that the maxWidth gets based on.
const CONTENT_BOX_WIDTH = 320;
//Guideline sizes are based on standard ~5" screen mobile device
const GUIDELINE_WIDTH_MIN = 350;
const GUIDELINE_HEIGHT_MIN = 680;
// a width to start adding padding and clamping content
const GUIDELINE_WIDTH_WIDE = 500;
// keep it simple. Make the typical width the same as where we start clamping
const PAGE_FRAME_MAX_WIDTH = GUIDELINE_WIDTH_WIDE;
const MAX_ITER_CYCLES = 10000;
const DOT_WIDTH = 12;
const SPOT_WIDTH = DOT_WIDTH * 5;
const GAME_CHECK_THROTTLE = ANIM_DURATION;
const TRACKER_MODERATE_SCALE_FACTOR = 0.25;
// constrain the spot from the max.
// Because the chair can't fully bend to the extremes.

// TODO - correct the definition of this
// This really should be 1, which means the spot's center hits the end perfectly.
// The movement of the spot should account for the spot radius instead.
const SPOT_CONSTRAINT_RATIO = 0.8;
const PONG_BALL_WIDTH = DOT_WIDTH;
const PONG_PADDLE_WIDTH = 90;
const PONG_PADDLE_HEIGHT = PONG_PADDLE_WIDTH / 5;
const PONG_PADDLE_OFFSET = PONG_PADDLE_HEIGHT / 2;
const SUB_SESSION_SECONDS = 5;

// If the accelerometer reports anything lower than this, consider it vibration noise and ignore it.
// This ensures that no motion are counted when the phone is just sitting on a table or the user is totally still.
const MIN_MOTION_CUTOFF = 0.02;

export {
  SOCKET_IO_DOMAIN,
  IS_PORTAL,
  SOCKET_IO_APP_ID,
  SOCKET_IO_PORTAL_AUTH_KEY,
  SOCKET_IO_KEY,
  DATASTORE_VERSION,
  DEFAULT_SAMPLE_RATE,
  FORMATED_TIME_DEFAULT,
  STAT_HIGHLIGHT_BOX_TYPES,
  DEFAULT_COORDS,
  APP_TYPES,
  APP_CONNECTION_STATUS,
  DIRECTION_TYPES,
  GAME_TYPES,
  CALORIES_NAV_TYPES,
  GAME_DIFFICULTY_TYPES,
  CHART_TYPES,
  CHART_DATA_TYPES,
  TIME_TYPES,
  SOCKET_ACTIONS,
  DUMMY_CHART_DATA,
  HOURS_IN_DAY,
  DAYS_IN_WEEK,
  DAYS_IN_TWOWEEK,
  DAYS_IN_MONTH,
  ANIM_DURATION,
  MOCK_MOTION_SAMPLE_RATE,
  REALTIME_CHART_INTERVAL,
  GAME_SPOT_MOVE_DURATION,
  CONTENT_BOX_WIDTH,
  PAGE_FRAME_MAX_WIDTH,
  GUIDELINE_WIDTH_MIN,
  GUIDELINE_HEIGHT_MIN,
  GUIDELINE_WIDTH_WIDE,
  MAX_ITER_CYCLES,
  DOT_WIDTH,
  SPOT_WIDTH,
  GAME_CHECK_THROTTLE,
  TRACKER_MODERATE_SCALE_FACTOR,
  SPOT_CONSTRAINT_RATIO,
  PONG_BALL_WIDTH,
  PONG_PADDLE_WIDTH,
  PONG_PADDLE_HEIGHT,
  PONG_PADDLE_OFFSET,
  SUB_SESSION_SECONDS,
  MIN_MOTION_CUTOFF,
};

// cache bust 8.25