import React, { useState } from 'react';
import { Text, View, Modal } from 'react-native';
import { colors, sizes } from 'theme';
import { CONTENT_BOX_WIDTH } from 'utils/constants';

const ModalView = ({ children, isVisible, setIsVisible }) => {
  return (
    <Modal
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(false);
      }}
      animationType={'none'}

      // don't use these - they were breaking the style on ios
      // presentationStyle="overFullScreen"
      // transparent={true}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>{children}</View>
      </View>
    </Modal>
  );
};

const styles = {
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.modalBackground,
  },
  modalView: {
    margin: sizes.base,
    backgroundColor: 'white',
    borderRadius: sizes.md,
    padding: sizes.base * 2,
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 40,
    elevation: 5,
    // make it just a little bigger to fit the header text nicely on the portal version
    maxWidth: CONTENT_BOX_WIDTH * 1.125,
  },
};

export default ModalView;
