import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { colors, sizes } from 'theme';
import { View, Text } from 'react-native';
import { times } from 'lodash';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import { useSharedTimer } from 'utils/TimerController/TimerProvider';

import BarChartNoDataWrap from 'components/BarChartNoDataWrap';
import Speedometer from 'components/Speedometer';
import Odometer from 'components/Odometer';
import { getCalCalcFunction } from 'utils/dataUtils';
import { calcBMR } from 'utils/dataUtils';
import { REALTIME_CHART_INTERVAL, IS_PORTAL, SOCKET_ACTIONS } from 'utils/constants';

const animationTime = REALTIME_CHART_INTERVAL;
const SpeedometerTitle = 'Active Calories per Minute';
const OdometerTitle = 'Calories this Session';
const defaultCalsProxy = {
  currentCals: 0,
  sessionCals: 0,
};

const BarChartRealtime = () => {
  const { isActive } = useSelector((state) => state.activeSession);
  const { currentCals, sessionCals } = useSharedMotionState();
  const [calsDataProxy, setCalsDataProxy] = useState(defaultCalsProxy);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const realtimeCalsUpdatedHook = (data) => {
    setCalsDataProxy(
      data
        ? {
            currentCals: data.currentCals || 0,
            sessionCals: data.sessionCals || 0,
          }
        : defaultCalsProxy
    );
  };

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    isActive &&
      setCalsDataProxy({
        currentCals,
        sessionCals,
      });
  }, [currentCals, sessionCals, isActive]);

  useEffect(() => {
    if (!IS_PORTAL) {
      return;
    }

    socket.on(SOCKET_ACTIONS.realtimeCalsUpdated, realtimeCalsUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.realtimeCalsUpdated, realtimeCalsUpdatedHook);
    };
  }, [socket]);

  return (
    <View style={styles.root}>
      {isActive ? (
        <>
          <View style={styles.meterWrap}>
            <Speedometer currentCals={calsDataProxy.currentCals} />
            <Text style={styles.headerText}>{SpeedometerTitle}</Text>
          </View>
          <View style={styles.meterWrap}>
            <Odometer sessionCals={calsDataProxy.sessionCals} />
            <Text style={styles.headerText}>{OdometerTitle}</Text>
          </View>
        </>
      ) : (
        <View style={styles.inactiveStateWrap}>
          <BarChartNoDataWrap shouldNavigateToHome={false} />
        </View>
      )}
    </View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    // marginTop: sizes.base,
  },
  inactiveStateWrap: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  meterWrap: {
    marginBottom: sizes.base,
  },
  headerText: {
    fontSize: sizes.fonts.h2,
    textAlign: 'center',
    marginBottom: sizes.base,
  },
};

export default BarChartRealtime;
