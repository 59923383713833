import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View, Animated, Platform } from 'react-native';
import coordParser from 'utils/coordParser';
import { DOT_WIDTH, GAME_CHECK_THROTTLE } from 'utils/constants';
import { throttle } from 'lodash';
import { colors } from 'theme';
import { PONG_PADDLE_WIDTH, PONG_PADDLE_HEIGHT, PONG_PADDLE_OFFSET } from 'utils/constants';

const PongPaddle = ({ data, animSpeed, setRealCoords }) => {
  const { isActive } = useSelector((state) => state.activeSession);

  // note: #animationPerformance see // https://eveningkid.medium.com/the-basics-of-react-native-animations-fb00a8ccc178
  const dotPos = useRef(new Animated.ValueXY()).current;

  const animationCleanup = () => {
    dotPos.stopAnimation();
    // dotPos.removeAllListeners();
  }

  useEffect(() => {
    if (!setRealCoords) {
      return;
    }

    const throttled = throttle(
      (data) => {
        setRealCoords(data);
      },
      // speed up the throttle, or the ball will sometimes calc too slow and fall through.
      // todo: GAME_CHECK_THROTTLE Unify
      // hmm seems to work best when this is slower than the pong ball.
      // Todo: better understand this
      GAME_CHECK_THROTTLE / 2,
      {
        leading: true,
        trailing: false,
      }
    );

    dotPos.addListener((data) => {
      throttled(data);
    });

    return () => {
      animationCleanup();
    };
  }, [dotPos, setRealCoords]);

  useEffect(() => {
    dotPos.stopAnimation();

    Animated.timing(dotPos, {
      toValue: coordParser.parseDotCoords(data),
      duration: animSpeed,
      useNativeDriver: Platform.OS !== 'web',
    }).start();

    return () => {
      dotPos.stopAnimation();
    };
  }, [data.x, data.y]);

  // Grab just the X. Ignore the Y.
  const transCoordsX = dotPos.getTranslateTransform()[0];
  const transCoordsY = dotPos.getTranslateTransform()[1];
  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: 0,
        transform: [transCoordsX],
      }}>
      <View style={[styles.paddle]} />
    </Animated.View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
  },
  paddle: {
    width: PONG_PADDLE_WIDTH,
    left: -PONG_PADDLE_WIDTH / 2,
    bottom: PONG_PADDLE_OFFSET,
    height: PONG_PADDLE_HEIGHT,
    backgroudColor: colors.blue,
    borderWidth: 1,
    borderRadius: PONG_PADDLE_WIDTH,
  },
  hiddenInputWrap: {
    position: 'absolute',
    bottom: -20,
    height: 0,
    width: 0,
  },
};

export default PongPaddle;
