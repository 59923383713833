import {
  getDayProps,
  getTimeDiff,
  getPastTimes,
  yesterdays,
  yesterhours,
  getTimestamp,
} from 'utils/calendarUtils';
import { sexChoices } from 'slices/app.slice';
import { TIME_TYPES, HOURS_IN_DAY, MAX_ITER_CYCLES, MIN_MOTION_CUTOFF } from 'utils/constants';
import moment from 'moment';
import { sortBy } from 'lodash';
import { log } from 'utils/logger';

const LBS_TO_KG = 0.453592;

const CALS_PER_STEP = 0.063;

// Note: #dotDistanceConstant
const CALS_PER_DOT_DISTANCE = 0.05;
const DOT_DISTANCE_DAMPER = 75;

const CALS_PER_MILES_BIKED = 56;
const CALS_PER_MIN_RUNNING = 15;
const STEPS_PER_MILE = 2000;
const kCAL_COEFFICIENT = 0.0000191;

// TODO The old code said this was a fake number. Should we throw this out?
// FAKE NUMBER.
const SIT_TO_LIFE_TIME_RATIO = 600;

// Note: #COUNTS_COEFFICIENT This is in the old code, but not used.
const COUNTS_COEFFICIENT = 177.7594;

// Do not allow caloric burn greater than 5, as one minute in a chair should not burn more than 5 calories
const INSANE_CALS_PER_MIN = 5;

// use some best-guess averages
const defaultUserData = {
  weightLbs: 181, // 181 lbs
  age: 30,
  heightFt: 5,
  heightIn: 6,
  heightInTtl: 5 * 12 + 6, //5 ft, 6"
};

//cloning a date is used because the setDate and setHours functions mutate.
const cloneDate = (_date) => {
  const date = _date || new Date();
  return new Date(date.valueOf());
};

const dateAddDays = (numOfDays, date) => {
  const dateClone = cloneDate(date);
  return new Date(dateClone.setDate(dateClone.getDate() + (numOfDays || 0)));
};

const dateAddHours = (numOfHours, date) => {
  const dateClone = cloneDate(date);
  return new Date(dateClone.setHours(dateClone.getHours() + (numOfHours || 0)));
};

const calcCaloriesWithDotDistance = (distance, userData, bmrAdjustment) => {
  // Note: #dotDistanceConstant
  // By inspecting samples of test data, this multiplier appears to be
  // fairly close to the following expectations given by the client:
  // A person burns about 1.0 calorie/minute sitting still
  // A person burns between 1.25 and 1.5 calories/minute when moving around on an active chair.
  const adjustedCals = distance * CALS_PER_DOT_DISTANCE * bmrAdjustment;
  return adjustedCals;
};

const debugChairSession = (stats, cals, bmrAdjustment) => {
  // uncomment to debug. Keep commented for production
  // log(stats);
  // log(`Cals: ${cals}`);
  // log(`Cals per Min: ${cals / (stats.secondsActive / 60)}`);
  // log(`BMR Adjustment: ${bmrAdjustment}`);
};

// get the calorie calc function based on user data
const getCalCalcFunction = (userData) => {
  const bmrAdjustment = calcBMR(userData);

  return (distance) => {
    return calcCaloriesWithDotDistance(distance, userData, bmrAdjustment);
  };
};

const parseChairSession = (subSessions) => {
  if (!subSessions || !subSessions.length > 0) {
    throw 'cannot parse empty subSessions';
  }

  const lastSub = subSessions[subSessions.length - 1];
  const dayProps = getDayProps(new Date(lastSub.time));

  return {
    calories: lastSub.calsTtl,
    secondsActive: lastSub.secondsActive,
    hourKey: dayProps.hourKey,
    dayKey: dayProps.dayKey,
    monthKey: dayProps.monthKey,
    timestamp: dayProps.timestamp,
    subSessions: subSessions,
  };
};

const parseSubSession = (stats, userData, timestamp) => {
  // todo: figure out the deal with motion data and get back to this.
  // const cals = calcCalories(motionData, userData);
  // For now, just mock it.
  const bmrAdjustment = calcBMR(userData);
  const cals = calcCaloriesWithDotDistance(stats.distanceTotal, userData, bmrAdjustment);

  // Use roundUpNegative because this can sometimes be a tiny negative number only because of rounding tiny numbers.
  // update: roundNicely now uses floor instead of round so roundUpNegative probably isn't needed, but doesn't hurt.
  const latestCals = roundUpNegative(roundNicely(cals - stats.lastCals));

  return {
    cals: latestCals,
    calsTtl: roundNicely(cals),
    time: timestamp || getTimestamp(),
    secondsActive: stats.secondsActive,
  };
};

const getDataForDays = (numDays) => {
  return yesterdays(numDays).reverse().map(getDayProps);
};

const getDataForHours = (hours) => {
  return yesterhours(hours || HOURS_IN_DAY)
    .reverse()
    .map(getDayProps);
};

// todo temp, not sure if we need this anymore.
const getSessionLength = (sessions) => {
  const sortedSessions = sortBy(sessions, 'timestamp');
  const first = sortedSessions[0];
  const last = sortedSessions[sortedSessions.length - 1];
  const diff = getTimeDiff(first.timestamp, last.timestamp, 'days');

  return diff + 1;
};

const getSessionIndex = (sessions, type) => {
  // todo: consider consolidating these functions further down
  switch (type) {
    case TIME_TYPES.day:
      return getSessionIndexByDays(sessions);
      break;
    case TIME_TYPES.hour:
      return getSessionIndexByhours(sessions);
      break;
    default:
      throw 'error: unexpected TIME_TYPES';
  }
};

const getSessionIndexByDays = (sessions) => {
  const index = {};

  sessions.forEach((sesh) => {
    const record = index[sesh.dayKey] || {
      calories: 0,
      secondsActive: 0,
    };

    record.calories = record.calories + (sesh.calories || 0);
    record.secondsActive = record.secondsActive + (sesh.secondsActive || 0);

    index[sesh.dayKey] = record;
  });

  return index;
};

const getSessionIndexByhours = (sessions) => {
  const todayProps = getDayProps();
  const index = {};

  sessions.forEach((sesh) => {
    // we only care about today
    if (todayProps.dayKey !== sesh.dayKey) {
      return;
    }

    const record = index[sesh.hourKey] || {
      calories: 0,
      secondsActive: 0,
    };

    record.calories = record.calories + (sesh.calories || 0);
    record.secondsActive = record.secondsActive + (sesh.secondsActive || 0);

    index[sesh.hourKey] = record;
  });

  return index;
};

const calcBMR = (userData) => {
  const heightFt = userData.heightFt || defaultUserData.heightFt;
  const heightIn = userData.heightIn || defaultUserData.heightIn;
  const weightLbs = userData.weight || defaultUserData.weightLbs;
  const age = userData.age || defaultUserData.age;
  const userSex = userData.sex || null;

  const weightKg = weightLbs * LBS_TO_KG;

  // calculate male BMR
  let bmrM = 88.632 + 13.397 * weightKg;
  bmrM = bmrM + (heightFt * 146.27352 + heightIn * 2.54);
  bmrM = bmrM - 5.677 * age;
  bmrM = bmrM / (24 * 60);

  // calculate female BMR
  let bmrF = 447.593 + 9.247 * weightKg;
  bmrF = bmrF + (heightFt * 94.42704 + heightIn * 2.54);
  bmrF = bmrF - 4.33 * age;
  bmrF = bmrF / (24 * 60);

  let bmrX = (bmrM + bmrF) / 2;

  if (userSex === sexChoices.male) {
    return bmrM;
  } else if (userSex === sexChoices.female) {
    return bmrF;
  } else {
    return bmrX;
  }
};

const calcCalories = (userData) => {
  // what should these actually be?
  const countsOneMinX = secondsActive;
  const countsOneMinY = secondsActive;
  // do we need Z?
  const countsOneMinZ = 0;

  const weightLbs = userData.weight || defaultUserData.weightLbs;
  const weightKg = weightLbs * LBS_TO_KG;

  // Note: #COUNTS_COEFFICIENT This is in the old code, but commented out. Check what is correct.
  // const cpmNOTUSED = countsOneMinX * COUNTS_COEFFICIENT + countsOneMinY * COUNTS_COEFFICIENT + countsOneMinZ * COUNTS_COEFFICIENT;  //
  const cpm = countsOneMinX + countsOneMinY + countsOneMinZ;

  // not used.. Do we need this?
  const vmCpm = Math.sqrt(Math.pow(countsOneMinX, 2) + Math.pow(countsOneMinY, 2));

  let kCalPerMin = cpm * kCAL_COEFFICIENT * weightKg + calcBMR();

  // woah, easy there, sitter. Throttle them to the max.
  if (kCalPerMin > INSANE_CALS_PER_MIN) {
    kCalPerMin = INSANE_CALS_PER_MIN;
  }

  return kCalPerMin;
};

const roundNicely = (val, figs) => {
  const roundValue = figs || (val < 0.01 ? 10000 : val < 0.1 ? 1000 : val < 10 ? 100 : val < 100 ? 10 : 1);
  return Math.floor(val * roundValue) / roundValue;
};

const roundUpNegative = (val) => {
  return val <= 0 ? 0 : val;
};

const calcSteps = (cals) => {
  return roundNicely(cals / CALS_PER_STEP);
};

const calcMilesBiked = (cals) => {
  return roundNicely(cals / CALS_PER_MILES_BIKED);
};

const calcMinutesRan = (cals) => {
  return roundNicely(cals / CALS_PER_MIN_RUNNING);
};

const calcMilesWalked = (cals) => {
  return roundNicely(cals / CALS_PER_STEP / STEPS_PER_MILE);
};

const calcLifetimeMinutes = (cals) => {
  // todo: note this used to take a param of minutesSitting. See if we can just translate to keep them consistent.
  // note the old app said this was Fake data... hide for now?
  return roundNicely(cals / SIT_TO_LIFE_TIME_RATIO);
};

const calcDistances = (newCoords, oldCoords) => {
  const distX = newCoords.x - oldCoords.x;
  const distY = newCoords.y - oldCoords.y;
  const distZ = newCoords.z || 0 - oldCoords.z || 0;

  let distance = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2), Math.pow(distZ, 2));

  if (distance < MIN_MOTION_CUTOFF) {
    distance = 0;
  }

  // use the natural log function to normalize distances.
  // Give larger distances diminishing returns of influence.
  // Because we know that active sitting should not be that much greater than inactive sitting
  // (The +1 is because Math.log(1) = 0)
  // (A DOT_DISTANCE_DAMPER greater than 1 will further damp larger distances

  const normalizedDistance = Math.log(distance * DOT_DISTANCE_DAMPER + 1) / DOT_DISTANCE_DAMPER;

  return {
    distance,
    normalizedDistance,
  };
};

const calcLastDayPercChange = (index) => {
  const twoDays = yesterdays(2)
    .map(getDayProps)
    .map((x) => x.dayKey);
  const idx = index[twoDays[0]] || {};
  const idxYest = index[twoDays[1]] || {};
  const calsToday = idx.calories || 0;
  const calsYesterday = idxYest.calories || 0;

  // protect against div by 0
  const percChange = !calsYesterday ? 0 : (calsToday - calsYesterday) / calsYesterday;

  return Math.round(percChange * 100);
};

const calcActiveStreak = (index) => {
  let streak = 0;
  let nextDate;
  let nextDateKey;
  let lapse;

  while (!lapse) {
    nextDate = moment().subtract(streak, 'days').toDate();
    nextDateKey = getDayProps(nextDate).dayKey;
    lapse = !index[nextDateKey];
    !lapse && streak++;

    if (streak > MAX_ITER_CYCLES) {
      // this should never happen.
      throw 'error: bad streak data';
    }
  }

  return streak;
};

const getClampedCoords = (target, min = -1, max = 1) => {
  // if the device is upside down, min and max will need to be flipped
  const _max = max > min ? max : min;
  const _min = max > min ? min : max;
  return target > _max ? _max : target < _min ? _min : target;
};

const parseCalibratedCoords = (coords, min, max) => {
  const x = getClampedCoords(coords.x || 0, min.x, max.x);
  const y = getClampedCoords(coords.y || 0, min.y, max.y);
  const z = getClampedCoords(coords.z || 0, min.z, max.z);

  let percX = (x - min.x) / (max.x - min.x);
  let adjustedX = percX * 2 - 1;
  let percY = (y - min.y) / (max.y - min.y);
  let adjustedY = percY * 2 - 1;
  // let percZ = (z - min.z) / (max.z - min.z)
  // let adjustedZ = (percZ * 2) - 1;

  return {
    x: adjustedX,
    y: adjustedY,
    // z: adjustedZ,
    z: 0,
  };
};

export {
  roundNicely,
  parseChairSession,
  parseSubSession,
  getCalCalcFunction,
  getDataForDays,
  getDataForHours,
  getSessionIndex,
  calcBMR,
  calcSteps,
  calcMilesBiked,
  calcMinutesRan,
  calcMilesWalked,
  calcLifetimeMinutes,
  calcDistances,
  calcLastDayPercChange,
  calcActiveStreak,
  parseCalibratedCoords,
};
