import { SPOT_CONSTRAINT_RATIO } from 'utils/constants';

// temp
const NUM_RANDOM_COORDS = 1;
const MAX = SPOT_CONSTRAINT_RATIO;

const getDummyMovement = () => {
  // generate an array of coordPairs of random vals between 0 and MAX
  let OVERSHOOT_MAX = 3;
  let i = 0;
  const fullData = [];

  while (i < NUM_RANDOM_COORDS) {
    // const angle = Math.random() * 2 * Math.PI;
    // const amplitude = Math.random() * MAX;

    const angle = Math.random() * 2 * Math.PI;
    const amplitude = OVERSHOOT_MAX * MAX;

    fullData.push({
      x: Math.cos(angle) * amplitude,
      y: Math.sin(angle) * amplitude,
      name: 'Random',
    });
    i++;
  }
  return fullData;
};



const defaultSampleRate = 1000;
const PongBallGenerator = (options) => {
  const settings = options || {};
  const onChange = settings.onChange || (() => {});
  const sampleRate = options.sampleRate || defaultSampleRate;
  const gameType = options.gameType;

  const defaultData = {
    x: 0,
    y: 0,
    name: 'None',
  };

  let myInterval = null;
  let data = {};

  const getResetData = () => {
    return Object.assign({}, defaultData);
  };

  const _subscribe = () => {
    let i = 0;
    let fullData = getDummyMovement();

    myInterval = setInterval(() => {
      if (i > fullData.length) {
        i = 0;
      }

      const currData = fullData[i];

      // currData has x, y, and name
      data = currData;
      i++;

      // call the onChange function and pass a clone of the data.
      onChange(Object.assign({}, data));
    }, sampleRate);
  };

  const _unsubscribe = () => {
    return clearInterval(myInterval);
  };

  const self = {
    start: () => {
      _subscribe();
      return self;
    },
    stop: () => {
      data = getResetData();
      _unsubscribe();
      return self;
    },
    getData: () => data,
  };

  return self;
};

export default PongBallGenerator;
