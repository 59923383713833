import React from 'react';
import { colors, sizes } from 'theme';
import { View, Text } from 'react-native';
import { getDataForDays } from 'utils/dataUtils';
import BarChartDays from './BarChartDays';

import { DUMMY_CHART_DATA, DAYS_IN_WEEK } from 'utils/constants';

const numDays = DAYS_IN_WEEK;

const buildDummyIdx = () => {
  const daysData = getDataForDays(DUMMY_CHART_DATA.length);
  const indexPerDay = {};

  daysData.forEach((data, idx) => {
    indexPerDay[data.dayKey] = DUMMY_CHART_DATA[idx];
  });

  return indexPerDay;
};

const BarChartNoData = () => {
  return <BarChartDays indexPerDay={buildDummyIdx()} numDays={numDays} />;
};

export default BarChartNoData;
