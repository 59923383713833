import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View } from 'react-native';
import { sizes, colors } from 'theme';
import ContentBox from 'components/ContentBox';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import PageFrame from 'components/PageFrame';
import siteCopy from 'utils/siteCopy';
import MovementSession from 'components/MovementSession';
import MovementSessionPortal from 'components/MovementSessionPortal';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { requestCalibration } from 'slices/activeSession.slice';
import { IS_PORTAL, SOCKET_ACTIONS } from 'utils/constants';

const Calibrate = () => {
  const { portalCode } = useSelector((state) => state.app);
  const { calibration } = useSelector((state) => state.activeSession);
  const dispatch = useDispatch();
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const triggerCalibration = (which) => {
    if (IS_PORTAL) {
      socket.emit(SOCKET_ACTIONS.requestSaveCalibration, {
        shortCode: portalCode,
        which: which,
      });
    } else {
      dispatch(requestCalibration(which));
    }
  };

  return (
    <View style={styles.root}>
      <PageFrame>
        <Text style={styles.h1}>{siteCopy.calibrateTitle}</Text>
        <ContentBox useBackground={false}>
          <View style={styles.arrowBtnWrap}>
            <ButtonPrimary
              title={'←'}
              style={[styles.arrowBtn, calibration.left.isDefault ? null : styles.isCalibrated]}
              textStyle={styles.arrowBtnIcon}
              onPress={() => {
                triggerCalibration('left');
              }}
            />
            <ButtonPrimary
              title={'→'}
              style={[styles.arrowBtn, calibration.right.isDefault ? null : styles.isCalibrated]}
              textStyle={styles.arrowBtnIcon}
              onPress={() => {
                triggerCalibration('right');
              }}
            />
            <ButtonPrimary
              title={'↑'}
              style={[styles.arrowBtn, calibration.up.isDefault ? null : styles.isCalibrated]}
              textStyle={styles.arrowBtnIcon}
              onPress={() => {
                triggerCalibration('up');
              }}
            />
            <ButtonPrimary
              title={'↓'}
              style={[styles.arrowBtn, calibration.down.isDefault ? null : styles.isCalibrated]}
              textStyle={styles.arrowBtnIcon}
              onPress={() => {
                triggerCalibration('down');
              }}
            />
            <ButtonPrimary
              title={'Reset'}
              style={[styles.arrowBtn, styles.resetBtn]}
              textStyle={styles.arrowBtnIcon}
              onPress={() => {
                triggerCalibration();
              }}
            />
          </View>
        </ContentBox>
        {IS_PORTAL ? <MovementSessionPortal /> : <MovementSession />}
        <ContentBox title={'Calibration Instructions'} useBackground={false}>
          <View style={styles.textTrackedCalsWrap}>
            <Text style={styles.textStandard}>
              For accurate results it's important to calibrate FitterSitter before beginning, but
              this will take only seconds.
            </Text>
            <Text style={styles.textStandard}>
              1. Be sure your phone is synced to your computer monitor using the app code.
            </Text>
            <Text style={styles.textStandard}>
              2. Physically attach your phone either to the back of your chair (a rubber band works
              well), or to yourself (slipping your phone into the waistband of your pants at the
              small of your back works fine.)
            </Text>
            <Text style={styles.textStandard}>
              3. Press the "RESET" button to begin calibration or re-calibration.
            </Text>
            <Text style={styles.textStandard}>
              4. Tip your chair's seat as far to the left as you feel comfortable with, and push the
              blue "←" arrow button. Sit very still until the blue "←" arrow button turns green.
            </Text>
            <Text style={styles.textStandard}>
              5. Repeat this procedure with each of the three remaining blue arrow buttons. Note
              that the up arrow button asks that you tip your seat as far forward as you feel
              comfortable with, and the down arrow button asks that you tip your seat as far back as
              is comfortable.
            </Text>
            <Text style={styles.textStandard}>
              6. Once all the arrow buttons are green you're all set.
            </Text>
            <Text style={styles.textStandard}>
              7. It shouldn't be necessary to recalibrate your chair unless your phone moves in
              relation to your chair (if that's what it is attached to) or your body (if that's what
              it is attached to.)
            </Text>
          </View>
        </ContentBox>
      </PageFrame>
    </View>
  );
};

const buttonSize = sizes.buttonSize;
// don't let the arrows get cut off on a phone
const arrowButtonSize = sizes.buttonSize * 1.25;
const buttonOffset = buttonSize * 1.5;

const styles = {
  root: {
    flex: 1,
  },
  headerWrap: {
    marginBottom: sizes.base,
  },
  textNotificationWrap: {
    textAlign: 'center',
    paddingVertical: sizes.base,
  },
  textTrackedCalsWrap: {
    textAlign: 'left',
    marginBottom: sizes.sm,
  },
  textStandard: {
    paddingBottom: sizes.fonts.base,
    fontSize: sizes.fonts.base,
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginTop: sizes.base,
  },

  h2: {
    fontSize: sizes.fonts.h2,
    alignSelf: 'center',
  },
  arrowBtnWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: sizes.base,
  },
  icon: {
    textAlign: 'center',
    width: sizes.base,
  },
  arrowBtn: {
    marginRight: sizes.buttonMargin,
    height: buttonSize,
    width: buttonSize,
  },
  arrowBtnIcon: {
    textAlign: 'center',
    minWidth: buttonSize,
  },
  resetBtn: {
    width: 'auto',
  },
  isCalibrated: {
    backgroundColor: colors.greenSoft,
  },
};

export default Calibrate;
