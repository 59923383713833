import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import GameDot from 'components/GameDot';
import MovementTargetZone from 'components/MovementTargetZone';
import { colors } from 'theme';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import { ANIM_DURATION, SOCKET_ACTIONS } from 'utils/constants';

const MovementSession = () => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const {portalCode} = useSelector((state) => state.app);
  const { calibratedCoords } = useSharedMotionState();

  useEffect(() => {
    if (!portalCode) {
      return;
    }

    socket.emit(SOCKET_ACTIONS.motionUpdated, {
      dotCoords: calibratedCoords,
      shortCode: portalCode,
    });
  }, [portalCode, calibratedCoords]);

  return (
    <View style={styles.root}>
      <MovementTargetZone>
        <GameDot data={calibratedCoords} animSpeed={ANIM_DURATION} />
      </MovementTargetZone>
    </View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
  },
};

export default MovementSession;
