// With suggestions from https://redux-toolkit.js.org/usage/usage-guide
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import logger from 'redux-logger';
import appReducer from 'slices/app.slice';
import chairSessionsReducer from 'slices/chairSessions.slice';
import activeSessionReducer from 'slices/activeSession.slice';
import { DATASTORE_VERSION } from 'utils/constants';

const STORE_KEY = 'root';

const rootReducer = combineReducers({
  app: appReducer,
  chairSessions: chairSessionsReducer,
  activeSession: activeSessionReducer,
});

const persistConfig = {
  key: STORE_KEY,
  version: DATASTORE_VERSION,
  storage: AsyncStorage,
  migrate: (state) => {
    // Increment DATASTORE_VERSION to reset the store. If needed later, more advanced migrations could be used.
    // See https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
    const pastVersion = (state && state._persist.version) || 0;
    if (pastVersion !== DATASTORE_VERSION) {
      return Promise.resolve({});
    }

    return Promise.resolve(state);
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// note: The boilerplate uses 'let'. But this could use 'const' right? Test it.
let persistor = persistStore(store);

const purge = () => {
  // do we really need to pause it first? Not clear from the community advice.
  // persistor.pause();
  return persistor
    .flush()
    .then(() => {
      return persistor.purge();
    })
    .then(() => {
      // do we need to restart it here if we pause it?
      // return persistor.persist();
    });
};

export { persistor, purge };

export default store;
