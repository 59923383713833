import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';
import GameSession from 'components/GameSession/GameSession';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { GAME_TYPES, SOCKET_ACTIONS } from 'utils/constants';

const GameSessionApp = ({ gameDifficulty, gameType, highScore, onGameOver, doEndGame }) => {
  const {portalCode} = useSelector((state) => state.app);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const [score, setScore] = useState(0);
  const [currentStage, setCurrentStage] = useState('A');
  const liveScore = useRef(0);

  useEffect(() => {
    socket.emit(SOCKET_ACTIONS.gameUpdated, {
      highScore: highScore,
      score: score,
      shortCode: portalCode,
      stageName: currentStage,
    });
  }, [portalCode, score, highScore, currentStage]);

  const incScore = (unit) => {
    const newScore = score + (unit || 1);
    setScore(newScore);
    liveScore.current = newScore;
  };

  return (
    <GameSession
      gameDifficulty={gameDifficulty}
      gameType={gameType}
      highScore={highScore}
      onGameOver={onGameOver}
      doEndGame={doEndGame}
      score={score}
      incScore={incScore}
      currentStage={currentStage}
      setCurrentStage={setCurrentStage}
    />
  );
};


export default GameSessionApp;
