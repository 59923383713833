import React from 'react';
import { Text, View } from 'react-native';
import { sizes, colors } from 'theme';
import PageFrameCenter from 'components/PageFrameCenter';
import CaloriesTabsNavigator from 'navigator/CaloriesTabs';
import StatHighlightBoxGroup from 'components/StatHighlightBoxGroup';
import ContentBox from 'components/ContentBox';

const strs = {
  h1: `Calories`,
};

const Calories = ({ navigation }) => {
  return (
    <View style={styles.root}>
      <PageFrameCenter style={styles.pageView}>
        <Text style={styles.h1}>{strs.h1}</Text>
        <CaloriesTabsNavigator />
        <StatHighlightBoxGroup />
      </PageFrameCenter>
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
  },
  pageView: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
  },
};

export default Calories;
