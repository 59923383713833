import React from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';
import ShadowBox from 'components/ShadowBox';
import coordParser from 'utils/coordParser';

const MovementTargetZone = ({ children }) => {
  const { isActive } = useSelector((state) => state.activeSession);
  const trackerWidth = coordParser.getTrackerWidth();

  const liveStyles = {
    trackerWrap: {
      width: trackerWidth,
      height: trackerWidth,
      borderRadius: trackerWidth / 2,
    },
    innerShadow: {
      opacity: isActive ? 1 : 0,
      width: trackerWidth,
      height: trackerWidth,
      borderRadius: trackerWidth / 2,
    },
  };

  return (
    <View style={styles.root}>
      <ShadowBox style={[styles.trackerWrap, liveStyles.trackerWrap]} spread={2}>
        <ShadowBox style={[styles.innerShadow, liveStyles.innerShadow]} color={colors.red} />
        <Text style={styles.crossHatch}>+</Text>
        {children}
      </ShadowBox>
    </View>
  );
};

// this is used as a font-size, so the positioning won't be strictly perfect, but looks fine.
const crossHatchSize = sizes.xl;

const styles = {
  root: {},
  trackerWrap: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerShadow: {
    height: '100%',
    position: 'absolute',
  },
  crossHatch: {
    fontSize: crossHatchSize,
    height: crossHatchSize,
    width: crossHatchSize,
    lineHeight: crossHatchSize,
    color: colors.grayLighter,
    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: [{ translateY: -crossHatchSize / 2 }, { translateX: -crossHatchSize / 2 }],
  },
};

export default MovementTargetZone;
