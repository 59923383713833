import React from 'react';
import Button from './Button';
import { colors } from 'theme';

const ButtonPrimary = (props) => {
  return <Button {...props} style={[styles.button, props.style]} textStyle={[styles.textStyle, props.textStyle || {}]} />;
};

const styles = {
  button: {
    backgroundColor: colors.blueSecondary,
    textTransform: 'uppercase',
  },
  textStyle: {
    textTransform: 'uppercase',
    color: colors.white,
  },
};

export default ButtonPrimary;
