// update here and in package.json "version"
const VERSION = '1.3.8';
const VERSION_INT = 30;

module.exports = {
  version: VERSION,
  expo: {
    name: 'FitterSitter',
    slug: 'qor360-react-native-app',
    description: 'FitterSitter is a free companion app for the QOR360 Ariel Chair.',
    privacy: 'hidden',
    owner: 'erchaves',
    platforms: [
      'ios',
      'android',
      'web'
    ],
    version: VERSION,
    orientation: 'portrait',
    icon: './assets/logos/qor360-logo-square-red-1024.png',
    userInterfaceStyle: 'light',
    splash: {
      image: './assets/logos/qor360-logo-square-white.png',
      resizeMode: 'contain',
      backgroundColor: '#e82c2e'
    },
    updates: {
      fallbackToCacheTimeout: 0,
      url: 'https://u.expo.dev/82e2fb19-9261-4777-b76a-95bde0c27cf0'
    },
    assetBundlePatterns: [
      '**/*'
    ],
    ios: {
      supportsTablet: true,
      bundleIdentifier: 'com.syllablehq.qor360reactnativeapp',
      buildNumber: VERSION
    },
    android: {
      package: 'com.syllablehq.qor360reactnativeapp',
      versionCode: VERSION_INT,
      versionName: VERSION,
      adaptiveIcon: {
        foregroundImage: './assets/logos/qor360-logo-square-white.png',
        backgroundColor: '#e82c2e'
      },
      permissions: [
        'android.permission.RECORD_AUDIO',
        'android.permission.HIGH_SAMPLING_RATE_SENSORS'
      ]
    },
    web: {
      favicon: './assets/favicons/apple-touch-icon.png'
    },
    plugins: [
      [
        'expo-image-picker',
        {
          photosPermission: 'This app accesses your photos to allow you to customize your profile.'
        }
      ]
    ],
    extra: {
      eas: {
        projectId: '82e2fb19-9261-4777-b76a-95bde0c27cf0'
      },
    },
    runtimeVersion: {
      policy: 'sdkVersion'
    }
  }
};
