import { sizes } from 'theme';
import { moderateScale, moderateScaleVertical } from 'utils/scaling';
import { TRACKER_MODERATE_SCALE_FACTOR } from 'utils/constants';

// arbitrary value to make it fit nicely in the middle of the view instead of hitting the edges.
const scaleFactor = 0.5;

const parse = (coords) => {
  const trackerWidth = getTrackerWidth();

  return {
    x: trackerWidth * coords.x * scaleFactor || 0,
    // note #flipYCoords
    y: trackerWidth * -coords.y * scaleFactor || 0,
    z: trackerWidth * coords.z * scaleFactor || 0,
  };
};

const getTrackerWidth = () => {
  const trackerMaxWidth = moderateScale(sizes.contentBoxWidth, TRACKER_MODERATE_SCALE_FACTOR);
  const trackerMaxHeight = moderateScaleVertical(
    sizes.contentBoxWidth,
    TRACKER_MODERATE_SCALE_FACTOR
  );
  const trackerSize = trackerMaxWidth > trackerMaxHeight ? trackerMaxHeight : trackerMaxWidth;

  const trackerWidth = trackerSize > sizes.contentBoxWidth ? trackerSize : sizes.contentBoxWidth;

  return trackerWidth;
};

const coordParser = {
  parseDotCoords: (coords) => {
    return parse(coords);
  },
  parseSpotCoords: (coords) => {
    return parse(coords);
  },
  getTrackerWidth,
};

export default coordParser;
