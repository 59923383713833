import React from 'react';
import io from 'socket.io-client';
import { APP_TYPES, IS_PORTAL, SOCKET_IO_KEY, SOCKET_IO_DOMAIN, APP_CONNECTION_STATUS } from 'utils/constants';
import { log } from 'utils/logger';

const SocketManager = (options) => {
  const settings = options || {};
  const handleStatusUpdate = settings.handleStatusUpdate || (() => {});
  const getPortalCode = settings.getPortalCode || (() => {});

  const mockSocket = {
    isMock: true,
    emit: () => {
      log('mock function: socket.emit');
    },
    on: () => {
      log('mock function: socket.on');
    },
    off: () => {
      log('mock function: socket.off');
    }
  }

  let _socket = mockSocket;
  let _onInitPromise = null;
  const _joinRoom = (portalCode) => {
    if (_socket.isMock) {
      log(`Warning: socket doesn't exist`);
      return;
    }

    _socket.emit(
      'roomRequest',
      {
        shortCode: portalCode,
      },
      (response) => {
        log(response); // ok
      }
    );
  };

  const sub = (socket, resolve) => {
    socket.on('connect_error', (err) => {
      log(err.message); // not authorized
      handleStatusUpdate(APP_CONNECTION_STATUS.status_500);
    });

    socket.on('disconnect', () => {
      log('disconnecting socket....');
      handleStatusUpdate(APP_CONNECTION_STATUS.status_500);
    });

    socket.on('connect', () => {
      const socketId = socket.id;
      handleStatusUpdate(APP_CONNECTION_STATUS.status_200);
      const portalCode = getPortalCode();

      // this happens when we reconnect after a network change.
      if (!!portalCode) {
        _joinRoom(portalCode);

        return;
      }

      // else
      if (IS_PORTAL) {
        resolve();
      } else {
        socket.emit(
          'roomRequest',
          {
            id: socketId,
          },
          (responseData) => {
            let { shortCode = '', message = '' } = responseData || {};
            resolve(shortCode);
          }
        );
      }
    });

    return self;
  };

  const unsub = (socket) => {
    // request that the server kick us out of all rooms
    socket.emit('end');

    socket.off('connect_error');
    socket.off('disconnect');
    socket.off('connect');
  };

  const disconnect = () => {
    if (!_socket || _socket.isMock) {
      return;
    }

    unsub(_socket);
    _socket = mockSocket;
  };

  const init = (resolve) => {
    _socket = io(SOCKET_IO_DOMAIN, {
      auth: {
        token: SOCKET_IO_KEY,
        type: IS_PORTAL ? APP_TYPES.portal : APP_TYPES.app,
      },
    });

    sub(_socket, resolve);

    return self;
  };

  const start = () => {
    // A promise that can be then'ed to regardless of whether the init completed or not.
    if (!SOCKET_IO_DOMAIN) {
      _onInitPromise = new Promise((resolve, reject) => {
        reject('Missing valid SOCKET_IO_DOMAIN');
      });
    } else {
      _onInitPromise = new Promise((resolve) => {
        init(resolve);
      });
    }

    return self;
  };

  const self = {
    getSocket: () => _socket,
    ensureInit: () => _onInitPromise,
    disconnect: () => {
      disconnect();
      return self;
    },
    restart: () => {
      disconnect();
      start();
      return self;
    },
    joinRoom: _joinRoom,
  };

  start();

  return self;
};

export default SocketManager;
