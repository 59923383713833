import React, { useState, useEffect, useContext, useRef } from 'react';
import { colors, sizes } from 'theme';
import DotInTheSpot from 'components/Games/DotInTheSpot/DotInTheSpot';
import SocketProvider from 'utils/SocketController/SocketProvider';
import {
  SOCKET_ACTIONS,
  GAME_DIFFICULTY_TYPES,
  ANIM_DURATION,
  GAME_SPOT_MOVE_DURATION,
  DEFAULT_COORDS,
} from 'utils/constants';

const DotInTheSpotPortal = ({ gameDifficulty }) => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const spotAnimSpeed =
    gameDifficulty === GAME_DIFFICULTY_TYPES.hard
      ? GAME_SPOT_MOVE_DURATION * 0.5
      : GAME_SPOT_MOVE_DURATION;

  const [dotTarget, setDotTarget] = useState(DEFAULT_COORDS);
  const [spotTarget, setSpotTarget] = useState(DEFAULT_COORDS);
  const [isColliding, setIsColliding] = useState(false);

  const checkCoordsEq = (a = {}, b = {}) => {
    return a.x === b.x && a.y === b.y && a.z === b.z;
  };

  const gameUpdatedHook = (data) => {
    const { isColliding, dotCoords, spotCoords } = data;

    if (typeof dotCoords !== 'undefined') {
      // don't re-set if they are equivalent because it will create a new object and cause many re-renders
      if (!checkCoordsEq(dotCoords, dotTarget)) {
        setDotTarget(dotCoords);
      }

      if (!checkCoordsEq(spotCoords, spotTarget)) {
        setSpotTarget(spotCoords);
      }
    }

    if (typeof isColliding !== 'undefined') {
      setIsColliding(isColliding);
    }
  };

  useEffect(() => {
    socket.on(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);
    };
  }, [socket]);

  return (
    <DotInTheSpot
      gameDotData={dotTarget}
      gameDotSpeed={ANIM_DURATION}
      gameDotSetter={null}
      spotData={spotTarget}
      isColliding={isColliding}
      gameSpotSpeed={spotAnimSpeed}
      gameSpotSetter={null}
    />
  );
};

export default DotInTheSpotPortal;
