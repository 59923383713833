import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Modal, Pressable } from 'react-native';
import { saveUserProfile } from 'slices/app.slice';
import Button from 'components/Button';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { sizes, colors } from 'theme';
import { saveIsActive } from 'slices/activeSession.slice';
import GameSessionApp from 'components/GameSession/GameSessionApp';
import GameSessionPortal from 'components/GameSession/GameSessionPortal';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { GAME_TYPES, GAME_DIFFICULTY_TYPES, SOCKET_ACTIONS, IS_PORTAL } from 'utils/constants';
import siteCopy from 'utils/siteCopy';

const strs = {
  h1: `Game`,
  h2Game: `Select Game Style:`,
  h2Difficulty: `Select Difficulty:`,
  btnStart: `Start`,
  btnStop: `End`,
  btnRandom: `Free Play`,
  btnPelvicClock: siteCopy.pelvicClock,
  btnPong: `Pong`,
  btnNormal: `Normal`,
  btnHard: `Hard`,
};

const Game = ({ navigation }) => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const dispatch = useDispatch();
  const { userProfile, portalCode } = useSelector((state) => state.app);
  const { subNav, isActive } = useSelector((state) => state.activeSession);
  const highScore = userProfile.gameHighScore;
  const [pendingSyncedState, setPendingSyncedState] = useState(null);
  const [hasGameStarted, setGameStarted] = useState(false);
  const [gameDifficulty, setGameDifficulty] = useState(
    subNav.gameDifficulty || GAME_DIFFICULTY_TYPES.normal
  );
  const [gameType, setGameType] = useState(GAME_TYPES.pong);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    // quick trick - if the game was just started and the session isn't already active, activate it.
    if (hasGameStarted && !isActive) {
      dispatch(saveIsActive(true));
    }
  }, [hasGameStarted]);

  useEffect(() => {
    if (!portalCode || IS_PORTAL) {
      return;
    }

    socket.emit(SOCKET_ACTIONS.subRouteUpdated, {
      gameType,
      gameDifficulty,
      hasGameStarted,
      shortCode: portalCode,
    });
  }, [portalCode, gameType, hasGameStarted, gameDifficulty]);

  useEffect(() => {
    if (!pendingSyncedState) {
      return;
    }

    if (IS_PORTAL) {
      let data = {
        shortCode: portalCode,
      };
      let dataProp;

      switch (pendingSyncedState.setter) {
        case setGameType:
          dataProp = 'gameType';
          break;
        case setGameDifficulty:
          dataProp = 'gameDifficulty';
          break;
        case setGameStarted:
          dataProp = 'hasGameStarted';
          break;
        default:
          throw 'error: unexpected pendingSyncedState';
      }

      data[dataProp] = pendingSyncedState.value;
      socket.emit(SOCKET_ACTIONS.subRouteUpdated, data);
    } else {
      pendingSyncedState.setter(pendingSyncedState.value);
    }

    setPendingSyncedState(null);
  }, [portalCode, pendingSyncedState]);

  const subRouteUpdatedHook = (data) => {
    const { gameType, gameDifficulty, hasGameStarted } = data;

    if (typeof gameType !== 'undefined') {
      setGameType(gameType);
    }
    if (typeof gameDifficulty !== 'undefined') {
      setGameDifficulty(gameDifficulty);
    }
    if (typeof hasGameStarted !== 'undefined') {
      setGameStarted(hasGameStarted);
    }
  };

  useEffect(() => {
    if (!portalCode) {
      return;
    }

    socket.on(SOCKET_ACTIONS.subRouteUpdated, subRouteUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.subRouteUpdated, subRouteUpdatedHook);
    };
  }, [portalCode, socket]);

  const attemptSaveHighscore = (score) => {
    const lastHigh = highScore;

    if (score > lastHigh) {
      dispatch(
        saveUserProfile({
          gameHighScore: score,
        })
      );
    }
  };

  const doEndGame = () => {
    setPendingSyncedState({
      setter: setGameStarted,
      value: false,
    });
  };

  const onGameOver = (score) => {
    attemptSaveHighscore(score);
  };

  return (
    <View style={styles.root}>
      <View style={styles.pageView}>
        {hasGameStarted ? (
          <>
            {IS_PORTAL ? (
              <GameSessionPortal
                gameDifficulty={gameDifficulty}
                gameType={gameType}
                // don't pass the highscore. Get it from the socket sync instead.
                // highScore={highScore}
                doEndGame={doEndGame}
                onGameOver={onGameOver}
              />
            ) : (
              <GameSessionApp
                gameDifficulty={gameDifficulty}
                gameType={gameType}
                highScore={highScore}
                doEndGame={doEndGame}
                onGameOver={onGameOver}
              />
            )}
          </>
        ) : (
          <>
            <View style={styles.section}>
              <Text style={styles.textLeft}>{`${siteCopy.highScore}: ${highScore}`}</Text>
            </View>
            <View style={styles.section}>
              <Text>{strs.h2Game}</Text>
              <View style={styles.row}>
                <Button
                  title={strs.btnRandom}
                  style={[
                    styles.btnToggle,
                    gameType === GAME_TYPES.random ? styles.btnTrue : styles.btnFalse,
                  ]}
                  textStyle={gameType === GAME_TYPES.random ? styles.btnToggleTrueText : null}
                  onPress={() =>
                    setPendingSyncedState({
                      setter: setGameType,
                      value: GAME_TYPES.random,
                    })
                  }
                />
                <Button
                  title={strs.btnPelvicClock}
                  style={[
                    styles.btnToggle,
                    gameType === GAME_TYPES.pelvicClock ? styles.btnTrue : styles.btnFalse,
                  ]}
                  textStyle={gameType === GAME_TYPES.pelvicClock ? styles.btnToggleTrueText : null}
                  onPress={() =>
                    setPendingSyncedState({
                      setter: setGameType,
                      value: GAME_TYPES.pelvicClock,
                    })
                  }
                />
                <Button
                  title={strs.btnPong}
                  style={[
                    styles.btnToggle,
                    gameType === GAME_TYPES.pong ? styles.btnTrue : styles.btnFalse,
                  ]}
                  textStyle={gameType === GAME_TYPES.pong ? styles.btnToggleTrueText : null}
                  onPress={() =>
                    setPendingSyncedState({
                      setter: setGameType,
                      value: GAME_TYPES.pong,
                    })
                  }
                />
              </View>
            </View>
            <View style={styles.section}>
              <Text>{strs.h2Difficulty}</Text>
              <View style={styles.row}>
                <Button
                  title={strs.btnNormal}
                  style={[
                    styles.btnToggle,
                    gameDifficulty === GAME_DIFFICULTY_TYPES.normal
                      ? styles.btnTrue
                      : styles.btnFalse,
                  ]}
                  textStyle={
                    gameDifficulty === GAME_DIFFICULTY_TYPES.normal
                      ? styles.btnToggleTrueText
                      : null
                  }
                  onPress={() =>
                    setPendingSyncedState({
                      setter: setGameDifficulty,
                      value: GAME_DIFFICULTY_TYPES.normal,
                    })
                  }
                />
                <Button
                  title={strs.btnHard}
                  style={[
                    styles.btnToggle,
                    gameDifficulty === GAME_DIFFICULTY_TYPES.hard
                      ? styles.btnTrue
                      : styles.btnFalse,
                  ]}
                  textStyle={
                    gameDifficulty === GAME_DIFFICULTY_TYPES.hard ? styles.btnToggleTrueText : null
                  }
                  onPress={() =>
                    setPendingSyncedState({
                      setter: setGameDifficulty,
                      value: GAME_DIFFICULTY_TYPES.hard,
                    })
                  }
                />
              </View>
            </View>
            <View style={styles.section}>
              <ButtonPrimary
                title={strs.btnStart}
                style={styles.btnStart}
                onPress={() =>
                  setPendingSyncedState({
                    setter: setGameStarted,
                    value: true,
                  })
                }
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
    backgroundColor: colors.white,
  },
  pageView: {
    flex: 1,
    paddingVertical: sizes.xl,
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    textAlign: 'center',
    marginBottom: sizes.base,
  },
  textLeft: {
    textAlign: 'left',
  },
  row: {
    flexDirection: 'row',
    marginVertical: sizes.base,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  btnToggle: {
    marginHorizontal: sizes.buttonMargin,
    textTransform: 'uppercase',
  },
  btnToggleTrueText: {
    color: colors.white,
  },
  btnTrue: {
    backgroundColor: colors.blueSavedState,
    color: colors.white,
  },
  btnFalse: {
    // let it be the default
  },
  modalView: {
    margin: sizes.base,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 40,
    elevation: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
  },
};

export default Game;
