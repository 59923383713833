import React, { useEffect, useRef } from 'react';
import { colors, sizes } from 'theme';
import { View, Text, Animated, Platform, Easing } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { REALTIME_CHART_INTERVAL } from 'utils/constants';

const gradEnd = '#e45813';
const gradStart = '#dddddd';
const circleDia = 300;
const circleDiaInner = circleDia / 3;
const needleSize = circleDia / 30;
const needleTipHeight = needleSize * 3;
const needleColor = colors.gray;
const segmentLineColor = '#ffffff';
const circleInnerColor = '#eeeeee';
const angleInitialOffset = 90;
const lineOffset = 45;
// convert the REALTIME_CHART_INTERVAL milliseconds to units per min
const needleCalMultipler = (1000 / REALTIME_CHART_INTERVAL) * 60;
const labelTextSize = sizes.base;
const labelTextSizeWithPad = labelTextSize * 1.5;
const animSpeed = REALTIME_CHART_INTERVAL;

const getOffset = (currentCals) => {
  const valueOffset0to1 = currentCals * needleCalMultipler;
  let offset = valueOffset0to1 * 180;

  // ensure it doens't go past the end
  if (offset > 180) {
    offset = 180;
  }

  return offset;
}


const Speedometer = ({currentCals}) => {
  const transValue = useRef(new Animated.Value(0)).current;
  const offset = getOffset(currentCals);

  useEffect(() => {
    Animated.timing(transValue, {
      toValue: offset,
      duration: animSpeed,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.linear,
    }).start();

    return () => {
      transValue.stopAnimation();
    };
  }, [offset]);

  const spin = transValue.interpolate({
    inputRange: [0, 180],
    outputRange: ['-90deg', '90deg']
  })

  const getSegmentLine = (angle) => {
    const getVal = (angle) => {
      return angle / angleInitialOffset ;
    };

    return (
      <View style={styles.circleSegmentLines}>
        <View style={[styles.needleSegmentLinePos, {transform:[{ rotate: `${angle-angleInitialOffset}deg`}]}]}>
          <View style={[styles.needleSegmentLine]}></View>
          <Text style={[styles.needleSegmentText]}>{getVal(angle).toFixed(1)}</Text>
        </View>
      </View>
    )
  }

  return (
    <View style={styles.root}>
      <View style={styles.rootWrap}>
        <View style={[styles.wrap, styles.wrapHidden]}>
          <LinearGradient
            colors={[gradStart, gradEnd]}
            start={[0.0, 0.5]}
            end={[1.0, 0.5]}
            style={[styles.circle, styles.circleBorder]}
          />
        </View>
        <View style={styles.wrap}>
          <View style={styles.circle}>
            { getSegmentLine(lineOffset * 1) }
            { getSegmentLine(lineOffset * 2) }
            { getSegmentLine(lineOffset * 3) }
          </View>
        </View>
        <View style={[styles.wrap, styles.wrapHidden]}>
          <View style={styles.circle}>
            <View style={styles.circleInner} />
            <Animated.View style={[styles.needlePos, {
              transform: [
                { rotate: spin },
              ],
            }]}>
              <View style={styles.needleWrap}>
                <View style={styles.needleBase} />
                <View style={styles.needle}></View>
                <View style={styles.needleTip}></View>
              </View>
            </Animated.View>
          </View>
        </View>
      </View>
    </View>
  )
};

const overflowOffset = labelTextSizeWithPad;

const styles = {
  root: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: circleDia / 2,
    marginBottom: sizes.sm,
  },
  // this is a hack to ensure we have a overflow: 'hidden'.. otherwise the buttons get covered
  // but we also need to not cut off the number label at the top
  rootWrap: {
    position: 'absolute',
    overflow: 'hidden',
    height: (circleDia / 2) + overflowOffset,
    width: circleDia,
    paddingTop: overflowOffset,
    marginTop: -overflowOffset,
  },
  wrap: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  wrapHidden: {
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderColor: colors.grayLight,
  },
  circleSegmentLines: {
    position: 'absolute',
    height: circleDia,
    width:  circleDia,
    left: circleDia / 2,
    bottom: circleDia / 2,
  },
  needleSegmentLinePos: {
    position: 'absolute',
    bottom: 0,
  },
  needleSegmentLine: {
    position: 'absolute',
    height: circleDia / 2,
    width: needleSize,
    backgroundColor: segmentLineColor,
    bottom: 0,
    transform: [
      { translateX: -needleSize / 2 },
      // ensure the color doesn't peek a pixel
      { scaleY: 1.05 },
    ],
  },
  needleSegmentText: {
    position: 'absolute',
    top: 0,
    transform: [
      {translateX: -labelTextSize / 2},
      {translateY: (-circleDia / 2) - labelTextSizeWithPad},
    ],
  },
  circle: {
    position: 'absolute',
    height: circleDia,
    width: circleDia,
  },
  circleBorder: {
    borderRadius: circleDia / 2,
    borderWidth: 1,
    borderColor: colors.grayLight,
  },
  circleInner: {
    position: 'absolute',
    backgroundColor: circleInnerColor,
    height: circleDiaInner,
    width: circleDiaInner,
    borderRadius: circleDiaInner / 2,
    bottom: circleDiaInner,
    left: circleDiaInner,
    borderWidth: 1,
    borderColor: colors.grayLight,
  },
  needlePos: {
    position: 'absolute',
    height: 0,
    bottom: circleDia / 2,
    left: '50%',

  },
  needleWrap: {
    position: 'absolute',
    height: circleDia / 2,
    width: needleSize,
    bottom: 0,
    transform: [
      { translateX: -needleSize / 2 },
    ],
  },
  needleBase: {
    position: 'absolute',
    bottom: 0,
    height: needleSize,
    width: '100%',
    backgroundColor: needleColor,
    borderRadius: needleSize,
  },
  needle: {
    position: 'absolute',
    top: needleTipHeight,
    bottom: needleSize / 2,
    width: '100%',
    backgroundColor: needleColor,
  },
  needleTip: {
    position: 'absolute',
    width: 0,
    height: 0,
    top: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderBottomWidth: needleTipHeight,
    borderRightWidth: needleSize / 2,
    borderLeftWidth: needleSize / 2,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: needleColor,
    borderLeftColor: 'transparent',
    // cover the tiny sub-pixel line
    transform: [{translateY: 0.5}],
  }
};

export default Speedometer;
