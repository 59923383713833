import React from 'react';
import { Text, View, Image } from 'react-native';
import { colors, sizes, images } from 'theme';
import { moderateScale } from 'utils/scaling';
import ShadowBox from 'components/ShadowBox';
import ContentHeader from 'components/ContentHeader';

const dummyImgSize = 200;

const ContentBox = ({ title, children, style, shouldUseDefault, useBackground = true }) => {
  const liveStyles = {
    root: {
      width: moderateScale(sizes.contentBoxWidth),
    },
  };

  return (
    <View style={[styles.root, style, liveStyles.root]}>
      <ContentHeader title={title} />
      {!useBackground ? (
        children
      ) : (
        <ShadowBox style={styles.body}>
          {shouldUseDefault ? (
            <Image source={images.comingSoon} style={styles.dummyImg} />
          ) : (
            children
          )}
        </ShadowBox>
      )}
    </View>
  );
};

const styles = {
  root: {
    marginTop: sizes.base,
  },
  body: {
    paddingHorizontal: sizes.base,
    paddingVertical: sizes.base,
    borderRadius: 20,
  },
  dummyImg: {
    alignSelf: 'center',
    resizeMode: 'contain',
    marginVertical: sizes.base,
    width: dummyImgSize,
    height: dummyImgSize,
  },
};

export default ContentBox;
