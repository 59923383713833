import React from 'react';
import { View, ScrollView } from 'react-native';
import { sizes, colors } from 'theme';

const PageFrame = ({ children }) => {
  return (
    <ScrollView style={styles.root}>
      <View style={styles.childView}>{children}</View>
    </ScrollView>
  );
};

const styles = {
  root: {
    flex: 1,
    backgroundColor: colors.white,
  },
  childView: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: sizes.base,
    paddingVertical: sizes.base,
  },
};

export default PageFrame;
