import React from 'react';
import { View } from 'react-native';
import { colors, sizes, images } from 'theme';

const shadowDefaults = {
  x: 0,
  y: 0,
  radius: sizes.shadow.radius,
  color: colors.gray,
  opacity: sizes.shadow.opacity,
};

const getNativeBoxShadow = (props) => {
  const settings = Object.assign({}, shadowDefaults, props);

  return {
    shadowColor: settings.color,
    shadowOffset: {
      width: settings.x,
      height: settings.y,
    },
    shadowOpacity: settings.opacity,
    shadowRadius: settings.radius,
    // for android
    elevation: 5,
  };
};

const ShadowBox = ({ children, ...props }) => {
  let settings = getNativeBoxShadow(props);

  const addedStyes = props.style || {};
  return (
    <View
      {...props}
      // default with a preset 100% width.
      style={[styles.root, addedStyes, settings]}>
      {children}
    </View>
  );
};

const styles = {
  root: {
    width: '100%',
    backgroundColor: colors.white,
  },
};

export default ShadowBox;
