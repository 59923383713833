import React, { useState, useEffect, useContext, useRef } from 'react';
import Pong from 'components/Games/Pong/Pong';
import SocketProvider from 'utils/SocketController/SocketProvider';

import { SOCKET_ACTIONS, DEFAULT_COORDS } from 'utils/constants';

const PongPortal = ({ gameDifficulty, gameType, doEndGame, score, incScore }) => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const [dotTarget, setDotTarget] = useState(DEFAULT_COORDS);
  const [spotTarget, setSpotTarget] = useState(DEFAULT_COORDS);

  const checkCoordsEq = (a = {}, b = {}) => {
    return a.x === b.x && a.y === b.y && a.z === b.z;
  };

  const gameUpdatedHook = (data) => {
    const { isColliding, dotCoords, spotCoords } = data;

    if (typeof dotCoords !== 'undefined') {
      // don't re-set if they are equivalent because it will create a new object and cause many re-renders
      if (!checkCoordsEq(dotCoords, dotTarget)) {
        setDotTarget(dotCoords);
      }

      if (!checkCoordsEq(spotCoords, spotTarget)) {
        setSpotTarget(spotCoords);
      }
    }
  };

  useEffect(() => {
    socket.on(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);
    };
  }, [socket]);

  return (
    <Pong
      gameDifficulty={gameDifficulty}
      pongBallCoords={spotTarget}
      pongPaddleCoords={dotTarget}
      pongBallSetter={null}
      pongPaddleSetter={null}
    />
  );
};

export default PongPortal;
