import React from 'react';
import { useSelector } from 'react-redux';
import { colors, sizes } from 'theme';
import { View, Text } from 'react-native';
import { getDataForDays } from 'utils/dataUtils';
import BarChartRealtime from './BarChartRealtime';
import BarChartDays from './BarChartDays';

import {
  DUMMY_CHART_DATA,
  CHART_TYPES,
  CHART_DATA_TYPES,
  DAYS_IN_WEEK,
  DAYS_IN_TWOWEEK,
  DAYS_IN_MONTH,
} from 'utils/constants';

const defaultChartType = CHART_TYPES.week;

const getNumDays = (chartType) => {
  switch (chartType) {
    case CHART_TYPES.realtime:
      // not used
      return null;
      break;
    case CHART_TYPES.week:
      return DAYS_IN_WEEK;
      break;
    case CHART_TYPES.twoweek:
      return DAYS_IN_TWOWEEK;
      break;
    case CHART_TYPES.month:
      return DAYS_IN_MONTH;
    default:
      throw 'error: unexpected CHART_TYPES';
  }
};

const buildDummyIdx = () => {
  const daysData = getDataForDays(DUMMY_CHART_DATA.length);
  const indexPerDay = {};

  daysData.forEach((data, idx) => {
    indexPerDay[data.dayKey] = DUMMY_CHART_DATA[idx];
  });

  return indexPerDay;
};

const BarChart = ({ chartType, chartDataType = CHART_DATA_TYPES.calories }) => {
  const { indexPerDay } = useSelector((state) => state.chairSessions);
  const optChartType = chartType ? chartType : defaultChartType;
  const numDays = getNumDays(optChartType);

  return optChartType === CHART_TYPES.realtime ? (
    <BarChartRealtime />
  ) : (
    <BarChartDays indexPerDay={indexPerDay} chartDataType={chartDataType} numDays={numDays} />
  );
};

export default BarChart;
