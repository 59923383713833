// See ImageWrap for similar implementation
import React from 'react';
import { View, Image, Text, Platform } from 'react-native';
import Button from 'components/Button';
import { Video, ResizeMode } from 'expo-av';

const VideoWrap = (props) => {
  const ratio = props.ratio || 1;
  const percentageStr = `${ratio * 100}%`;
  const source = props.source || '';
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});

  return (
    <View
      style={[
        styles.wrap,
        props.style,
        {
          paddingBottom: percentageStr,
        },
      ]}>
      <View style={[styles.vidWrap, {
        paddingBottom: percentageStr,
      }]}>

        <Video
          ref={video}
          style={[styles.video, {
            paddingBottom: percentageStr,
          }]}
          source={source}
          useNativeControls
          resizeMode={ResizeMode.CONTAIN}
          isLooping
          onPlaybackStatusUpdate={status => setStatus(() => status)}
          onReadyForDisplay={videoData => {
            if (Platform.OS === 'web') {
              videoData.srcElement.style.width = "100%"
            }
          }}
        />
        {/*Don't show buttons*/}
        {/*<View style={styles.buttons}>
          <Button
            title={status.isPlaying ? 'Pause' : 'Play'}
            onPress={() =>
              status.isPlaying ? video.current.pauseAsync() : video.current.playAsync()
            }
          />
        </View>*/}
      </View>
    </View>
  );
};

const styles = {
  vidWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  video: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  // buttons: {
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
};

export default VideoWrap;
