import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View, Animated, Platform } from 'react-native';
import coordParser from 'utils/coordParser';
import { DOT_WIDTH, GAME_CHECK_THROTTLE } from 'utils/constants';
import { throttle } from 'lodash';
import { colors } from 'theme';

const GameDot = ({ data, animSpeed, setRealCoords }) => {
  const {isActive} = useSelector((state) => state.activeSession);

    // note: #animationPerformance see // https://eveningkid.medium.com/the-basics-of-react-native-animations-fb00a8ccc178
  const dotPos = useRef(new Animated.ValueXY()).current;

  const liveStyles = {
    gameDot: {
      opacity: isActive ? 1 : 0.5,
      backgroundColor: isActive ? colors.red : colors.grayLight,
    },
  };

  useEffect(() => {
    if (!setRealCoords) {
      return;
    }

    dotPos.addListener(
      throttle(
        (dotPos) => {
          setRealCoords(dotPos);
        },
        GAME_CHECK_THROTTLE,
        {
          leading: true,
        }
      )
    );

    return () => {
      dotPos.stopAnimation();
      dotPos.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    Animated.timing(dotPos, {
      toValue: coordParser.parseDotCoords(data),
      duration: animSpeed,
      useNativeDriver: Platform.OS !== 'web',
    }).start();

    return () => {
      dotPos.stopAnimation();
    };
  }, [data.x, data.y]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        transform: dotPos.getTranslateTransform(),
      }}>
      <View style={[styles.gameDot, liveStyles.gameDot]} />
    </Animated.View>
  );
};

const styles = {
  gameDot: {
    position: 'absolute',
    borderRadius: DOT_WIDTH,
    backgroundColor: colors.red,
    width: DOT_WIDTH,
    height: DOT_WIDTH,
    top: '50%',
    left: '50%',
    transform: [{ translateY: -DOT_WIDTH / 2 }, { translateX: -DOT_WIDTH / 2 }],
  },
};

export default GameDot;
