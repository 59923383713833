import React, { useContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Switch } from 'react-native';
import { colors, sizes, mixins } from 'theme';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { saveIsActive } from 'slices/activeSession.slice';
import siteCopy from 'utils/siteCopy';
import NavProvider from 'navigator/NavProvider';
import { useSharedTimer } from 'utils/TimerController/TimerProvider';
import { IS_PORTAL, SOCKET_ACTIONS, APP_CONNECTION_STATUS } from 'utils/constants';

const headerMenuScale = sizes.md;

const SessionStatusBar = ({ isCalibratePage }) => {
  const {portalCode, connectionStatus} = useSelector((state) => state.app);
  const {isActive} = useSelector((state) => state.activeSession);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const Navigator = useContext(NavProvider);
  const dispatch = useDispatch();
  const [timeData] = useSharedTimer();
  const [isToggleOn, setIsToggleOn] = useState(isActive);

  const toggleSessionIsActive = (bool = false) => {
    if (IS_PORTAL) {
      socket.emit(SOCKET_ACTIONS.dispatchProxy, {
        shortCode: portalCode,
        // todo: consider adding these strings to constants?
        slice: 'activeSession',
        reducer: 'saveIsActive',
        value: bool,
      });
    } else {
      dispatch(saveIsActive(bool));
    }
  };

  useEffect(() => {
    setIsToggleOn(isActive);
  }, [isActive]);

  const activeThumbColor = colors.greenLightest;

  return (
    <View style={styles.root}>
      <View style={styles.textLeft}>
        <Switch
          style={styles.switch}
          trackColor={{ false: colors.grayLight, true: colors.greenSoft }}
          thumbColor={isToggleOn ? activeThumbColor : colors.grayLightest}
          // fix for web - add activeThumbColor also https://github.com/facebook/react-native/issues/30429
          activeThumbColor={activeThumbColor}
          onValueChange={toggleSessionIsActive}
          value={isToggleOn}
        />
        <Text
          style={styles.switchText}
          onPress={() => {
            toggleSessionIsActive(!isActive);
          }}>
          {isToggleOn ? `Tracking: ${timeData.formattedTime}` : `Track Calories`}
        </Text>
      </View>
      <View style={styles.textRight}>
        { !!connectionStatus && connectionStatus === APP_CONNECTION_STATUS.status_500 ? (
          <Text style={styles.warningText}>
            {'No server connection...'}
          </Text>
        ) : (
          isCalibratePage ? null : (
            <Text
              style={mixins.hyperLink}
              onPress={() => {
                Navigator.navigate('Calibrate');
              }}>
              {'View Calibration'}
            </Text>
          )
        )}
      </View>
    </View>
  );
};

const styles = {
  root: {
    height: sizes.headerHeight,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: headerMenuScale,
    ...mixins.bottomBorder,
  },
  textLeft: {
    flexDirection: 'row',
  },
  textRight: {
    flexDirection: 'row',
  },
  switch: {
    marginRight: sizes.xs,
  },
  switchText: {
    alignSelf: 'center',
  },
  warningText: {
    fontStyle: 'italic',
    color: colors.grayLight,
  },
};

export default SessionStatusBar;
