import React from 'react';
import MockMotionTracker from 'utils/MockMotionTracker';
import MotionTracker from 'utils/MotionTracker';
import { ANIM_DURATION, MOCK_MOTION_SAMPLE_RATE } from 'utils/constants';

const MotionManager = (options={}) => {
  const noop = (() => {});
  const onChange = options.onChange || noop;
  const sampleRate = options.sampleRate || ANIM_DURATION;
  const mockMotionSampleRate = options.mockMotionSampleRate || MOCK_MOTION_SAMPLE_RATE;

  let activeTracker = null;
  let motionTracker = null;
  let mockMotionTracker = null;
  let subCallback = onChange;

  // super simple pub/sub with just a single event
  const _sub = (callback) => {
    subCallback = callback;
  }

  const _unsub = (callback) => {
    subCallback = noop;
  }

  const onChangeClosure = (data) => {
    subCallback(data);
  }

  const self = {
    init: (resolve) => {
      motionTracker = new MotionTracker({
        onChange: onChangeClosure,
        sampleRate,
      });

      motionTracker.test().then((isAvail) => {
        if (isAvail) {
          activeTracker = motionTracker;
        } else {
          mockMotionTracker = new MockMotionTracker({
            onChange: onChangeClosure,
            sampleRate: mockMotionSampleRate,
          });
          activeTracker = mockMotionTracker;
        }

        resolve();
      });

      return self;
    },
    start: () => {
      return initWrap.then(() => {
        activeTracker.start();
      });
    },
    stop: () => {
      return initWrap.then(() => {
        activeTracker.stop();
      });
    },
    reset: () => {
      return initWrap.then(() => {
        activeTracker.reset();
      });
    },
    sub: (callback) => {
      _sub(callback);
      return self;
    },
    unsub: () => {
      _unsub();
      return self;
    },
    getData: () => {
      return activeTracker.getData();
    },
  };

  const initWrap = new Promise((resolve) => {
    self.init(resolve);
  });

  return self;
};

export default MotionManager;
