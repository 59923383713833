import React, { useState, useEffect, useContext } from 'react';
import { Text, View } from 'react-native';
import GameDot from 'components/GameDot';
import { colors } from 'theme';
import MovementTargetZone from 'components/MovementTargetZone';
import { ANIM_DURATION, DEFAULT_COORDS } from 'utils/constants';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { SOCKET_ACTIONS } from 'utils/constants';

const MovementSessionPortal = () => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const [dotData, setDotData] = useState(DEFAULT_COORDS);

  const motionUpdatedHook = (data = {}) => {
    setDotData(data.dotCoords);
  };

  useEffect(() => {
    socket.on(SOCKET_ACTIONS.motionUpdated, motionUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.motionUpdated, motionUpdatedHook);
    };
  }, [socket]);

  return (
    <View style={styles.root}>
      <MovementTargetZone>
        <GameDot data={dotData} animSpeed={ANIM_DURATION} />
      </MovementTargetZone>
    </View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
  },
};

export default MovementSessionPortal;
