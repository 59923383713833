import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MotionManager from 'utils/MotionController/MotionManager';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { saveCalibration, requestCalibration } from 'slices/activeSession.slice';
import { IS_PORTAL, SOCKET_ACTIONS, DEFAULT_COORDS, REALTIME_CHART_INTERVAL } from 'utils/constants';
import { log } from 'utils/logger';
import { SharedMotionProvider, useSharedMotion } from 'utils/MotionController/MotionProvider';
import { parseCalibratedCoords, getCalCalcFunction } from 'utils/dataUtils';
import { throttle } from 'lodash';

// Note: #useSharedMotion: This code is a bit confusing.
// The meat of the code is in utils/MotionController/MotionManager
// this creates a singleton used for the whole lifecycle of the app.
const singletonMotionManager = new MotionManager();

const debouncedSetCals = throttle((dotDistance, calCalcFunction, currentCals, sessionCals, setCurrentCals, setSessionCals) => {
  const prevSessionCals = sessionCals;
  const prevCurrentCals = currentCals;

  const newSessionCals = calCalcFunction(dotDistance);
  // The current chunk is the newTotal minus the previous chunk's cached total.
  const newCurrentCals = newSessionCals - prevSessionCals;

  setCurrentCals(newCurrentCals);
  setSessionCals(newSessionCals);
},
REALTIME_CHART_INTERVAL,
{
  leading: true,
})

const MotionController = ({ children }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.app.userProfile);
  const { calibration, whichCalibrationRequested } = useSelector((state) => state.activeSession);

  const [motionData, setMotionData] = useSharedMotion();
  const [dotData, setDotData] = useState(null);
  const [currentCals, setCurrentCals] = useState(0);
  const [sessionCals, setSessionCals] = useState(0);
  const [calCalcFunction, setCalCalcFunction] = useState(null);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const min = {
    x: calibration.left.x,
    y: calibration.down.y,
    z: 0,
  };

  const max = {
    x: calibration.right.x,
    y: calibration.up.y,
    z: 0,
  };

  useEffect(() => {
    singletonMotionManager.sub(setDotData).start();

    return () => {
      singletonMotionManager.unsub();
    };
  }, []);

  useEffect(() => {
    if (!dotData) {
      // dotData will be null on init until the motion manager starts
      // this maybe will never happen? But ensure data stays correct
      // setMotionData(initMotionData);
      return;
    }

    const calibratedCoords = parseCalibratedCoords(dotData.coords, min, max);

    if (whichCalibrationRequested) {
      dispatch(saveCalibration({[whichCalibrationRequested]: dotData.coords}));
    }

    setMotionData({
      motionController: singletonMotionManager,
      dotData: dotData,
      currentCals: currentCals,
      sessionCals: sessionCals,
      calibratedCoords: calibratedCoords,
    });

    // let them get picked up in the next cycle.
    debouncedSetCals(dotData.distanceTotal, calCalcFunction, currentCals, sessionCals, setCurrentCals, setSessionCals);
  }, [dotData, calibration, whichCalibrationRequested, calCalcFunction, currentCals, sessionCals]);

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    socket.on(SOCKET_ACTIONS.doSaveCalibration, (data = {}) => {
      const { which } = data;

      dispatch(requestCalibration(which));
    });

    return () => {
      socket.off(SOCKET_ACTIONS.doSaveCalibration);
    };
  }, [socket]);

  // cache a new calCal function when the user object changes.
  useEffect(() => {
    // this needs to be wrapped, because setState will parse a function instead of accepting it as an object.
    setCalCalcFunction(() => {
      return getCalCalcFunction(userProfile);
    });
  }, [userProfile]);

  return children;
};

export default ({ children }) => (
  <SharedMotionProvider>
    <MotionController children={children} />
  </SharedMotionProvider>
);
