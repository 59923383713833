// This must be here, as advised here https://reactnavigation.org/docs/stack-navigator/
import 'react-native-gesture-handler';
import 'intl';
import 'intl/locale-data/jsonp/en';

// https://stackoverflow.com/questions/41736735/react-native-and-intl-polyfill-required-on-android-device

import React, { useState, useEffect } from 'react';
import { View, SafeAreaView, StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { colors } from 'theme';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'utils/store';
import 'utils/ignore';

// assets
import { imageAssets } from 'theme/images';
import { useFonts, Roboto_400Regular } from '@expo-google-fonts/roboto';

import Navigator from './navigator';
import SocketController from 'utils/SocketController';
import MotionController from 'utils/MotionController';
import TimerController from 'utils/TimerController';
import DataSyncController from 'utils/DataSyncController';
import { useKeepAwake } from 'expo-keep-awake';

const App = () => {
  const [didLoad, setDidLoad] = useState(false);

  let [areFontsLoaded] = useFonts({
    Roboto_400Regular,
  });

  // assets preloading
  const handleLoadAssets = async () => {
    await Promise.all([...imageAssets]);
    setDidLoad(true);
  };

  useEffect(() => {
    handleLoadAssets();
  }, []);

  useKeepAwake();

  return (
    <SafeAreaView style={styles.root}>
      {areFontsLoaded && didLoad ? (
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SocketController>
              <MotionController>
                <TimerController>
                  <DataSyncController>
                    <Navigator />
                  </DataSyncController>
                </TimerController>
              </MotionController>
            </SocketController>
          </PersistGate>
        </Provider>
      ) : (
        <View />
      )}
    </SafeAreaView>
  );
};

const styles = {
  root: {
    flex: 1,
    // todo: StatusBar.currentHeight is null on iOS, but it may be needed on android. Confirm.
    marginTop: StatusBar.currentHeight,
    backgroundColor: colors.grayLight,
    // this isn't needed for the most part, but it removes an extra scroll-bar that appears on web.
    overflowY: 'hidden',
  },
};

export default App;
