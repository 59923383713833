import React, { useRef, useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import SocketProvider from 'utils/SocketController/SocketProvider';
import About from 'pages/About';
import Tutorial from 'pages/Tutorial';
import Profile from 'pages/Profile';
import Calibrate from 'pages/Calibrate';
import Game from 'pages/Game';
import Calories from 'pages/Calories';
import Datadump from 'pages/Datadump';
import DrawerNavigator from 'navigator/Drawer';
import NavProvider from 'navigator/NavProvider';
import PageWrap from 'components/PageWrap';
import { SOCKET_ACTIONS } from 'utils/constants';

const getNavWrappedPage = (Comp, options = {}) => {
  return ({ navigation }) => (
    <NavProvider.Provider value={navigation}>
      <PageWrap navigation={navigation} options={options}>
        <Comp />
      </PageWrap>
    </NavProvider.Provider>
  );
};

const Stack = createNativeStackNavigator();

const Navigator = () => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const {portalCode} = useSelector((state) => state.app);
  const lastRoute = useRef();
  const navigatorRef = useRef();

  const routeUpdatedHook = (data) => {
    const { routeIndex, routeName } = data;

    try {
      const navigation = navigatorRef.current;
      const currentRoutes = navigation.getState().routes;
      const currentRouteName = currentRoutes[currentRoutes.length - 1].name;

      if (routeName && currentRouteName !== routeName) {
        navigation.navigate(routeName, { isProxy: true });
      }
    } catch (e) {
      throw 'Navigation sync error';
    }
  };

  useEffect(() => {
    socket.on(SOCKET_ACTIONS.routeUpdated, routeUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.routeUpdated, routeUpdatedHook);
    };
  }, [socket]);

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenListeners={{
          state: (e) => {
            try {
              const routeState = e.data.state;
              const routeIndex = routeState.index;
              const route = routeState.routes[routeIndex];
              const routeParams = route.params || {};
              const routeName = route.name;
              const isProxy = routeParams.isProxy;
              const shouldEmit = !isProxy && lastRoute.current !== routeName;

              lastRoute.current = routeName;

              if (shouldEmit) {
                socket.emit(SOCKET_ACTIONS.routeUpdated, {
                  shortCode: portalCode,
                  routeName: routeName,
                });
              }
            } catch (err) {
              // this should never happen
              throw `Navigation parse error`;
            }
          },
        }}
        // Note #initialRoute: This can be uncommented for testing purposes.
        // initialRouteName="Tutorial"
        screenOptions={({ navigation: nav }) => {
          navigatorRef.current = nav;

          return {
            animation: 'none',
            headerShown: false,
          };
        }}>
        <Stack.Screen name="Home" component={getNavWrappedPage(DrawerNavigator)} />
        <Stack.Screen
          name="About"
          component={getNavWrappedPage(About, {
            isSubPage: true,
          })}
        />
        <Stack.Screen
          name="Tutorial"
          component={getNavWrappedPage(Tutorial, {
            isSubPage: true,
          })}
        />
        <Stack.Screen
          name="Profile"
          component={getNavWrappedPage(Profile, {
            isSubPage: true,
          })}
        />
        <Stack.Screen
          name="Calibrate"
          component={getNavWrappedPage(Calibrate, {
            isSubPage: true,
            shouldShowPortalBtn: true,
            isCalibratePage: true,
          })}
        />
        <Stack.Screen
          name="Game"
          component={getNavWrappedPage(Game, {
            isSubPage: true,
            shouldShowPortalBtn: true,
          })}
        />
        <Stack.Screen
          name="Calories"
          component={getNavWrappedPage(Calories, {
            isSubPage: true,
            shouldShowPortalBtn: true,
          })}
        />
        <Stack.Screen
          name="Datadump"
          component={getNavWrappedPage(Datadump, {
            isSubPage: true,
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigator;
