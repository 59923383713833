import { calcDistances } from 'utils/dataUtils';
import { DEFAULT_COORDS, DEFAULT_SAMPLE_RATE } from 'utils/constants';

const defaultSampleRate = DEFAULT_SAMPLE_RATE;
const useHighNoise = true;

const MockMotionTracker = (options) => {
  const settings = options || {};
  const onChange = settings.onChange || (() => {});
  const sampleRate = options.sampleRate || defaultSampleRate;

  const defaultData = {
    coords: DEFAULT_COORDS,
    distance: 0,
    normalizedDistance: 0,
  };

  const getResetData = () => {
    return Object.assign({}, defaultData);
  };

  const getLinearNoise = (val) => {
    // random val between 0 and 1
    let rand = Math.random();

    //damp it with arbitrary value
    rand = rand * 0.15;

    // bias it towards the middle
    return val > 0 ? -rand : rand;
  };

  const getChaoticNoise = (val) => {
    // random val between 0 and 1
    let rand = Math.random();
    let randExp = Math.random();

    // add exponent to add sharp spikes
    randExp = randExp * 6;
    // spike it
    rand = Math.pow(rand, randExp);

    //damp it with arbitrary value
    rand = rand * 0.15;

    // bias it towards the middle
    return val > 0 ? -rand : rand;
  };

  const getDummyMovement = (val) => {
    if (useHighNoise) {
      return getChaoticNoise(val);
    } else {
      return getLinearNoise(val);
    }
  };

  let myInterval = null;
  let data = getResetData();

  const _subscribe = () => {
    myInterval = setInterval(() => {
      const { coords } = data;

      const addDistX = getDummyMovement(coords.x);
      const addDistY = getDummyMovement(coords.y);
      const newX = coords.x + addDistX;
      const newY = coords.y + addDistY;
      const newZ = coords.z; // no change

      const newCoords = {
        x: newX,
        y: newY,
        z: newZ,
      };

      const latest = calcDistances(newCoords, coords);
      const newDistance = data.distance + latest.distance;
      const newNormalizedDistance = data.normalizedDistance + latest.normalizedDistance;

      // set data.
      data = {
        coords: newCoords,
        distance: newDistance,
        normalizedDistance: newNormalizedDistance,
        // use these more specific properties to avoid confusion later
        distanceInc: latest.normalizedDistance,
        distanceTotal: newNormalizedDistance,
      };

      onChange(data);
    }, sampleRate);
  };

  const _unsubscribe = () => {
    return clearInterval(myInterval);
  };

  const self = {
    start: () => {
      // ensure we always start fresh.
      self.stop();

      _subscribe();
      return self;
    },
    stop: () => {
      data = getResetData();
      _unsubscribe();
      return self;
    },
    reset: () => {
      data = getResetData();
      return self;
    },
    getData: () => data,
  };

  return self;
};

export default MockMotionTracker;
