import React, { useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, TextInput, View } from 'react-native';
import siteCopy from 'utils/siteCopy';
import ContentBox from 'components/ContentBox';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { sizes, colors } from 'theme';
import NavProvider from 'navigator/NavProvider';
import { savePortalCode } from 'slices/app.slice';
import { purge } from 'utils/store';

const HomePortal = () => {
  const Navigator = useContext(NavProvider);
  const {userProfile, portalCode} = useSelector((state) => state.app);
  const userName = userProfile.name || '';
  const [portalCodeInputText, setPortalCodeInputText] = useState('');
  const portalCodeRef = useRef();
  const dispatch = useDispatch();

  const submitPortalCode = (code) => {
    dispatch(savePortalCode({
      portalCode: code,
    }));
  };

  return (
    <View style={styles.root}>
      {portalCode ? (
        <>
          <Text style={styles.h1}>
            {userName ? `${siteCopy.greetingWithNoName}, ${userName}` : siteCopy.greetingWithNoName}
          </Text>
          <ContentBox useBackground={false}>
            <Text style={styles.textInput}>Synced with app Code: {portalCode}</Text>
            <ButtonPrimary
              title={'Disconnect'}
              onPress={() => {
                purge();
              }}
              style={[styles.btn, styles.btnRed]}
            />
          </ContentBox>
          <ContentBox title={siteCopy.viewScreen} useBackground={false}>
            <ButtonPrimary
              title={'Calibrate'}
              onPress={() => {
                Navigator.navigate('Calibrate');
              }}
              style={styles.btn}
            />
            <ButtonPrimary
              title={'Game'}
              onPress={() => {
                Navigator.navigate('Game');
              }}
              style={styles.btn}
            />
          </ContentBox>
        </>
      ) : (
        <View>
          {/* note: consider #consolodatePortalCodeForms */}
          <Text style={styles.textInput}>{siteCopy.pleaseEnterCode}</Text>
          <TextInput
            style={[styles.textBox, styles.formInput]}
            autoCorrect={false}
            ref={portalCodeRef}
            placeholder={'Your Code'}
            placeholderTextColor={colors.grayLight}
            autoCapitalize="none"
            onChangeText={(value) => {
              setPortalCodeInputText(value);
            }}
            onKeyPress={(e) => {
              // desktop only - let enter send the form
              const eventKey = e.key;

              if (eventKey && eventKey === 'Enter') {
                submitPortalCode(portalCodeInputText);
              }
            }}
          />
          <ButtonPrimary
            title={'Submit'}
            onPress={() => {
              submitPortalCode(portalCodeInputText);
            }}
            style={[styles.btn]}
          />
        </View>
      )}
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
    alignItems: 'center',
  },
  formWrap: {},
  h1: {
    alignSelf: 'flex-start',
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
  },
  btn: {
    marginBottom: sizes.xs,
  },
  btnRed: {
    backgroundColor: colors.red,
    color: colors.white,
  },
  // todo #textBoxStylesConsolidate
  textBox: {
    padding: sizes.base / 2,
    borderWidth: 1,
    borderColor: colors.grayLighter,
  },
  textInput: {
    paddingVertical: sizes.base / 2,
  },
  formInput: {
    marginBottom: sizes.sm,
  },
};

export default HomePortal;
