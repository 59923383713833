import React from 'react';
import { View, Text } from 'react-native';
import { sizes, colors } from 'theme';
import { STAT_HIGHLIGHT_BOX_TYPES } from 'utils/constants';
import {
  calcSteps,
  calcMilesBiked,
  calcMilesWalked,
  calcMinutesRan,
  calcLifetimeMinutes,
} from 'utils/dataUtils';

const StatHighlightBox = (props) => {
  let calData = null;
  let typeTitle = '';
  let calories = props.calories;

  switch (props.type) {
    case STAT_HIGHLIGHT_BOX_TYPES.calories:
      calData = calories;
      typeTitle = 'Calories';
      break;
    case STAT_HIGHLIGHT_BOX_TYPES.steps:
      calData = calcSteps(calories);
      typeTitle = 'Steps';
      break;
    case STAT_HIGHLIGHT_BOX_TYPES.milesBiked:
      calData = calcMilesBiked(calories);
      typeTitle = 'Miles Biked';
      break;
    case STAT_HIGHLIGHT_BOX_TYPES.minutesRan:
      calData = calcMinutesRan(calories);
      typeTitle = 'Minutes Ran';
      break;
    case STAT_HIGHLIGHT_BOX_TYPES.milesWalked:
      calData = calcMilesWalked(calories);
      typeTitle = 'Miles Walked';
      break;
    case STAT_HIGHLIGHT_BOX_TYPES.lifetimeMinutes:
      calData = calcLifetimeMinutes(calories);
      typeTitle = 'Lifetime Minutes Saved';
      break;
    default:
      throw 'error: unexpected STAT_HIGHLIGHT_BOX_TYPES';
  }

  return (
    <View style={styles.boxWrap}>
      <View style={styles.boxWrapInner}>
        <View style={styles.contentWrap}>
          <Text style={styles.textHeader}>{typeTitle}</Text>
          <Text style={styles.textStat}>{calData}</Text>
        </View>
      </View>
    </View>
  );
};

const headerTextSize = sizes.md;

const styles = {
  boxWrap: {
    width: '50%',
    padding: sizes.frac2,
  },
  boxWrapInner: {
    borderRadius: sizes.base,
    paddingBottom: '100%',
    backgroundColor: colors.grayLighter,
  },
  contentWrap: {
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    marginTop: '50%',
    // fix centering based on text size
    transform: [{ translateY: -headerTextSize * 1.5 }],
  },
  textHeader: {
    fontSize: headerTextSize,
    textAlign: 'center',
    padding: sizes.frac4,
    paddingBottom: sizes.frac4,
  },
  textStat: {
    fontSize: sizes.lg,
    textAlign: 'center',
  },
};

export default StatHighlightBox;
