import React from 'react';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';

const ContentHeader = ({ title }) =>
  title ? (
    <View style={styles.header}>
      <Text style={styles.headerText}>{title}</Text>
    </View>
  ) : null;

const styles = {
  header: {
    paddingVertical: sizes.sm,
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  headerText: {
    fontSize: sizes.fonts.h2,
  },
};

export default ContentHeader;
