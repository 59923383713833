import { Asset } from 'expo-asset';

const images = {
  logo: require('assets/logos/qor360-logo-square.png'),
  logoCrop: require('assets/logos/qor360-logo-square-crop.png'),
  logoNoText: require('assets/logos/qor360-logo-no-text.png'),
  comingSoon: require('assets/images/coming-soon.png'),
  activeSittingStreak: require('assets/images/active-sitting-streak-text.png'),
  chairBg: require('assets/images/red-chair-bg.jpg'),
  turnerAndLex: require('assets/images/turner-and-lex.jpg'),
  tutorialVideo: require('assets/videos/getting-started-video.mp4'),
};

const imgRatios = {
  chairBg: 0.56243,
};

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key]).downloadAsync()
);

export { imgRatios };
export default images;
