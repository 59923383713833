import colors from './colors';
import sizes from './sizes';

const mixins = {
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
  },
  topBorder: {
    borderTopWidth: 1,
    borderTopColor: colors.grayLightest,
  },
  hyperLink: {
    alignSelf: 'center',
    color: colors.blue,
    padding: sizes.base / 2,
  },
};

export default mixins;
