import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import DotInTheSpot from 'components/Games/DotInTheSpot/DotInTheSpot';
import SpotGenerator from 'utils/SpotGenerator';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import {
  GAME_DIFFICULTY_TYPES,
  SOCKET_ACTIONS,
  ANIM_DURATION,
  GAME_SPOT_MOVE_DURATION,
  SPOT_WIDTH,
  DEFAULT_COORDS,
} from 'utils/constants';

const DotInTheSpotApp = ({
  gameDifficulty,
  gameType,
  doEndGame,
  incScore,
  setCurrentStage,
}) => {
  const {portalCode} = useSelector((state) => state.app);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const spotAnimSpeed =
    gameDifficulty === GAME_DIFFICULTY_TYPES.hard
      ? GAME_SPOT_MOVE_DURATION * 0.5
      : GAME_SPOT_MOVE_DURATION;
  const [spotTarget, setSpotTarget] = useState(DEFAULT_COORDS);
  const [dotRealCoords, setDotRealCoords] = useState(DEFAULT_COORDS);
  const [spotRealCoords, setSpotRealCoords] = useState(DEFAULT_COORDS);
  const [isColliding, setIsColliding] = useState(false);

  // A ref is also needed to ensure the socket.io always fires correctly
  const isCollidingRef = useRef(false);
  const { calibratedCoords } = useSharedMotionState();

  const checkIsColliding = () => {
    const distX = dotRealCoords.x - spotRealCoords.x;
    const distY = dotRealCoords.y - spotRealCoords.y;

    const distance = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));

    if (distance < SPOT_WIDTH / 2) {
      setIsColliding(true);
      isCollidingRef.current = true;

      incScore();
    } else {
      setIsColliding(false);
      isCollidingRef.current = false;
    }
  };

  const onSpotChange = (data) => {
    const stageName = data.name;
    const coords = {
      x: data.x,
      y: data.y,
      z: data.z,
    };

    setSpotTarget(coords);
    setCurrentStage(stageName);
  };

  useEffect(() => {
    const spotGenerator = new SpotGenerator({
      onChange: onSpotChange,
      sampleRate: spotAnimSpeed,
      gameType: gameType,
    }).start();

    return () => {
      spotGenerator.stop();
      doEndGame();
    };
  }, []);

  useEffect(() => {
    checkIsColliding();
  }, [dotRealCoords, spotRealCoords]);

  useEffect(() => {
    socket.emit(SOCKET_ACTIONS.gameUpdated, {
      dotCoords: calibratedCoords,
      spotCoords: spotTarget,
      shortCode: portalCode,
      isColliding: isColliding,
    });
  }, [socket, portalCode, calibratedCoords, spotTarget, isColliding]);

  return (
    <DotInTheSpot
      gameDotData={calibratedCoords}
      gameDotSpeed={ANIM_DURATION}
      gameDotSetter={setDotRealCoords}
      spotData={spotTarget}
      isColliding={isColliding}
      gameSpotSpeed={spotAnimSpeed}
      gameSpotSetter={setSpotRealCoords}
    />
  );
};

export default DotInTheSpotApp;
