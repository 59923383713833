/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { getDummySessions } from 'utils/mockData';
import { getSessionIndex, roundNicely } from 'utils/dataUtils';
import { TIME_TYPES } from 'utils/constants';

// If this is true, when you log out, it will pre-populate sessions.
const SHOULD_USE_DUMMY_DATA = false;

const sessionDataDefault = {
  calories: 0,
  secondsActive: 0,
  subSessions: [],
  hourKey: null,
  dayKey: null,
  monthKey: null,
  timestamp: null,
};

const buildIndexPerDay = (sessions) => {
  return getSessionIndex(sessions, TIME_TYPES.day);
};

const buildIndexPerHour = (sessions) => {
  return getSessionIndex(sessions, TIME_TYPES.hour);
};

const getTotalCals = (sessions) => {
  return roundNicely(sessions.reduce((prev, curr) => prev + curr.calories, 0));
};

const getTotalSecondsActive = (sessions) => {
  return Math.floor(sessions.reduce((prev, curr) => prev + curr.secondsActive, 0));
};

const reIndex = (state) => {
  state.indexPerDay = buildIndexPerDay(state.sessions);
  state.indexPerHour = buildIndexPerHour(state.sessions);
  state.totalCals = getTotalCals(state.sessions);
  state.totalSecondsActive = getTotalSecondsActive(state.sessions);
};

const sessionsListDefault = SHOULD_USE_DUMMY_DATA ? getDummySessions() : [];
const hourSessionsListDefault = SHOULD_USE_DUMMY_DATA ? getDummySessions() : [];

const initialState = {
  sessions: sessionsListDefault,
  subSessionsPending: [],
  indexPerDay: buildIndexPerDay(sessionsListDefault),
  indexPerHour: buildIndexPerHour(hourSessionsListDefault),
  totalCals: getTotalCals(sessionsListDefault),
  totalSecondsActive: getTotalSecondsActive(sessionsListDefault),
};

// ------------------------------------
// Slice
// ------------------------------------
const chairSessionsSlice = createSlice({
  name: 'chairSessions',
  initialState,
  reducers: {
    saveAllChairSessions: (state, { payload }) => {
      const sessionData = payload.sessions;

      // overwrite everything. The portal will use this to stay in sync.
      if (sessionData) {
        state.sessions = sessionData;
        state.subSessionsPending = [];
        reIndex(state);
      }
    },
    saveChairSession: (state, { payload }) => {
      const sessionData = payload;
      const validKeys = Object.keys(sessionDataDefault);
      const newSession = {};

      validKeys.forEach((key, idx) => {
        const newVal = sessionData[key];
        const isValid =
          typeof newVal !== 'undefined' && (
            (newVal === null || typeof newVal === 'number' || typeof newVal === 'string') ||
            (key === 'subSessions' && Array.isArray(newVal))
          )
        if (isValid) {
          newSession[key] = newVal;
        }
      });

      state.sessions = state.sessions.concat(newSession);
      // Rebuild index.
      // If we scaled up greatly, this could be made efficient, but it's not consequential atm.
      reIndex(state);
    },
    savePendingSubsessions: (state, { payload }) => {
      const pendingSubsessions = payload;
      const isValid = Array.isArray(pendingSubsessions)
      if (isValid) {
        state.subSessionsPending = pendingSubsessions;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export const { saveChairSession, saveAllChairSessions, savePendingSubsessions } = chairSessionsSlice.actions;

export default chairSessionsSlice.reducer;
