import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, Image, Pressable } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import FontIcon from 'react-native-vector-icons/FontAwesome5';
import { sizes, colors, images, mixins } from 'theme';
import siteCopy from 'utils/siteCopy';
import { IS_PORTAL } from 'utils/constants';
import NavProvider from 'navigator/NavProvider';
const headerTitle = IS_PORTAL ? siteCopy.brandNamePortal : siteCopy.brandName;

const AppHeader = () => {
  const navigation = useContext(NavProvider);
  const {userProfile} = useSelector((state) => state.app);
  const profileImg = userProfile.image || null;

  return (
    <View>
      <View style={styles.headerWrap}>
        <View style={styles.logoButton}>
          <Pressable
            onPress={() => {
              navigation.dispatch(DrawerActions.toggleDrawer());
            }}>
            <Image source={images.logo} style={styles.headerLogo} />
          </Pressable>
        </View>
        <Text style={styles.headerH1}>{headerTitle}</Text>
        <Pressable
          style={styles.headerProfileButton}
          onPress={() => {
            navigation.navigate('Profile');
          }}>
          {!profileImg && (
            <FontIcon name="user-circle" style={styles.profileIcon} color={colors.gray} />
          )}
          {!!profileImg && <Image source={{ uri: profileImg }} style={styles.profilePic} />}
        </Pressable>
      </View>
    </View>
  );
};

const headerImgPadding = sizes.md;
const headerImgSize = sizes.headerHeight - headerImgPadding;
const marginForHeaderHoriz = sizes.base;
const headerImgMulit = 0.8;

const styles = {
  headerWrap: {
    height: sizes.headerHeight,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    ...mixins.bottomBorder,
  },
  headerLogo: {
    resizeMode: 'contain',
    alignItems: 'center',
    justifyContent: 'center',
    height: headerImgSize,
    width: headerImgSize,
  },
  headerProfileButton: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: marginForHeaderHoriz,
  },
  logoButton: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: headerImgSize,
    width: headerImgSize,
    position: 'absolute',
    left: marginForHeaderHoriz,
  },
  profileIcon: {
    // use the font size to scale it about right. Use a fudge factor to make it look right
    fontSize: headerImgSize,
  },
  profilePic: {
    height: headerImgSize,
    width: headerImgSize,
    borderRadius: headerImgSize,
    resizeMode: 'cover',
  },
  profilePicWrap: {
    position: 'relative',
  },
  headerH1: {
    fontSize: sizes.lg,
  },
};

export default AppHeader;
