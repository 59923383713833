import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { IS_PORTAL, SOCKET_ACTIONS } from 'utils/constants';
import { useSharedTimer } from 'utils/TimerController/TimerProvider';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import { saveIsActive, saveCalibration, saveActiveTime } from 'slices/activeSession.slice';
import { saveUserProfile } from 'slices/app.slice';
import { parseChairSession } from 'utils/dataUtils';
import { saveAllChairSessions, saveChairSession, savePendingSubsessions } from 'slices/chairSessions.slice';

const DataSyncController = ({ children }) => {
  const [timeData] = useSharedTimer();
  const { portalCode, userProfile } = useSelector((state) => state.app);
  const { isActive, calibration } = useSelector((state) => state.activeSession);
  const chairSessionsState = useSelector((state) => state.chairSessions);
  const { currentCals, sessionCals } = useSharedMotionState();
  const dispatch = useDispatch();
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const isActivePrev = useRef();

  const activeSessionReducers = {
    saveIsActive,
    saveCalibration,
    saveActiveTime,
  };

  const appStateReducers = {
    saveUserProfile,
  };

  const chairSessionsReducers = {
    saveAllChairSessions,
  };

  // this is only used if the app crashed before the session ended which leaves stranded pending subsessions.
  const processPendingSubSessions = (subSessions) => {
    if (!subSessions || !subSessions.length > 0) {
      return;
    }

    const data = parseChairSession(subSessions);
    dispatch(saveChairSession(data));

    // reset the pending subsessions
    dispatch(savePendingSubsessions([]));
  }

  /////////////////////////
  /// App Only (not portal): On load, check for pending subSessions that could have been stranded if the app crashed
  /////////////////////////
  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }
    processPendingSubSessions(chairSessionsState.subSessionsPending);
  }, []);
  /////////////////////////

  /////////////////////////
  /// App Only (not portal): Emit realtimeCalsUpdated when it changes
  /////////////////////////
  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    socket.emit(SOCKET_ACTIONS.realtimeCalsUpdated, {
      currentCals,
      sessionCals,
      shortCode: portalCode,
    });
  }, [portalCode, currentCals, sessionCals]);
  /////////////////////////

  /////////////////////////
  /// App Only (not portal): Emit session whenever data changes
  /////////////////////////
  const emitSession = (portalCode, calibration, isActive, timeData) => {
    let sendData = {
      shortCode: portalCode,
      isActive: isActive,
    };

    if (!!timeData) {
      sendData.time = timeData.time;
      sendData.formattedTime = timeData.formattedTime;
    }

    if (!!calibration) {
      sendData.calibration = calibration;
    }

    socket.emit('sessionUpdated', sendData);
  };

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    emitSession(portalCode, calibration);
  }, [portalCode, calibration]);

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    // let the app tell the portal its data has been updated
    if (isActivePrev.current !== isActive) {
      emitSession(portalCode, null, isActive, timeData);
    }

    isActivePrev.current = isActive;
  }, [portalCode, isActive, timeData]);
  /////////////////////////

  /////////////////////////
  /// App Only (not portal): Emit appStateUpdated whenever userProfile updates
  /////////////////////////
  const emitAppStateUpdated = (portalCode, userProfile) => {
    socket.emit('appStateUpdated', {
      shortCode: portalCode,
      userProfile,
    });
  };

  const emitChairSessionsStateUpdated = (portalCode, chairSessionsState) => {
    socket.emit('chairSessionsStateUpdated', {
      shortCode: portalCode,
      chairSessionsState,
    });
  };

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    // let the app tell the portal its data has been updated
    emitAppStateUpdated(portalCode, userProfile);
    emitChairSessionsStateUpdated(portalCode, chairSessionsState);
  }, [portalCode, userProfile]);
  /////////////////////////

  /////////////////////////
  /// App Only (not portal): Watch for requestDataState and send the data with emitSession
  /////////////////////////
  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    const requestDataStateHook = (data={}) => {
      const { which } = data;
      // note: #requestDataStateLimited
      // quick fix to resolve an over-rendering problem caused by this resetting too much data when it didn't change.
      if (which === 'chairSessions') {
        emitChairSessionsStateUpdated(portalCode, chairSessionsState);
      } else {
        emitSession(portalCode, calibration, isActive, timeData);
        emitAppStateUpdated(portalCode, userProfile);
        emitChairSessionsStateUpdated(portalCode, chairSessionsState);
      }
    };

    // The app should watch for session requests, and send the info as needed.
    // This happens on the portal initial load.
    socket.on(SOCKET_ACTIONS.requestDataState, requestDataStateHook);

    return () => {
      socket.off(SOCKET_ACTIONS.requestDataState, requestDataStateHook);
    };
  }, [portalCode, isActive, calibration, timeData, userProfile, chairSessionsState]);
  /////////////////////////

  /////////////////////////
  /// App Only (not portal): The app listens for the dispatchProxy from the Portal
  /////////////////////////
  const dispatchProxyHook = (data) => {
    const slice = data.slice;
    const value = data.value;
    let reducers;
    let validAction;

    switch (slice) {
      case 'app':
        reducers = appStateReducers;
        break;
      case 'activeSession':
        reducers = activeSessionReducers;
        break;
      case 'chairSessions':
        reducers = chairSessionsReducers;
        break;
      default:
        throw 'error: unexpected slice name';
    }

    validAction = reducers[data.reducer];

    if (validAction) {
      dispatch(validAction(value));
    } else {
      throw `error: invalid proxy call: [slice: ${slice}, value: ${value}, reducer: ${data.reducer}]`;
    }
  };

  useEffect(() => {
    if (IS_PORTAL) {
      return;
    }

    socket.on(SOCKET_ACTIONS.dispatchProxy, dispatchProxyHook);

    return () => {
      socket.off(SOCKET_ACTIONS.dispatchProxy, dispatchProxyHook);
    };
  }, [socket, portalCode]);
  /////////////////////////

  /////////////////////////
  /// Portal only: Request the session on start. Then listen for updates and update our data state
  /////////////////////////
  const sessionUpdatedHook = (data) => {
    typeof data.isActive !== 'undefined' && dispatch(saveIsActive(data.isActive));
    typeof data.calibration !== 'undefined' && dispatch(saveCalibration(data.calibration));
    typeof data.time !== 'undefined' && dispatch(saveActiveTime(data.time));
  };

  const appStateUpdatedHook = (data) => {
    dispatch(saveUserProfile(data.userProfile));
  };

  const chairSessionsStateUpdatedHook = (data) => {
    dispatch(saveAllChairSessions(data.chairSessionsState));
  };

  useEffect(() => {
    if (!IS_PORTAL) {
      return;
    }

    // get the session on init
    socket.emit(SOCKET_ACTIONS.requestDataState, {
      shortCode: portalCode,
    });

    // and listen for updates
    socket.on(SOCKET_ACTIONS.sessionUpdated, sessionUpdatedHook);
    socket.on(SOCKET_ACTIONS.appStateUpdated, appStateUpdatedHook);
    socket.on(SOCKET_ACTIONS.chairSessionsStateUpdated, chairSessionsStateUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.sessionUpdated, sessionUpdatedHook);
      socket.off(SOCKET_ACTIONS.appStateUpdated, appStateUpdatedHook);
      socket.off(SOCKET_ACTIONS.chairSessionsStateUpdated, chairSessionsStateUpdatedHook);
    };
  }, [socket, portalCode]);
  /////////////////////////

  return children;
};

export default DataSyncController;
