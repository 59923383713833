import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Pressable, Alert, Platform } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import Masthead from 'components/Masthead';
import Button from 'components/Button';
import FontIcon from 'react-native-vector-icons/FontAwesome5';
import { sizes, colors, images } from 'theme';
import siteCopy from 'utils/siteCopy';
import { purge } from 'utils/store';

const DrawerBody = ({ navigation }) => {
  const goToPage = (pageTitle) => {
    navigation.dispatch(DrawerActions.closeDrawer());
    navigation.navigate(pageTitle);
  };

  const goToAboutPage = () => {
    goToPage('About')
  };

  const purgeDataAlertMobile = () =>
    Alert.alert(siteCopy.alertTitle, siteCopy.alertText, [
      {
        text: 'Continue',
        onPress: () => {
          navigation.dispatch(DrawerActions.closeDrawer());
          purge();
        },
      },
      {
        text: 'Cancel',
        onPress: () => {},
        style: 'cancel',
      },
    ]);

  const purgeDataWeb = () => {
    navigation.dispatch(DrawerActions.closeDrawer());
    purge();
  };

  return (
    <View style={styles.drawerContents}>
      <View style={styles.closeButton}>
        <FontIcon.Button
          name="times"
          size={20}
          color={colors.white}
          backgroundColor={'transparent'}
          onPress={() => {
            navigation.dispatch(DrawerActions.closeDrawer());
          }}
        />
      </View>
      <Masthead />
      <View style={styles.mainContent}>
        <Pressable onPress={goToAboutPage}>
          <Text style={styles.paragraph}>{siteCopy.companyBio}…</Text>
        </Pressable>
        <View>
          <Button title="About Us" onPress={goToAboutPage} style={styles.pageButton} />
          <Button title={siteCopy.tutorialPageTitle} onPress={() => {goToPage('Tutorial')}} style={styles.pageButton} />
          <Button
            title="Profile"
            onPress={() => {
              navigation.dispatch(DrawerActions.closeDrawer());
              navigation.navigate('Profile');
            }}
            style={styles.pageButton}
          />
          <Button
            title={siteCopy.goToGameCTA}
            onPress={() => {
              navigation.dispatch(DrawerActions.closeDrawer());
              navigation.navigate('Game');
            }}
            style={styles.pageButton}
          />
          <Button
            title={siteCopy.goToCalibrateCTA}
            onPress={() => {
              navigation.dispatch(DrawerActions.closeDrawer());
              navigation.navigate('Calibrate');
            }}
            style={styles.pageButton}
          />
          <Button
            title={siteCopy.datadump}
            onPress={() => {
              navigation.dispatch(DrawerActions.closeDrawer());
              navigation.navigate('Datadump');
            }}
            style={styles.pageButton}
          />
          <Button
            title={siteCopy.logout}
            onPress={() => {
              Platform.OS === 'web' ? purgeDataWeb() : purgeDataAlertMobile();
            }}
            style={styles.pageButton}
          />
        </View>
        <View>
          <Text style={styles.versionText}>
            {siteCopy.versionNotice}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = {
  drawerContents: {},
  closeButton: {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    right: 0,
  },
  mainContent: {
    padding: sizes.base,
  },
  paragraph: {
    marginBottom: sizes.base,
  },
  versionText: {
    display: 'flex',
    fontStyle: 'italic',
    alignSelf: 'center',
    fontSize: sizes.sm,
    color: colors.grayLighter,
  },
  pageButton: {
    marginBottom: sizes.base,
    marginHorizontal: sizes.base,
  },
};

export default DrawerBody;
