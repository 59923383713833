import React, { useState, useEffect, useRef } from 'react';
import { Text, View, Animated, Easing, Platform } from 'react-native';
import coordParser from 'utils/coordParser';
import { DOT_WIDTH, GAME_CHECK_THROTTLE } from 'utils/constants';
import { throttle } from 'lodash';
import { colors } from 'theme';

const PongBall = ({ data, animSpeed, setRealCoords }) => {
  // note: #animationPerformance see // https://eveningkid.medium.com/the-basics-of-react-native-animations-fb00a8ccc178
  const ballPos = useRef(new Animated.ValueXY(data)).current;

  const animationCleanup = () => {
    ballPos.stopAnimation();
    ballPos.removeAllListeners();
  }

  useEffect(() => {
    if (!setRealCoords) {
      return;
    }
    const throttled = throttle(
      (data) => {
        setRealCoords(data);
      },
      // speed up the throttle, or the ball will sometimes calc too slow and fall through.
      // todo: GAME_CHECK_THROTTLE Unify
      GAME_CHECK_THROTTLE / 6,
      {
        leading: true,
        trailing: false,
      }
    );

    ballPos.addListener((data) => {
      throttled(data);
    });

    return () => {
      animationCleanup();
    };
  }, [ballPos, setRealCoords]);

  useEffect(() => {
    Animated.timing(ballPos, {
      toValue: coordParser.parseSpotCoords(data),
      duration: animSpeed,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.linear,
    }).start();

    return () => {
      ballPos.stopAnimation();
    };
  }, [data.x, data.y]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        transform: ballPos.getTranslateTransform(),
      }}>
      <View style={styles.PongBall} />
    </Animated.View>
  );
};

const styles = {
  PongBall: {
    position: 'absolute',
    borderRadius: DOT_WIDTH,
    backgroundColor: colors.blue,
    width: DOT_WIDTH,
    height: DOT_WIDTH,
    transform: [{ translateY: -DOT_WIDTH / 2 }, { translateX: -DOT_WIDTH / 2 }],
  },
};

export default PongBall;
