import React, { useEffect, useRef } from 'react';
import { colors, sizes } from 'theme';
import { View, Text, Animated, Platform, Easing } from 'react-native';
import { REALTIME_CHART_INTERVAL } from 'utils/constants';

const borderWidth = 1;
const padStartAmount = 6;
const borderColor = colors.gray;
const animSpeed = REALTIME_CHART_INTERVAL;
const numberFontSize = 30;
const digitWrapHeight = numberFontSize * 1.5;
const digitWrapOffsetUnit = digitWrapHeight;
const maxWrap = digitWrapOffsetUnit * 10;
// add a tiny nudge to compensate for slight style inconsistency.
const digitnudge = Platform.OS === 'android' ? 1 : 0;

const getDigitsAndTenths = (num) => {
  const decimalPlaces = 1;
  const roundedDigitsWithTenths = Math.round(num * (10 * decimalPlaces), 10);

  const digits = roundedDigitsWithTenths
    .toString()
    .padStart(padStartAmount, '0')
    .split('')
    .map(Number);
  const tenths = digits.pop();
  return [digits, tenths];
};

const getDigitOffset = (digit) => {
  // a number to make the digits offset the right height
  const itemHeight = digitWrapOffsetUnit;
  const offset = -digit * itemHeight + digitnudge;
  return offset;
};

const Odometer = ({ sessionCals }) => {
  const digitArrays = getDigitsAndTenths(sessionCals);
  const digitArray = digitArrays[0];
  const digitTenths = digitArrays[1];
  const offset = getDigitOffset(digitTenths);
  const prevDigit = useRef(0);
  const transValue = useRef(new Animated.Value(offset)).current;

  useEffect(() => {
    // if the number cycles back, make it jump instantly.
    // const animSpeedVal = prevDigit.current <= digitTenths ? animSpeed : 0;
    let animSpeedVal = animSpeed;
    let target = offset;
    let queuedTimeout;

    if (prevDigit.current > digitTenths) {
      // set the wrap to the 0 at the end... with a half-duration...
      // and also queue up a trigger a half-duration
      target = -maxWrap;
      animSpeedVal = animSpeed / 2;
      // and then queue up
      queuedTimeout = setTimeout(() => {
        transValue.setValue(0);
        Animated.timing(transValue, {
          toValue: offset,
          duration: animSpeed / 2,
          useNativeDriver: Platform.OS !== 'web',
          easing: Easing.linear,
        }).start();
      }, animSpeed / 2);
    }

    Animated.timing(transValue, {
      toValue: target,
      duration: animSpeedVal,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.linear,
    }).start();

    prevDigit.current = digitTenths;
    return () => {
      clearTimeout(queuedTimeout);
      transValue.stopAnimation();
    };
  }, [offset]);

  return (
    <View style={styles.root}>
      <View style={styles.outerWrap}>
        {digitArray.map((item, idx) => {
          const wrapStyle = idx === 0 ? {} : {
            // the borderRightWidth property isnt working on ios, so use this instead.
            marginLeft: -borderWidth,
          };

          return (
            <View key={`digit-wrap-${idx}`}>
              <View style={[styles.digitWrap, wrapStyle]}>
                <Text style={styles.digit}>{item}</Text>
              </View>
            </View>
          );
        })}
      </View>
      <>
        <Text style={[styles.digit, styles.digitDecimalDot]}>.</Text>
        <View style={styles.outerWrap}>
          <View style={[styles.digitWrap, {justifyContent: 'flex-start'}]}>
            <Animated.View
              style={{
                transform: [{ translateY: transValue }],
              }}>
              <Text style={styles.digit}>{0}</Text>
              <Text style={styles.digit}>{1}</Text>
              <Text style={styles.digit}>{2}</Text>
              <Text style={styles.digit}>{3}</Text>
              <Text style={styles.digit}>{4}</Text>
              <Text style={styles.digit}>{5}</Text>
              <Text style={styles.digit}>{6}</Text>
              <Text style={styles.digit}>{7}</Text>
              <Text style={styles.digit}>{8}</Text>
              <Text style={styles.digit}>{9}</Text>
              <Text style={styles.digit}>{0}</Text>
            </Animated.View>
          </View>
        </View>
      </>
    </View>
  );
};

const styles = {
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: sizes.xs,
  },
  outerWrap: {
    flexDirection: 'row',
    borderWidth: borderWidth,
    borderColor: colors.black,
    borderRadius: 2,
  },
  digitWrap: {
    borderWidth: borderWidth,
    borderColor: borderColor,
    width: numberFontSize,
    height: digitWrapHeight,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  digit: {
    fontSize: numberFontSize,
    paddingVertical: numberFontSize / 7,
    textAlign: 'center',
    height: digitWrapHeight,
  },
  digitDecimalDot: {
    width: numberFontSize / 3,
    height: digitWrapHeight,
    textAlign: 'center',
    borderWidth: 0,
  },
};

export default Odometer;
