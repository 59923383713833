import { Accelerometer } from 'expo-sensors';
import { Platform } from 'react-native';
import { calcDistances } from 'utils/dataUtils';
import { DEFAULT_COORDS, DEFAULT_SAMPLE_RATE } from 'utils/constants';

const defaultSampleRate = DEFAULT_SAMPLE_RATE;

// correct for inconsistencies. See https://github.com/expo/expo/issues/19229
const transform =
  Platform.OS === 'android'
    ? { x: -1, y: -1, z: 1 }
    : Platform.OS === 'ios'
    ? { x: 1, y: 1, z: 1 }
    : { x: 1, y: 1, z: 1 };

const MotionTracker = (options) => {
  const settings = options || {};
  const onChange = settings.onChange || (() => {});
  const sampleRate = options.sampleRate || defaultSampleRate;

  const defaultData = {
    coords: DEFAULT_COORDS,
    distance: 0,
    normalizedDistance: 0,
  };

  const getResetData = () => {
    return Object.assign({}, defaultData);
  };

  let subscription = null;
  let data = getResetData();

  const _subscribe = () => {
    subscription = Accelerometer.addListener((accelerometerData) => {
      const newCoords = {
        x: accelerometerData.x * transform.x,
        y: accelerometerData.y * transform.y,
        z: accelerometerData.z * transform.z,
      };

      const latest = calcDistances(newCoords, data.coords);
      const newDistance = data.distance + latest.distance;
      const newNormalizedDistance = data.normalizedDistance + latest.normalizedDistance;

      // set data.
      data = {
        coords: newCoords,
        distance: newDistance,
        normalizedDistance: newNormalizedDistance,
        // use these more specific properties to avoid confusion later
        distanceInc: latest.normalizedDistance,
        distanceTotal: newNormalizedDistance,
      };

      onChange(data);
    });
    Accelerometer.setUpdateInterval(sampleRate);
  };

  const _test = () => {
    return Accelerometer.isAvailableAsync();
  };

  const _unsubscribe = () => {
    subscription && subscription.remove();
    subscription = null;
  };

  const self = {
    test: () => {
      return _test();
    },
    start: () => {
      // ensure we always start fresh.
      self.stop();

      _subscribe();
      return self;
    },
    stop: () => {
      data = getResetData();
      _unsubscribe();
      return self;
    },
    reset: () => {
      data = getResetData();
      return self;
    },
    getData: () => data,
  };

  return self;
};

export default MotionTracker;
