import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, Image } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import FontIcon from 'react-native-vector-icons/FontAwesome5';
import { sizes, colors, images } from 'theme';
import { calcDrawerWidth } from 'utils/scaling';
import siteCopy from 'utils/siteCopy';
import { createDrawerNavigator, DrawerContentScrollView } from '@react-navigation/drawer';
import DrawerBody from './DrawerBody';

import PageFrame from 'components/PageFrame';
import Home from 'pages/Home';
import HomePortal from 'pages/HomePortal';

const Drawer = createDrawerNavigator();

import { IS_PORTAL } from 'utils/constants';

const CompHome = () => (
  <PageFrame>
    {
      IS_PORTAL ? <HomePortal /> : <Home />
    }
  </PageFrame>
);

const DrawerMenuContainer = (props) => {
  return (
    <DrawerContentScrollView
      {...props}
      // remove the annoying default padding here
      contentContainerStyle={{ paddingTop: 0 }}>
      <DrawerBody {...props} />
    </DrawerContentScrollView>
  );
};

const DrawerNavigator = () => (
  <Drawer.Navigator
    drawerContent={DrawerMenuContainer}
    screenOptions={{
      animationEnabled: false,
      drawerStyle: {
        width: calcDrawerWidth(),
      },
    }}>
    <Drawer.Screen name='MainDrawer' component={CompHome} options={{
      headerShown: false,
      title: 'Home'
    }} />
  </Drawer.Navigator>
);


export default DrawerNavigator;
