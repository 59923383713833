import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { View, Text } from 'react-native';
import { sizes, colors } from 'theme';
import Button from 'components/Button';
import BarChart from 'components/BarChart';
import BarChartNoDataWrap from 'components/BarChartNoDataWrap';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { CHART_TYPES, IS_PORTAL, SOCKET_ACTIONS, CALORIES_NAV_TYPES } from 'utils/constants';

const getChartType = (routeName) => {
  switch (routeName) {
    case CALORIES_NAV_TYPES.realtime:
      return CHART_TYPES.realtime;
      break;
    case CALORIES_NAV_TYPES.week:
      return CHART_TYPES.week;
      break;
    case CALORIES_NAV_TYPES.month:
      return CHART_TYPES.month;
    default:
      throw 'error: unexpected CALORIES_NAV_TYPES';
  }
};

const getChartContainer = (routeName) => {
  const chartType = getChartType(routeName);
  const chartTypeTitle = `${chartType.slice(0, 1).toUpperCase()}${chartType.slice(1)}`;

  // not the best matching, but okay for now..
  const chartTitle =
    chartTypeTitle.toLowerCase() === CALORIES_NAV_TYPES.realtime
      ? ``
      : `Calories Burned per ${chartTypeTitle}`;

  return () => (
    <View style={styles.subContainer}>
      <Text style={styles.headerText}>{chartTitle}</Text>
      <BarChart chartType={chartType} />
    </View>
  );
};

const RealtimeView = getChartContainer(CALORIES_NAV_TYPES.realtime);
const WeekView = getChartContainer(CALORIES_NAV_TYPES.week);
const MonthView = getChartContainer(CALORIES_NAV_TYPES.month);

const CaloriesTabsNavigator = () => {
  const {totalCals} = useSelector((state) => state.chairSessions);
  const {subNav} = useSelector((state) => state.activeSession);
  const {portalCode} = useSelector((state) => state.app);
  const userHasData = !!totalCals;
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const [pendingSyncedState, setPendingSyncedState] = useState(null);
  const [caloriesNavType, setCaloriesNavType] = useState(
    subNav.caloriesNavType || CALORIES_NAV_TYPES.realtime
  );

  useEffect(() => {
    if (!portalCode || IS_PORTAL) {
      return;
    }

    socket.emit(SOCKET_ACTIONS.subRouteUpdated, {
      caloriesNavType,
      shortCode: portalCode,
    });
  }, [portalCode, caloriesNavType]);

  useEffect(() => {
    if (!pendingSyncedState) {
      return;
    }

    if (IS_PORTAL) {
      let data = {
        shortCode: portalCode,
      };
      let dataProp;

      switch (pendingSyncedState.setter) {
        case setCaloriesNavType:
          dataProp = 'caloriesNavType';
          break;
        default:
          throw 'error: unexpected pendingSyncedState';
      }

      data[dataProp] = pendingSyncedState.value;
      socket.emit(SOCKET_ACTIONS.subRouteUpdated, data);
    } else {
      pendingSyncedState.setter(pendingSyncedState.value);
    }

    setPendingSyncedState(null);
  }, [portalCode, socket, pendingSyncedState, caloriesNavType, subNav]);

  const subRouteUpdatedHook = (data) => {
    const { caloriesNavType } = data;

    if (typeof caloriesNavType !== 'undefined') {
      setCaloriesNavType(caloriesNavType);
    }
  };

  useEffect(() => {
    if (!portalCode) {
      return;
    }

    socket.on(SOCKET_ACTIONS.subRouteUpdated, subRouteUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.subRouteUpdated, subRouteUpdatedHook);
    };
  }, [portalCode, socket]);

  const menuIdx =
    caloriesNavType === CALORIES_NAV_TYPES.month
      ? 2
      : caloriesNavType === CALORIES_NAV_TYPES.week
      ? 1
      : 0;

  const Menu = () => (
    <View style={styles.menuWrap}>
      <View style={styles.menu}>
        <Button
          title="Real Time"
          onPress={() => {
            setPendingSyncedState({
              setter: setCaloriesNavType,
              value: CALORIES_NAV_TYPES.realtime,
            });
          }}
          style={{
            ...styles.button,
            ...(menuIdx === 0 ? styles.buttonSelected : styles.buttonNotSelected),
          }}
        />
        <Button
          title="Week"
          onPress={() => {
            setPendingSyncedState({
              setter: setCaloriesNavType,
              value: CALORIES_NAV_TYPES.week,
            });
          }}
          style={{
            ...styles.button,
            ...(menuIdx === 1 ? styles.buttonSelected : styles.buttonNotSelected),
          }}
        />
        <Button
          title="Month"
          onPress={() => {
            setPendingSyncedState({
              setter: setCaloriesNavType,
              value: CALORIES_NAV_TYPES.month,
            });
          }}
          style={{
            ...styles.button,
            ...(menuIdx === 2 ? styles.buttonSelected : styles.buttonNotSelected),
          }}
        />
      </View>
    </View>
  );

  return (
    <View style={styles.root}>
      <Menu />
      {/* for the realtime view, the RealtimeView renders it's own BarChartNoDataWrap
        that does not jump to the homepage..Sorry this is confusing..
      */}
      {menuIdx === 0 ? (
        <View style={styles.body}>
          <RealtimeView />
        </View>
      ) : userHasData ? (
        <View style={styles.body}>
          {menuIdx === 1 && <WeekView />}
          {menuIdx === 2 && <MonthView />}
        </View>
      ) : (
        <BarChartNoDataWrap />
      )}
    </View>
  );
};

const styles = {
  root: {},
  body: {},
  subContainer: {
    marginTop: 20,
  },
  menuWrap: {
    marginBottom: sizes.base,
  },
  menu: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    marginHorizontal: sizes.buttonMargin,
  },
  buttonNotSelected: {},
  buttonSelected: {
    backgroundColor: colors.blueSavedState,
    color: colors.white,
  },
  headerText: {
    fontSize: sizes.fonts.h2,
    textAlign: 'center',
  },
};

export default CaloriesTabsNavigator;
