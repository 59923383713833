import React, { useRef, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, Pressable, TextInput } from 'react-native';
import FontIcon from 'react-native-vector-icons/FontAwesome5';
import { colors, sizes, mixins } from 'theme';
import NavProvider from 'navigator/NavProvider';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import ModalView from 'components/ModalView';
import FontIconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import siteCopy from 'utils/siteCopy';
import { savePortalCode } from 'slices/app.slice';
import { IS_PORTAL } from 'utils/constants';
import { purge } from 'utils/store';

const headerMenuScale = sizes.md;

const SubPageHeader = ({ shouldShowPortalBtn = false }) => {
  const navigation = useContext(NavProvider);
  const {portalCode} = useSelector((state) => state.app);
  const [modalVisible, setModalVisible] = useState(false);
  const [portalConnected, setPortalConnected] = useState(false);
  const [portalCodeInputText, setPortalCodeInputText] = useState('');
  const portalCodeRef = useRef();
  const dispatch = useDispatch();

  const submitPortalCode = (code) => {
    dispatch(
      savePortalCode({
        portalCode: code,
      })
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.headerWrap}>
        <Pressable
          onPress={() => {
            navigation.navigate('Home');
            // if (navigation.canGoBack()) {
            //   navigation.goBack();
            // } else {
            //   navigation.navigate('Home');
            // }
          }}
          style={styles.backBtn}>
          <FontIcon name="chevron-left" size={headerMenuScale} style={styles.backBtnIcon} />
          <Text style={styles.backBtnText}>Back</Text>
        </Pressable>
        {shouldShowPortalBtn ? (
          <>
            <Pressable onPress={() => setModalVisible(true)}>
              <View style={styles.portalInfo}>
                {
                  !!portalCode ? (
                    <Text style={styles.portalCodeText}>{portalCode}</Text>
                  ) : (
                    <Text style={[styles.portalCodeText, styles.warningText]}>{IS_PORTAL ? `Enter Code` : `connecting...`}</Text>
                  )
                }
                <FontIconMaterial
                  name="cast"
                  size={headerMenuScale * 2}
                  color={portalConnected ? colors.green : colors.blue}
                />
              </View>
            </Pressable>
          </>
        ) : null}
        <ModalView isVisible={modalVisible} setIsVisible={setModalVisible}>
          {IS_PORTAL ? (
            <>
              <Text style={styles.h1}>Screencast from Device</Text>
              <Text>{siteCopy.portalModalOnPortal}</Text>
              <Text style={styles.portalCodeTextInModal}>{portalCode}</Text>
              {portalCode ? (
                <ButtonPrimary
                  title={'Disconnect'}
                  onPress={() => {
                    purge();
                  }}
                  style={styles.modalDisconnectButton}
                />
              ) : (
                <View style={styles.inputWrap}>
                  {/* note: consider #consolodatePortalCodeForms */}
                  <Text style={styles.textInput}>{siteCopy.pleaseEnterCode}</Text>
                  <TextInput
                    style={[styles.textBox, styles.formInput]}
                    autoCorrect={false}
                    ref={portalCodeRef}
                    placeholder={'Your Code'}
                    placeholderTextColor={colors.grayLight}
                    autoCapitalize="none"
                    onChangeText={(value) => {
                      setPortalCodeInputText(value);
                    }}
                    onKeyPress={(e) => {
                      // desktop only - let enter send the form
                      const eventKey = e.key;

                      if (eventKey && eventKey === 'Enter') {
                        submitPortalCode(portalCodeInputText);
                      }
                    }}
                  />
                  <ButtonPrimary
                    title={'Submit'}
                    onPress={() => {
                      submitPortalCode(portalCodeInputText);
                    }}
                    style={[styles.modalDoneButton]}
                  />
                </View>
              )}
            </>
          ) : (
            <>
              <Text style={styles.h1}>Screencast to Portal</Text>
              <Text>{siteCopy.portalModalOnApp}</Text>
              <Text style={styles.portalCodeTextInModal}>{portalCode}</Text>
              {portalCode ? (
                <ButtonPrimary
                  title={'Refresh Code'}
                  onPress={() => {
                    submitPortalCode(null);
                  }}
                  style={styles.modalDisconnectButton}
                />
              ) : (null)}
            </>

          )}
          <ButtonPrimary
            title={'Done'}
            onPress={() => setModalVisible(!modalVisible)}
            style={styles.modalDoneButton}
          />
        </ModalView>
      </View>
    </View>
  );
};

const styles = {
  root: {},
  headerWrap: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: headerMenuScale / 2,
    ...mixins.bottomBorder,
    height: sizes.headerHeight,
  },
  backBtn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  backBtnIcon: {
    paddingRight: headerMenuScale / 2,
    color: colors.blue,
  },
  backBtnText: {
    color: colors.blue,
    fontSize: headerMenuScale * 1.125,
  },
  portalInfo: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  portalInfoText: {
    color: colors.grayLighter,
  },
  portalCodeTextInModal: {
    paddingVertical: sizes.base,
    fontSize: sizes.md,
  },
  portalCodeText: {
    fontSize: sizes.base,
    marginRight: sizes.xs,
  },
  inputWrap: {
    width: '100%',
  },
  modalDoneButton: {
    width: '100%',
    marginBottom: sizes.base,
  },
  modalDisconnectButton: {
    width: '100%',
    marginBottom: sizes.base,
    backgroundColor: colors.red,
    color: colors.white,
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
  },
  // todo #textBoxStylesConsolidate
  textBox: {
    padding: sizes.base / 2,
    borderWidth: 1,
    borderColor: colors.grayLighter,
  },
  textInput: {
    paddingVertical: sizes.base / 2,
  },
  formInput: {
    marginBottom: sizes.sm,
  },
  warningText: {
    fontStyle: 'italic',
    color: colors.grayLight,
  },
};

export default SubPageHeader;
