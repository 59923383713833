/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { DEFAULT_COORDS } from 'utils/constants';
import { GAME_TYPES, GAME_DIFFICULTY_TYPES, CALORIES_NAV_TYPES } from 'utils/constants';

const initialState = {
  isActive: false,
  // note: activeTime is used as a trigger to reset the activeTime on the portal.
  // it may not always be up to date; instead useSharedTimer is the main driver of this state.
  activeTime: 0,
  // note: right now we're only using the x or y coords for left/right or top/bottom.
  // so we could get rid of the other coords probably.
  // Unless we decide to get more clever... Right now there could be an issue with "gimbal lock"
  // if the phone is oriented wrong.
  // I think we could get the vector of each axis regardless of x/y/z
  calibration: {
    left: { ...DEFAULT_COORDS, x: -1, isDefault: true },
    right: { ...DEFAULT_COORDS, x: 1, isDefault: true },
    up: { ...DEFAULT_COORDS, y: 1, isDefault: true },
    down: { ...DEFAULT_COORDS, y: -1, isDefault: true },
  },
  whichCalibrationRequested: null,
  subNav: {
    caloriesNavType: CALORIES_NAV_TYPES.realtime,
    gameType: GAME_TYPES.pelvicClock,
    gameDifficulty: GAME_DIFFICULTY_TYPES.normal,
  },
};

// ------------------------------------
// Slice
// ------------------------------------
const activeSessionSlice = createSlice({
  name: 'activeSession',
  initialState,
  reducers: {
    saveIsActive: (state, { payload }) => {
      // default to a toggle
      let newVal = !state.isActive;

      if (typeof payload !== 'undefined') {
        newVal = payload;
      }

      state.isActive = newVal;
    },
    requestCalibration: (state, { payload }) => {
      // should be false, center, left, right, up, or down.
      // if blank, clear the calibration
      state.whichCalibrationRequested = payload;
      if (!payload) {
        state.calibration = initialState.calibration;
      }
    },
    saveCalibration: (state, { payload }) => {
      state.whichCalibrationRequested = null;
      const keys = Object.keys(initialState.calibration);

      keys.forEach((key) => {
        if (payload && payload[key]) {
          state.calibration[key] = payload[key];
        }
      });

      state.calibration = { ...state.calibration };
    },
    saveActiveTime: (state, { payload }) => {
      let newVal = state.activeTime;

      if (typeof payload !== undefined) {
        newVal = payload;
      }

      state.activeTime = newVal;
    },
    saveSubNav: (state, { payload }) => {
      state.whichCalibrationRequested = null;
      const keys = Object.keys(initialState.subNav);

      keys.forEach((key) => {
        if (payload && payload[key]) {
          state.subNav[key] = payload[key];
        }
      });

      state.subNav = { ...state.subNav };
    },
    reset: (state, { payload }) => {
      //just reset some elements, not the calibration
      state.isActive = initialState.isActive;
      state.activeTime = initialState.activeTime;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export const {
  saveIsActive,
  requestCalibration,
  saveCalibration,
  saveSubNav,
  reset,
  saveActiveTime,
} = activeSessionSlice.actions;

export default activeSessionSlice.reducer;
