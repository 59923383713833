import gameStages, { getDummyMovement } from 'utils/gameStages';
import { GAME_TYPES } from 'utils/constants';

const defaultSampleRate = 100;
const SpotGenerator = (options) => {
  const settings = options || {};
  const onChange = settings.onChange || (() => {});
  const sampleRate = options.sampleRate || defaultSampleRate;
  const gameType = options.gameType;

  const defaultData = {
    x: 0,
    y: 0,
    name: 'None',
  };

  let myInterval = null;
  let data = {};

  const getResetData = () => {
    return Object.assign({}, defaultData);
  };

  function getGameMovement(stagesList) {
    const fullData = [];

    for (let stage = 0; stage < stagesList.length; stage++) {
      const currentStage = stagesList[stage];
      const currentStageCoordPairs = currentStage.coordPairs;
      const currentStageName = currentStage.name;
      for (let i = 0; i < currentStageCoordPairs.length; i++) {
        const coordPair = currentStageCoordPairs[i];

        fullData.push({
          x: coordPair[0],
          // note #flipYCoords
          y: -coordPair[1],
          name: currentStageName,
        });
      }
    }

    return fullData;
  }

  const _subscribe = () => {
    let i = 0;
    let fullData = getDummyMovement();

    if (gameType === GAME_TYPES.pelvicClock) {
      fullData = getGameMovement(gameStages);
    }

    myInterval = setInterval(() => {
      const currData = fullData[i];
      // currData has x, y, and name
      data = currData;

      i++;
      // reset to the beginning when we get to the end
      if (i >= fullData.length) {
        i = 0;
      }
      // call the onChange function and pass a clone of the data.
      onChange(Object.assign({}, data));
    }, sampleRate);
  };

  const _unsubscribe = () => {
    return clearInterval(myInterval);
  };

  const self = {
    start: () => {
      _subscribe();
      return self;
    },
    stop: () => {
      data = getResetData();
      _unsubscribe();
      return self;
    },
    getData: () => data,
  };

  return self;
};

export default SpotGenerator;
