import React from 'react';
import { Text, View } from 'react-native';
import PageFrameCenter from 'components/PageFrameCenter';
import VideoWrap from 'components/VideoWrap';
import { sizes, colors, images } from 'theme';
import siteCopy from 'utils/siteCopy';

const Tutorial = ({ navigation }) => {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  return (
    <View style={styles.root}>
      <PageFrameCenter style={styles.pageView} shouldShowMasthead={true} >
        <Text style={styles.headerText}>{siteCopy.tutorialPageTitle}</Text>
        <VideoWrap source={images.tutorialVideo} ratio={9/16} />
      </PageFrameCenter>
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
  },
  pageView: {
    backgroundColor: colors.white,
  },
  headerText: {
    fontSize: sizes.fonts.h2,
    marginBottom: sizes.base,
  },
};

export default Tutorial;
