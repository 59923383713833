import React from 'react';
import { View, ScrollView } from 'react-native';
import { sizes, colors } from 'theme';
import AppHeader from 'components/AppHeader';
import SubPageHeader from 'components/SubPageHeader';
import SessionStatusBar from 'components/SessionStatusBar';
import AppFooter from 'components/AppFooter';
import { IS_PORTAL } from 'utils/constants';

const PageWrap = ({ navigation, options, children }) => {
  return (
    <View style={styles.root}>
      {
        options.isSubPage ? <SubPageHeader shouldShowPortalBtn={options.shouldShowPortalBtn}
        /> : <AppHeader />
      }
        <SessionStatusBar isCalibratePage={options.isCalibratePage} />
        {children}
      <AppFooter />
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
  }
};

export default PageWrap;
