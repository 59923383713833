/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { getTodayDate } from 'utils/calendarUtils';
// ------------------------------------
// Constants
// ------------------------------------

const sexChoices = {
  male: 'MALE',
  female: 'FEMALE',
  other: 'OTHER',
};

const userProfileDefault = {
  name: null,
  age: null,
  weight: null,
  heightFt: null,
  heightIn: null,
  sex: null,
  // just saved as a base64 string
  image: null,
  gameHighScore: 0,
  memberSince: getTodayDate(),
};

const initialState = {
  userProfile: userProfileDefault,
  portalCode: null,
  connectionStatus: null,
};

// ------------------------------------
// Slice
// ------------------------------------
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    saveUserProfile: (state, { payload }) => {
      const profile = payload;
      const validKeys = Object.keys(userProfileDefault);

      validKeys.forEach((key, idx) => {
        const currVal = state.userProfile[key];
        const newVal = profile[key];

        const isValid =
          typeof newVal !== 'undefined' &&
          (newVal === null || typeof newVal === 'number' || typeof newVal === 'string');

        if (isValid) {
          state.userProfile[key] = profile[key];
          state.userProfile = Object.assign({}, state.userProfile);
        }
      });
    },
    savePortalCode: (state, { payload }) => {
      const {
        portalCode
      } = payload;

      state.portalCode = portalCode || null;
    },
    saveConnectionStatus: (state, { payload }) => {
      const connectionStatus = payload;
      state.connectionStatus = connectionStatus || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export { sexChoices };
//Note: appSlice.actions will get created from createSlice's reducers
export const { saveUserProfile, savePortalCode, saveConnectionStatus } = appSlice.actions;

export default appSlice.reducer;
