import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import siteCopy from 'utils/siteCopy';
import PongApp from 'components/Games/Pong/PongApp';
import PongPortal from 'components/Games/Pong/PongPortal';
import DotInTheSpotApp from 'components/Games/DotInTheSpot/DotInTheSpotApp';
import DotInTheSpotPortal from 'components/Games/DotInTheSpot/DotInTheSpotPortal';

import SocketProvider from 'utils/SocketController/SocketProvider';
import { GAME_TYPES, SOCKET_ACTIONS, IS_PORTAL } from 'utils/constants';

const PongWhich = IS_PORTAL ? PongPortal : PongApp;
const DotInTheSpotWhich = IS_PORTAL ? DotInTheSpotPortal : DotInTheSpotApp;

const GameSession = ({ gameDifficulty, gameType, highScore, onGameOver, doEndGame, score, incScore, currentStage, setCurrentStage }) => {
  const {portalCode} = useSelector((state) => state.app);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const strs = {
    btnStop: `End`,
  };

  const wrappedEndGame = () => {
    onGameOver(score);
    doEndGame();
  };

  return (
    <View style={styles.root}>
      <View style={styles.gameStats}>
        {!!currentStage && gameType === GAME_TYPES.pelvicClock ? (
          <Text>{`${siteCopy.currentStage} ${currentStage}`}</Text>
        ) : null}
        <Text>{`${siteCopy.highScore}: ${highScore}`}</Text>
        <Text>{`${siteCopy.score}: ${score}`}</Text>
      </View>
      <ButtonPrimary title={strs.btnStop} style={styles.btnStop} onPress={wrappedEndGame} />
      {gameType === GAME_TYPES.pong ? (
        <PongWhich
          gameDifficulty={gameDifficulty}
          gameType={gameType}
          doEndGame={wrappedEndGame}
          score={score}
          incScore={incScore}
        />
      ) : (
        <DotInTheSpotWhich
          gameDifficulty={gameDifficulty}
          gameType={gameType}
          doEndGame={wrappedEndGame}
          score={score}
          incScore={incScore}
          setCurrentStage={setCurrentStage}
        />
      )}
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
  },
  gameStats: {
    marginBottom: sizes.base,
  },
  btnStop: {
    backgroundColor: colors.red,
    position: 'absolute',
    right: 0,
    top: 0,
    color: colors.white,
  },
};

export default GameSession;
