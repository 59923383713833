import {CONTENT_BOX_WIDTH} from 'utils/constants';

const base = 16;
const xs = 8;
const sm = 14;
const md = 20;
const lg = 26;
const xl = 32;

const buttonSize = 40;
const buttonPadding = base;

const sizes = {
  shadow: {
    radius: base,
    opacity: .35,
  },
  borderRadius: base,
  borderRadiusBtn: 5,
  base: base,
  xs: xs,
  sm: sm,
  md: md,
  lg: lg,
  xl: xl,
  frac2: base / 2,
  frac4: base / 4,
  frac8: base / 8,
  headerHeight: base * 4.5,
  fonts: {
    h1: lg,
    h2: md,
    base: base,
    footerMenu: 10,
  },
  fontWeightBold: 'bold',
  contentBoxWidth: CONTENT_BOX_WIDTH,
  buttonSize: buttonSize,
  buttonPaddingV: buttonPadding / 2,
  buttonPaddingH: buttonPadding,
  buttonMargin: 6,
  iconSizeSm: sm,
};

export default sizes;
