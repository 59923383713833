import React, { useContext, useState, useEffect, useRef } from 'react';
import { Text, View, TextInput, Image } from 'react-native';
import Button from 'components/Button';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import PageFrameCenter from 'components/PageFrameCenter';
import { sizes, colors, mixins } from 'theme';
import * as ImagePicker from 'expo-image-picker';
import { useSelector, useDispatch } from 'react-redux';
import { saveUserProfile, sexChoices } from 'slices/app.slice';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { IS_PORTAL, SOCKET_ACTIONS } from 'utils/constants';
import siteCopy from 'utils/siteCopy';

const strs = {
  nameFalse: 'Please enter your full name:',
  age: 'How old are you?',
  height: 'Next, please enter your height.',
  weight: 'Enter your weight in pounds.',
  sex: 'Select your sex.',
  btnSaved: 'Saved',
  btnNotSaved: 'Save',
  hyperLink: 'Why do we need this info?',
  hyperLinkText: `To accurately approximate each user's caloric burn, we have derived a series of convoluted equations that translate motion while seated into caloric expenditure. Every individual burns calories at a different rate, but by knowing a few key features such as age, height, and weight, our algorithm can more accurately predict your energy expenditure! For more info, please visit QOR360.com.`,
};

const Profile = ({ navigation }) => {
  const dispatch = useDispatch();
  const { portalCode, userProfile } = useSelector((state) => state.app);
  const { totalCals } = useSelector((state) => state.chairSessions);
  const memberSince = userProfile.memberSince || '';
  const [name, setName] = useState(userProfile.name || '');
  const [age, setAge] = useState(userProfile.age || '');
  const [weight, setWeight] = useState(userProfile.weight || '');
  const [heightFt, setHeightFt] = useState(userProfile.heightFt || '');
  const [heightIn, setHeightIn] = useState(userProfile.heightIn || '');
  const [sex, setSex] = useState(userProfile.sex || '');
  const [image, setImage] = useState(userProfile.image || '');
  // attrs below here are not for profile
  const [showText, setShowText] = useState(false);
  const [infoSaved, setInfoSaved] = useState(true);
  const [doSubmit, setDoSubmit] = useState(false);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const saveProfilePortal = (data) => {
    socket.emit(SOCKET_ACTIONS.dispatchProxy, {
      shortCode: portalCode,
      // todo: consider adding these strings to constants?
      slice: 'app',
      reducer: 'saveUserProfile',
      value: data,
    });
  };

  const saveProfile = (data) => {
    dispatch(saveUserProfile(data));
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const deleteImage = () => {
    setImage(null);
  };

  const placeholderValues = {
    name: 'First Last',
    age: 'Age',
    weight: 'Weight, lbs',
    heightFt: 'Height, feet',
    heightIn: 'Height, inches',
  };

  //references to smooth out user flow.
  const nameRef = useRef();
  const ageRef = useRef();
  const feetRef = useRef();
  const inchesRef = useRef();
  const weightRef = useRef();
  const nextFocusRef = useRef();
  const saveProfileWhich = IS_PORTAL ? saveProfilePortal : saveProfile;

  useEffect(() => {
    // for now, just save on any change to any of these.
    // This could be fine to keep as is for now since we're mocking the database and writes are cheap.
    if (doSubmit) {
      saveProfileWhich({
        name,
        age,
        weight,
        heightFt,
        heightIn,
        sex,
        image,
      });

      setDoSubmit(false);
      setInfoSaved(true);
    }
  }, [doSubmit, name, age, weight, heightFt, heightIn, sex, image]);

  // make it clear not to truth test with just image as it causes a warning / error.
  const hasImage = !!image;
  const profilePageIsDisabled = IS_PORTAL && !portalCode;

  return profilePageIsDisabled ? (
    <View style={styles.root}>
      <PageFrameCenter>
        <View style={styles.mainContent}>
          <Text style={styles.notConnectedMsg}>
            {siteCopy.messagePortalNotConnected}
          </Text>
        </View>
      </PageFrameCenter>
    </View>
  ) : (
    <View style={styles.root}>
      <PageFrameCenter>
        <View style={styles.mainContent}>
          <View style={styles.row}>
            {hasImage && <Image source={{ uri: image }} style={styles.profilePic} />}
            <Text style={styles.boldText}>{name ? `${name}` : `Guest user`}</Text>
          </View>
          {hasImage && (
            <ButtonPrimary style={styles.btnEdit} title="Remove Photo" onPress={deleteImage} />
          )}
          {!hasImage && (
            <ButtonPrimary style={styles.btnEdit} title="Add Photo" onPress={pickImage} />
          )}
          <Text style={styles.surveyText}>
            {name ? `Hi ${name}! Welcome to ${siteCopy.brandName}.` : strs.nameFalse}
          </Text>
          <TextInput
            style={styles.textBox}
            placeholderTextColor={colors.grayLight}
            autoCorrect={false}
            returnKeyType="next"
            ref={nameRef}
            placeholder={placeholderValues.name}
            value={name}
            onChangeText={(name) => {
              setName(name);
              setInfoSaved(false);
            }}
            onBlur={() => {
              // setDoSubmit(true);
            }}
            onSubmitEditing={() => {
              ageRef.current.focus();
            }}
          />
          <Text style={styles.surveyText}>{strs.age}</Text>
          <TextInput
            style={styles.textBox}
            placeholderTextColor={colors.grayLight}
            keyboardType="numbers-and-punctuation"
            returnKeyType="next"
            ref={ageRef}
            placeholder={placeholderValues.age}
            value={age}
            onChangeText={(age) => {
              setAge(age);
              setInfoSaved(false);
            }}
            onBlur={() => {
              // setDoSubmit(true);
            }}
            onSubmitEditing={() => {
              feetRef.current.focus();
            }}
          />
          <View style={styles.row}>
            <View style={[styles.col, styles.colFirst]}>
              <Text style={styles.surveyText}>{strs.height}</Text>
              <View style={styles.rowSub}>
                <TextInput
                  style={styles.textBox}
                  placeholderTextColor={colors.grayLight}
                  keyboardType="numbers-and-punctuation"
                  returnKeyType="next"
                  ref={feetRef}
                  placeholder={placeholderValues.heightFt}
                  value={heightFt}
                  onChangeText={(heightFt) => {
                    setHeightFt(heightFt);
                    setInfoSaved(false);
                  }}
                  onBlur={() => {
                    // setDoSubmit(true);
                  }}
                  onSubmitEditing={() => {
                    inchesRef.current.focus();
                  }}
                />
                <Text style={styles.labelText}>{`ft`}</Text>
              </View>
              <View style={styles.rowSub}>
                <TextInput
                  style={styles.textBox}
                  placeholderTextColor={colors.grayLight}
                  keyboardType="numbers-and-punctuation"
                  returnKeyType="next"
                  ref={inchesRef}
                  placeholder={placeholderValues.heightIn}
                  value={heightIn}
                  onChangeText={(heightIn) => {
                    setHeightIn(heightIn);
                    setInfoSaved(false);
                  }}
                  onBlur={() => {
                    // setDoSubmit(true);
                  }}
                  onSubmitEditing={() => {
                    weightRef.current.focus();
                  }}
                />
                <Text style={styles.labelText}>{`in`}</Text>
              </View>
            </View>
            <View style={styles.col}>
              <Text style={styles.surveyText}>{strs.weight}</Text>
              <View style={styles.rowSub}>
                <TextInput
                  style={styles.textBox}
                  placeholderTextColor={colors.grayLight}
                  keyboardType="numbers-and-punctuation"
                  returnKeyType="next"
                  ref={weightRef}
                  placeholder={placeholderValues.weight}
                  value={weight}
                  onChangeText={(weight) => {
                    setWeight(weight);
                    setInfoSaved(false);
                  }}
                  onBlur={() => {
                    // setDoSubmit(true);
                  }}
                />
                <Text style={styles.labelText}>{`lbs`}</Text>
              </View>
            </View>
          </View>
          <Text style={styles.surveyText}>{strs.sex}</Text>
          <View style={styles.row}>
            {Object.keys(sexChoices).map((key) => (
              <Button
                key={key}
                style={sex === sexChoices[key] ? styles.btnSexTrue : styles.btnSexFalse}
                title={key}
                onPress={() => {
                  setSex(sexChoices[key]);
                  setInfoSaved(false);
                }}
                onBlur={() => {
                  // setDoSubmit(true);
                }}
              />
            ))}
          </View>

          <Button
            style={infoSaved ? styles.btnSaved : styles.btnNotSaved}
            title={infoSaved ? strs.btnSaved : strs.btnNotSaved}
            onPress={() => setDoSubmit(true)}
          />

          <Text style={styles.hyperLink} onPress={() => setShowText(!showText)}>
            {'Why do we need this info?'}
          </Text>
          <Text>{showText ? strs.hyperLinkText : ''}</Text>
        </View>
      </PageFrameCenter>
    </View>
  );
};

const profilePicSize = 90;

const styles = {
  root: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    marginVertical: sizes.base,
    justifyContent: 'space-between',
    // Using this for now.
    // To do this right, we should introduce a grid style lib with breakpoints like
    // https://mui.com/material-ui/react-grid/
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  col: {
    marginVertical: sizes.base,
  },
  colFirst: {
    paddingRight: sizes.base,
  },
  rowSub: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainContent: {
    backgroundColor: colors.white,
    paddingHorizontal: sizes.base,
  },
  notConnectedMsg: {
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: 250,
    padding: sizes.base,
  },
  profilePic: {
    width: profilePicSize,
    height: profilePicSize,
    borderRadius: profilePicSize / 2,
  },
  btnNotSaved: {
    backgroundColor: colors.blueSecondary,
    color: colors.white,
    marginVertical: sizes.base,
  },
  btnSaved: {
    marginVertical: sizes.base,
  },
  btnSexTrue: {
    backgroundColor: colors.blueSavedState,
    color: colors.white,
  },
  textBox: {
    padding: sizes.base / 2,
    borderBottomWidth: 1,
    minWidth: sizes.xl * 2,
  },
  surveyText: {
    marginVertical: sizes.base,
  },
  labelText: {
    marginLeft: sizes.base / 2,
    alignSelf: 'flex-end',
  },
  h1: {
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
  },
  hyperLink: {
    alignSelf: 'center',
    color: colors.blue,
    padding: sizes.base / 2,
  },
  boldText: {
    fontWeight: sizes.fontWeightBold,
  },
};

export default Profile;
