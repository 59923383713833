import React from 'react';
import { Text, View } from 'react-native';
import ContentBox from 'components/ContentBox';
import Button from 'components/Button';
import PageFrameCenter from 'components/PageFrameCenter';
import ShadowBox from 'components/ShadowBox';
import ImageWrap from 'components/ImageWrap';
import { sizes, colors, images } from 'theme';
import siteCopy from 'utils/siteCopy';

const About = ({ navigation }) => {
  return (
    <View style={styles.root}>
      <PageFrameCenter style={styles.pageView} shouldShowMasthead={true} >
        <Text style={styles.paragraph}>{siteCopy.companyBio}</Text>
        <View style={styles.pullquoteWrap}>
          <ShadowBox style={styles.pullquote}>
            <Text style={[styles.paragraph, styles.pullquoteText]}>{siteCopy.companyQuote}</Text>
            <Text style={[styles.paragraph, styles.pullquoteText, styles.pullquoteAttr]}>
              {siteCopy.companyQuoteAttr}
            </Text>
          </ShadowBox>
        </View>
        <Text style={styles.headerText}>{siteCopy.aboutHowItStartedTitle}</Text>
        <ImageWrap style={styles.imageWrap} source={images.turnerAndLex} ratio={1 / 1.5} />
        <Text style={styles.paragraph}>{siteCopy.aboutIntroPart1}</Text>
        <Text style={styles.paragraph}>{siteCopy.aboutIntroPart2}</Text>
        <Text style={styles.paragraph}>{siteCopy.aboutIntroPart3}</Text>
        <Text style={styles.paragraphBold}>{siteCopy.aboutIntroPart4}</Text>
      </PageFrameCenter>
    </View>
  );
};

const pullQuoteMaxWidth = 275;

const styles = {
  root: {
    flex: 1,
  },
  pageView: {
    backgroundColor: colors.white,
  },
  headerText: {
    fontSize: sizes.fonts.h2,
    marginBottom: sizes.base,
  },
  pullquoteWrap: {
    alignItems: 'center',
    marginBottom: sizes.lg,
  },
  pullquote: {
    borderRadius: sizes.borderRadius,
    backgroundColor: colors.redSecondary,
    padding: sizes.base,
    paddingBottom: 0,
    maxWidth: pullQuoteMaxWidth,
  },
  pullquoteText: {
    textAlign: 'center',
    color: colors.white,
  },
  pullquoteAttr: {
    textAlign: 'right',
  },
  paragraph: {
    marginBottom: sizes.base,
  },
  paragraphBold: {
    marginBottom: sizes.base,
    fontWeight: sizes.fontWeightBold,
  },
  pageButton: {
    marginBottom: sizes.base,
  },
  imageWrap: {
    marginBottom: sizes.base,
  },
};

export default About;
