import React from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { sizes, colors } from 'theme';
import PageFrameCenter from 'components/PageFrameCenter';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import siteCopy from 'utils/siteCopy';
import csvDownload from 'json-to-csv-export';
import { flatMap } from 'lodash';
import { IS_PORTAL } from 'utils/constants';
import moment from 'moment';
import { getTimestamp } from 'utils/calendarUtils';

const parseAndDownload = (dataSet) => {
  const reformated = flatMap(dataSet, (data) => {
    const subSessions = data.subSessions || [];

    const subSessionData = subSessions.map((sub) => {
      return {
        // note: Due to some confusion about how excel parses numbers,
        // the client requested this be a string even though it shouldn't matter.
        // this will just add quotes around the digits in the csv text file.
        timestamp: `${sub.time}`,
        time: moment(sub.time).format('YYYY/MM/DD HH:mm:ss'),
        calories: sub.cals,
      };
    });

    // return one line for the session data, and then multiple lines for the subSessionData
    return subSessionData;
  });

  const defaults = {
    data: reformated,
    filename: `FitterSitter-Calorie-Sessions-${getTimestamp()}`,
    delimiter: ',',
    headers: ['timestamp', 'time', 'calories'],
  };

  try {
    csvDownload(defaults);
  } catch (err) {
    console.log(err);
  }
};

const Datadump = ({ navigation }) => {
  const fullDataState = useSelector((state) => state);
  const { portalCode } = useSelector((state) => state.app);
  const datadump = JSON.stringify(fullDataState, null, 4);
  const sessionsData = fullDataState.chairSessions.sessions;
  const isDisabled = IS_PORTAL && !portalCode;

  return (
    <View style={styles.root}>
      <PageFrameCenter style={styles.pageView}>
        {isDisabled ? (
          <Text style={styles.notConnectedMsg}>{siteCopy.messagePortalNotConnected}</Text>
        ) : (
          <>
            {IS_PORTAL ? (
              <ButtonPrimary
                style={styles.downloadBtn}
                title={siteCopy.downloadCSV}
                onPress={() => {
                  parseAndDownload(sessionsData);
                }}
              />
            ) : (
              <Text style={styles.info}>(To download a CSV, please use the portal.)</Text>
            )}
            <Text style={styles.h2}>Raw Data</Text>
            <Text style={styles.rawtext}>{datadump}</Text>
          </>
        )}
      </PageFrameCenter>
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
  },
  downloadBtn: {
    marginTop: sizes.buttonSize,
    marginBottom: sizes.buttonSize,
  },
  disabledBtn: {
    marginTop: sizes.buttonSize,
    marginBottom: sizes.buttonSize,
    backgroundColor: colors.gray,
  },
  pageView: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notConnectedMsg: {
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: 250,
    padding: sizes.base,
  },
  h2: {
    fontSize: sizes.fonts.h2,
    fontWeight: sizes.fontWeightBold,
    marginBottom: sizes.base,
  },
  info: {
    fontSize: sizes.fonts.base,
    marginBottom: sizes.base,
  },
  rawtext: {
    fontSize: sizes.fonts.base,
    marginBottom: sizes.base,
    maxWidth: '100%',
  },
};

export default Datadump;
