import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';
import PongBall from 'components/PongBall';
import PongPaddle from 'components/PongPaddle';
import coordParser from 'utils/coordParser';
import { GAME_DIFFICULTY_TYPES, ANIM_DURATION, GAME_SPOT_MOVE_DURATION } from 'utils/constants';

const gameSpeedAdjustedInterval = GAME_SPOT_MOVE_DURATION * 1.5;

const Pong = ({
  gameDifficulty,
  pongBallCoords,
  pongPaddleCoords,
  pongBallSetter,
  pongPaddleSetter,
}) => {
  const trackerWidth = coordParser.getTrackerWidth();
  const spotAnimSpeed =
    gameDifficulty === GAME_DIFFICULTY_TYPES.hard
      ? gameSpeedAdjustedInterval * 0.7
      : gameSpeedAdjustedInterval;

  const liveStyles = {
    trackerWrap: {
      width: trackerWidth,
      height: trackerWidth,
    },
  };

  return (
    <View style={[styles.trackerWrap, liveStyles.trackerWrap]}>
      <View style={styles.pongWrap}>
        <View style={styles.pongBallWrap}>
          <PongBall
            data={pongBallCoords}
            animSpeed={spotAnimSpeed}
            setRealCoords={pongBallSetter}
          />
        </View>
        <View style={styles.pongPaddleWrap}>
          <PongPaddle
            data={pongPaddleCoords}
            animSpeed={ANIM_DURATION}
            setRealCoords={pongPaddleSetter}
          />
        </View>
      </View>
    </View>
  );
};

const styles = {
  trackerWrap: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  pongWrap: {
    flex: 1,
    width: '100%',
    height: '100%',
    borderWidth: 2,
    // red, but with some transparency
    borderBottomColor: '#e82c2e66',
    borderColor: colors.green,
    position: 'absolute',
  },
  pongBallWrap: {
    left: '50%',
    top: '50%',
    position: 'absolute',
  },
  pongPaddleWrap: {
    flex: 1,
    position: 'absolute',
    left: '50%',
    bottom: 0,
  },
};

export default Pong;
