import moment from 'moment';

const DEFAULT_LOCALE = 'en-us';

const getDayProps = (date) => {
  const dateObj = date || new Date();
  const day = dateObj.getDate();
  const hour = dateObj.getHours();
  const hour00 = padTo2Digits(hour);
  const day00 = padTo2Digits(day);
  const month00 = padTo2Digits(dateObj.getMonth() + 1);
  const year = dateObj.getFullYear();

  return {
    day,
    hour00,
    day00,
    month00,
    year,
    timestamp: dateObj.valueOf(),
    time: dateObj.toLocaleString(DEFAULT_LOCALE, {
      hour: 'numeric',
      minute: 'numeric',
    }), // 8:20 PM
    hourKey: [year, month00, day00, hour00].join('-'),
    dayKey: [year, month00, day00].join('-'),
    monthKey: [year, month00].join('-'),
    // Note #DateFormatFix. This formatting would fail on android without the "import 'intl'" line at the top of the app.
    month: moment(dateObj).locale(DEFAULT_LOCALE).format('MMMM'), // January
    dayOfWeek: dateObj.getDay(),
    monthShort: moment(dateObj).locale(DEFAULT_LOCALE).format('MMM'), // Jan
    weekday: moment(dateObj).locale(DEFAULT_LOCALE).format('dddd'), // Tuesday
    weekdayShort: moment(dateObj).locale(DEFAULT_LOCALE).format('ddd'), // Tue
  };
};

const getTimestamp = (date) => {
  const dateObj = date || new Date();

  return dateObj.valueOf();
};

const getTimeDiff = (startDate, endDate, type) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);

  return toDate.diff(fromDate, type);
};

const getTodayDate = () => {
  return moment().format('l');
};

const getPastTimes = (times, type, startDate) => {
  // an empty date will default to now
  const start = startDate || new Date();
  const fromDate = moment(start);

  const range = [];

  for (let i = 0; i < times; i++) {
    range.push(moment(startDate).subtract(i, type).toDate());
  }

  return range;
};

const yesterdays = (ttlDays, date) => {
  // note: 'days' is the string for moment. Consider alligning with TIME_TYPES.day.
  return getPastTimes(ttlDays, 'days', date);
};

const yesterhours = (ttlHours, date) => {
  // note: 'hours' is the string for moment. Consider alligning with TIME_TYPES.hour.
  return getPastTimes(ttlHours, 'hours', date);
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

const getFormatedTime = (milliseconds) => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  // let hours roll over past 24

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export {
  getDayProps,
  getTimeDiff,
  getPastTimes,
  getTodayDate,
  yesterdays,
  yesterhours,
  getFormatedTime,
  getTimestamp,
};
