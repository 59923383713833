import React, { useState, useEffect, useContext, useRef } from 'react';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';
import GameSession from 'components/GameSession/GameSession';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { GAME_TYPES, SOCKET_ACTIONS } from 'utils/constants';

const GameSessionPortal = ({ gameDifficulty, gameType, onGameOver, doEndGame }) => {
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const [currentStage, setCurrentStage] = useState('A');
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(0);

  const gameUpdatedHook = (data) => {
    const { stageName, score, highScore } = data;

    if (typeof stageName !== 'undefined') {
      setCurrentStage(stageName);
    }

    if (typeof score !== 'undefined') {
      setScore(score);
    }

    if (typeof highScore !== 'undefined') {
      setHighScore(highScore);
    }
  };

  useEffect(() => {
    socket.on(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);

    return () => {
      socket.off(SOCKET_ACTIONS.gameUpdated, gameUpdatedHook);
    };
  }, [socket]);

  return (
    <GameSession
      gameType={gameType}
      gameDifficulty={gameDifficulty}
      highScore={highScore}
      onGameOver={onGameOver}
      doEndGame={doEndGame}
      score={score}
      currentStage={currentStage}
      setCurrentStage={setCurrentStage}
    />
  );
};

export default GameSessionPortal;
