import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TimerManager from 'utils/TimerController/TimerManager';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import { parseChairSession, parseSubSession } from 'utils/dataUtils';
import { getDayProps } from 'utils/calendarUtils';
import { saveChairSession, savePendingSubsessions } from 'slices/chairSessions.slice';
import { reset } from 'slices/activeSession.slice';
import { IS_PORTAL, SOCKET_ACTIONS, SUB_SESSION_SECONDS } from 'utils/constants';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { SharedTimerProvider, useSharedTimer } from 'utils/TimerController/TimerProvider';

let singletonTimerManager;

const TimerController = ({ children }) => {
  const dispatch = useDispatch();
  const [timeData, setTimeData] = useSharedTimer();
  const { portalCode, userProfile } = useSelector((state) => state.app);
  const { motionController, dotData } = useSharedMotionState();
  const { isActive, activeTime } = useSelector((state) => state.activeSession);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const subSessionsRef = useRef([]);

  const endSession = (portalCode) => {
    singletonTimerManager.stop();
    motionController && motionController.reset();

    if (IS_PORTAL) {
      // get the session that was just saved by the app
      socket.emit(SOCKET_ACTIONS.requestDataState, {
        shortCode: portalCode,
        // note: #requestDataStateLimited
        which: 'chairSessions',
      });
    } else {
      //only save the active session if it's been at least 1 second.
      if (!!timeData.time) {
        saveActiveSession();
      }
    }

    dispatch(reset());
  };

  const startSession = (startTime=0) => {
    singletonTimerManager.start(startTime);
  };

  const saveActiveSession = () => {
    const secondsActive = timeData.time;
    let subSessions = subSessionsRef.current;

    // if there is no subSession yet, generate one on the fly and wrap it in a single-item array.
    if (!subSessions.length > 0) {
      subSessions = [getSubSession(secondsActive)];
    }

    const data = parseChairSession(subSessions);
    dispatch(saveChairSession(data));

    // reset the subsessions
    subSessionsRef.current = [];
    saveActiveSubSessions([]);
  };

  const saveActiveSubSessions = (subSessions) => {
    dispatch(savePendingSubsessions(subSessions));
  };

  const getSubSession = (secondsActive) => {
    const subSessions = subSessionsRef.current;
    const lastSub = subSessions[subSessions.length-1] || {};
    const lastCals = lastSub.calsTtl || 0;

    const stats = {
      distanceTotal: dotData.distanceTotal,
      secondsActive: secondsActive,
      lastCals: lastCals,
    };

    return parseSubSession(stats, userProfile);
  };

  useEffect(() => {
    // for now, make sure the session is stopped after a page reload. (on new page load)
    // not sure if we can reliably save it before an app close / crash instead.
    dispatch(reset());

    singletonTimerManager = new TimerManager({
      onChange: setTimeData,
    });

    setTimeData(singletonTimerManager.getData());
  }, []);

  useEffect(() => {
    // note: This is the newly updated value. So set the state to match.
    if (isActive) {
      startSession(activeTime);
    } else {
      // note - this fires on page load, which isn't really correct, but it doesn't matter.
      endSession(portalCode);
    }
  }, [portalCode, isActive, activeTime]);

  useEffect(() => {
    const hasHitInterval = !!timeData.time && timeData.time % SUB_SESSION_SECONDS === 0;
    let subSessions = subSessionsRef.current;
    // the portal will request all this info, no need to track it.
    if (!IS_PORTAL) {
      if (hasHitInterval) {
        subSessions = subSessions.concat(getSubSession(timeData.time));
        subSessionsRef.current = subSessions;
        saveActiveSubSessions(subSessions);
      }
    }
  }, [timeData]);

  return children;
};

export default ({ children }) => (
  <SharedTimerProvider>
    <TimerController children={children} />
  </SharedTimerProvider>
);
