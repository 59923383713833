import React, { useState, useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import Pong from 'components/Games/Pong/Pong';
import coordParser from 'utils/coordParser';
import PongBallGenerator from 'utils/PongBallGenerator';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { useSharedMotionState } from 'utils/MotionController/MotionProvider';
import {
  GAME_DIFFICULTY_TYPES,
  SOCKET_ACTIONS,
  GAME_SPOT_MOVE_DURATION,
  PONG_BALL_WIDTH,
  DEFAULT_COORDS,
  PONG_PADDLE_WIDTH,
  PONG_PADDLE_HEIGHT,
  PONG_PADDLE_OFFSET,
} from 'utils/constants';

// There is a bit of delay before the animation feedback picks up the boundary.
// So pretend like the ball has a little shell around it.
const pongBallBuffer = 5;
// this game is a little harder. Slow it down a touch. (larger interval is slower)
const gameSpeedAdjustedInterval = GAME_SPOT_MOVE_DURATION * 1.5;

const getCoordsFromAngle = (angle, amplitude) => {
  return {
    x: Math.cos(angle) * amplitude,
    y: Math.sin(angle) * amplitude,
  };
};

const getRandomOffset = () => {
  // random between 0 and 0.25.
  const rando = Math.random() * 0.25;
  // how much mult should it add to x?
  const randMult = 10;
  const randoSide = Math.random() > 0.5;
  const offset = rando * (randoSide ? 1 : -1) * randMult;

  return offset;
};

const getRandomAngle = () => {
  // random between 0.25 and 0.5. Avoid 0 as it's too vertical.
  const adjustRadianTarget = 0.075;
  const vertAvoid = adjustRadianTarget / 2;
  const rando = Math.random() * adjustRadianTarget + vertAvoid;
  const randoSide = Math.random() > 0.5;
  const angleAdjustment = rando * (randoSide ? 1 : -1);
  // random angle pointing down, to the side, close to paddle.
  const startAngle = (0.5 + angleAdjustment) * Math.PI;

  return startAngle;
};

const PongApp = ({ gameDifficulty, gameType, doEndGame, score, incScore }) => {
  const {portalCode} = useSelector((state) => state.app);
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();
  const trackerWidth = coordParser.getTrackerWidth();
  const spotAnimSpeed =
    gameDifficulty === GAME_DIFFICULTY_TYPES.hard
      ? gameSpeedAdjustedInterval * 0.5
      : gameSpeedAdjustedInterval;
  const [pongBallTarget, setPongBallTarget] = useState(DEFAULT_COORDS);
  const [dotRealCoords, setDotRealCoords] = useState(DEFAULT_COORDS);
  const [pongBallRealCoords, setPongBallRealCoords] = useState(DEFAULT_COORDS);
  const pongScoreInc = useRef(1);

  const { calibratedCoords } = useSharedMotionState();

  const gameRadius = trackerWidth / 2 - PONG_BALL_WIDTH / 2 - pongBallBuffer;
  const paddleFloor = -gameRadius + PONG_PADDLE_HEIGHT + PONG_PADDLE_OFFSET;

  const checkPong = (pongBallTarget, pongBallRealCoords, pongPaddleCoords) => {
    // Y is backwards. Flip it for clarity.
    const realY = -pongBallRealCoords.y;
    const realX = pongBallRealCoords.x;
    const paddleXOffset = pongPaddleCoords.x;
    const hasHitXPos = realX >= gameRadius;
    const hasHitYPos = realY >= gameRadius;
    const hasHitXNeg = realX <= -gameRadius;
    // extra condition at the end ensures the paddle can't cover the ball and then bounce it up before it hits the floor
    const hasHitYNegPaddle = realY <= paddleFloor && realY > paddleFloor - PONG_PADDLE_HEIGHT;
    const hasHitYNegFloor = realY <= -gameRadius;
    const isInPaddleXRange =
      realX > paddleXOffset - PONG_PADDLE_WIDTH / 2 &&
      realX < paddleXOffset + PONG_PADDLE_WIDTH / 2;

    if (hasHitYPos && pongBallTarget.y > 0) {
      // hit ceiling
      setPongBallTarget({
        x: pongBallTarget.x,
        y: -pongBallTarget.y,
      });
    } else if (hasHitXPos && pongBallTarget.x > 0) {
      // hit left wall
      setPongBallTarget({
        x: -pongBallTarget.x,
        y: pongBallTarget.y,
      });
    } else if (hasHitXNeg && pongBallTarget.x < 0) {
      // hit right wall
      setPongBallTarget({
        x: -pongBallTarget.x,
        y: pongBallTarget.y,
      });
      // } else if (hasHitYNegPaddle && isInPaddleXRange) {
    } else if (hasHitYNegPaddle && isInPaddleXRange && pongBallTarget.y <= 0) {
      // hit paddle

      // add some score. And also, increase the score amount
      // so you get more points the longer you keep the ball alive
      incScore(pongScoreInc.current);
      pongScoreInc.current = pongScoreInc.current + 1;

      const newX = pongBallTarget.x + getRandomOffset();

      setPongBallTarget({
        x: newX,
        y: -pongBallTarget.y,
      });
    } else if (hasHitYNegFloor && pongBallTarget.y < 0) {
      // hit floor
      doEndGame();
    }
  };

  useEffect(() => {
    const pongBallGenerator = new PongBallGenerator({
      sampleRate: spotAnimSpeed,
      gameType: gameType,
    }).start();

    return () => {
      pongBallGenerator.stop();
    };
  }, []);

  useEffect(() => {
    // redefine for clarity. The dot coords are the paddlecoords (just the x axis)
    const pongPaddleCoords = dotRealCoords;

    checkPong(pongBallTarget, pongBallRealCoords, pongPaddleCoords);
  }, [pongBallTarget, pongBallRealCoords, dotRealCoords]);

  useEffect(() => {
    const startAngle = getRandomAngle();
    setPongBallTarget(getCoordsFromAngle(startAngle, -4));
  }, []);

  useEffect(() => {
    socket.emit(SOCKET_ACTIONS.gameUpdated, {
      dotCoords: calibratedCoords,
      spotCoords: pongBallTarget,
      shortCode: portalCode,
    });
  }, [socket, calibratedCoords, pongBallTarget]);

  return (
    <Pong
      gameDifficulty={gameDifficulty}
      pongBallCoords={pongBallTarget}
      pongPaddleCoords={calibratedCoords}
      pongBallSetter={setPongBallRealCoords}
      pongPaddleSetter={setDotRealCoords}
    />
  );
};

export default PongApp;
