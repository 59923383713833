import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View } from 'react-native';
import siteCopy from 'utils/siteCopy';
import ContentBox from 'components/ContentBox';
import BarChart from 'components/BarChart';
import MiniDashboard from 'components/MiniDashboard';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import BarChartNoDataWrap from 'components/BarChartNoDataWrap';
import { sizes } from 'theme';
import { CHART_TYPES, CHART_DATA_TYPES } from 'utils/constants';
import NavProvider from 'navigator/NavProvider';

const defaultSittingTime = 14;

const strs = {
  box1: {
    title: `Calories burned this week`,
  },
  box2: {
    title: `Sitting Time for Past ${defaultSittingTime} Days`,
  },
  greetingWithNoName: `Good Afternoon`,
};

const Home = () => {
  const Navigator = useContext(NavProvider);

  const {userProfile} = useSelector((state) => state.app);
  const {totalCals, indexPerDay} = useSelector((state) => state.chairSessions);
  const userName = userProfile.name || '';
  const userHasData = !!totalCals;

  const ContentBoxWithCTAs = (
    <ContentBox title={siteCopy.trackOrPlay} useBackground={false}>
      <ButtonPrimary
        title={'Calibrate Device'}
        onPress={() => {
          Navigator.navigate('Calibrate');
        }}
        style={styles.btn}
      />
      <ButtonPrimary
        title={'Play Game'}
        onPress={() => {
          Navigator.navigate('Game');
        }}
        style={styles.btn}
      />
    </ContentBox>
  );

  return (
    <View style={styles.root}>
      <Text style={styles.h1}>
        {userName ? `${strs.greetingWithNoName}, ${userName}` : strs.greetingWithNoName}
      </Text>
      {userHasData && (
        <>
          <ContentBox>
            <MiniDashboard
              totalCals={totalCals}
              indexPerDay={indexPerDay}
            />
          </ContentBox>
          {ContentBoxWithCTAs}
          <ContentBox title={strs.box1.title}>
            <BarChart />
          </ContentBox>
          <ContentBox title={strs.box2.title}>
            <BarChart chartType={CHART_TYPES.twoweek} chartDataType={CHART_DATA_TYPES.minutesActive}  />
          </ContentBox>
        </>
      )}
      {!userHasData && (
        <>
          {ContentBoxWithCTAs}
          <ContentBox>
            <BarChartNoDataWrap />
          </ContentBox>
        </>
      )}
    </View>
  );
};

const styles = {
  root: {
    flex: 1,
    alignItems: 'center',
  },
  h1: {
    alignSelf: 'flex-start',
    fontSize: sizes.fonts.h1,
    fontWeight: sizes.fontWeightBold,
  },
  btn: {
    width: '100%',
    marginBottom: sizes.xs,
  },
};

export default Home;
