import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, Image, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { sizes, colors, images, mixins } from 'theme';
import siteCopy from 'utils/siteCopy';
import { useRoute } from '@react-navigation/native';

import NavProvider from 'navigator/NavProvider';

const getMenuIcon = (name, isFocused) => {
  return <Icon name={name} size={30} color={isFocused ? colors.blue : colors.grayLight} />;
};

const AppFooter = ({ doShowSubPageHeader }) => {
  const route = useRoute();
  const navigation = useContext(NavProvider);

  let footerMenuItems = [
    {
      name: 'Home',
      icon: 'home',
    },
    {
      name: 'Game',
      icon: 'gamepad',
    },
    {
      name: 'Calibrate',
      icon: 'crosshairs',
    },
    {
      name: 'Calories',
      icon: 'fire',
    },
  ];

  return (
    <View style={styles.root}>
      <View style={styles.menuWrap}>
        {footerMenuItems.map((item, idx) => {
          const {
            name,
            icon
          } = item;
          const isFocused = route.name === name;

          const onPress = () => {
            if (!isFocused) {
              navigation.navigate(name);
            }
          };

          const onLongPress = () => {
            navigation.navigate(name);
          };

          return (
            <Pressable
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={name}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.menuItem}
              key={idx}>
              {getMenuIcon(icon, isFocused)}
              <Text style={[styles.menuText,
                {
                  color: isFocused ? colors.blue : colors.grayLight,
                }
              ]}>{name}</Text>
            </Pressable>
          );
        })}
      </View>
    </View>
  );
};

const styles = {
  root: {
    backgroundColor: colors.white,
    ...mixins.topBorder,
  },
  menuWrap: {
    marginHorizontal: sizes.xl,
    marginVertical: sizes.xs,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuItem: {
    alignItems: 'center',
  },
  menuText: {
    marginTop: sizes.frac4,
    fontSize: sizes.fonts.footerMenu,
  }
};

export default AppFooter;
