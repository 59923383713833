import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { colors, sizes } from 'theme';

import GameDot from 'components/GameDot';
import GameSpot from 'components/GameSpot';
import coordParser from 'utils/coordParser';
import {} from 'utils/constants';

const DotInTheSpot = ({
  gameDotData,
  gameDotSpeed,
  gameDotSetter,
  spotData,
  isColliding,
  gameSpotSpeed,
  gameSpotSetter,
}) => {
  const trackerWidth = coordParser.getTrackerWidth();

  const liveStyles = {
    trackerWrap: {
      width: trackerWidth,
      height: trackerWidth,
      borderRadius: trackerWidth / 2,
    },
  };

  return (
    <View style={[styles.trackerWrap, liveStyles.trackerWrap]}>
      <GameDot data={gameDotData} animSpeed={gameDotSpeed} setRealCoords={gameDotSetter} />
      <GameSpot
        data={spotData}
        isColliding={isColliding}
        animSpeed={gameSpotSpeed}
        setRealCoords={gameSpotSetter}
      />
    </View>
  );
};

const styles = {
  trackerWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.grayLightest,
  },
};

export default DotInTheSpot;
