// Ensure the font sizes look good on every device at every resolution
// Thanks https://medium.com/soluto-engineering/size-matters-5aeeb462900a
import { Dimensions } from 'react-native';
import { GUIDELINE_WIDTH_MIN, GUIDELINE_HEIGHT_MIN, GUIDELINE_WIDTH_WIDE } from 'utils/constants';
import { sizes } from 'theme';

const scale = (size) => (Dimensions.get('window').width / GUIDELINE_WIDTH_MIN) * size;
const verticalScale = (size) => ((Dimensions.get('window').height - sizes.headerHeight) / GUIDELINE_HEIGHT_MIN) * size;
const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;
const moderateScaleVertical = (size, factor = 0.5) => size + (verticalScale(size) - size) * factor;

const calcDrawerWidth = () => {
  const DRAWER_WIDTH_DEFAULT = 0.8;
  const DRAWER_WIDTH_MIN = GUIDELINE_WIDTH_MIN;
  const DRAWER_WIDTH_MAX = GUIDELINE_WIDTH_WIDE;
  const width = Dimensions.get('window').width;

  return width <= DRAWER_WIDTH_MIN
    ? width
    : width >= DRAWER_WIDTH_MAX
    ? DRAWER_WIDTH_MAX
    : width * DRAWER_WIDTH_DEFAULT;
};

export { scale, moderateScale, calcDrawerWidth, moderateScaleVertical };
