import React from 'react';
import { colors, sizes, images } from 'theme';
import { View, Text, Image } from 'react-native';
import { getDayProps } from 'utils/calendarUtils';
import { calcLastDayPercChange } from 'utils/dataUtils';
import { moderateScale } from 'utils/scaling';
import FontIcon from 'react-native-vector-icons/FontAwesome5';
import { calcSteps, calcMilesWalked, calcActiveStreak } from 'utils/dataUtils';

const dayProps = getDayProps();

const stateData = {
  dateText: `${dayProps.month} ${dayProps.day}, ${dayProps.year}`,
};

const strs = {
  statCalories: `calories`,
  getStatPercChange: (change) => `${change}% from yesterday`,
  getStatSteps: (steps) => `${steps} steps`,
  getStatMiles: (miles) => `${miles} miles`,
};

const MiniDashboard = ({ totalCals, indexPerDay }) => {
  const totalSteps = calcSteps(totalCals);
  const totalMilesWalked = calcMilesWalked(totalCals);
  const lastDayPercChange = calcLastDayPercChange(indexPerDay);
  const activeStreak = calcActiveStreak(indexPerDay);
  const styles = getStyles();

  return (
    <View style={styles.root}>
      <Text style={styles.headerTxt}>{stateData.dateText}</Text>

      <View style={styles.body}>
        <View style={styles.left}>
          <View style={styles.calCount}>
            <Text style={styles.statCalNum}>{totalCals}</Text>
            <Text style={styles.statCalText}>{strs.statCalories}</Text>
          </View>
          <View style={styles.stats}>
            <View style={styles.statsRow}>
              {/* the triangle icon doesn't load... so just scale up the caret */}
              <View
                style={[
                  styles.statsIcon,
                  {
                    transform: [{ scale: 1.5 }],
                  },
                ]}>
                {lastDayPercChange >= 0 ? (
                  <FontIcon name="caret-up" size={sizes.iconSizeSm} color={colors.green} />
                ) : (
                  <FontIcon name="caret-down" size={sizes.iconSizeSm} color={colors.red} />
                )}
              </View>
              <Text style={styles.statsRowTxt}>
                {strs.getStatPercChange(Math.abs(lastDayPercChange))}
              </Text>
            </View>
            <View style={styles.statsRow}>
              <View
                style={[
                  styles.statsIcon,
                  {
                    transform: [{ rotate: '-70deg' }],
                  },
                ]}>
                <FontIcon name="shoe-prints" size={sizes.iconSizeSm} color={colors.red} />
              </View>
              <Text style={styles.statsRowTxt}>{strs.getStatSteps(totalSteps)}</Text>
            </View>
            <View style={styles.statsRow}>
              <View style={styles.statsIcon}>
                <FontIcon name="route" size={sizes.iconSizeSm} color={colors.red} />
              </View>
              <Text style={styles.statsRowTxt}>{strs.getStatMiles(totalMilesWalked)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.bigCircle}>
          <View style={styles.circleBg}></View>
          <View style={styles.circleStatTextWrap}>
            <Text style={styles.circleStatText}>{activeStreak}</Text>
          </View>
          <Image source={images.activeSittingStreak} style={styles.arcText} />
        </View>
      </View>
    </View>
  );
};

const statsRowMargin = sizes.iconSizeSm / 4;

const getStyles = () => {
  const marginStandard = moderateScale(sizes.base) / 2;
  const circleSize = moderateScale(sizes.contentBoxWidth) / 3.25;
  // adapt the huge font to the circle which is adapted to the page width
  const HugeFontSize = circleSize / 2.5;
  // fine-tune padding in proportion
  const HugeFontSizeMargin = HugeFontSize / 10;

  return {
    root: {
      padding: marginStandard,
    },
    headerTxt: {
      color: colors.gray,
    },
    body: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    left: {
      flex: 1,
    },
    bigCircle: {
      position: 'relative',
    },
    calCount: {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    statCalNum: {
      color: colors.red,
      fontSize: HugeFontSize,
      paddingRight: HugeFontSizeMargin,
    },
    statCalText: {
      fontSize: HugeFontSize / 3,
      fontWeight: sizes.fontWeightBold,
      color: colors.red,
    },
    stats: {},
    statsRow: {
      flexDirection: 'row',
      marginVertical: statsRowMargin,
    },
    statsRowTxt: {
      // fontSize: sizes.sm,
      // lineHeight: 0,
    },
    statsIcon: {
      textAlign: 'center',
      paddingHorizontal: statsRowMargin,
      width: 24,
    },
    circleBg: {
      borderRadius: circleSize / 2,
      width: circleSize,
      height: circleSize,
      backgroundColor: colors.red,
      justifyContent: 'center',
      alignItems: 'center',
    },
    arcText: {
      width: circleSize,
      height: circleSize,
      transform: [{ scale: 0.9 }],
      position: 'absolute',
    },
    circleStatTextWrap: {
      width: circleSize,
      height: circleSize,
      position: 'absolute',
      flexDirection: 'row',
      alignItems: 'center',
      justifyItems: 'center',
    },
    circleStatText: {
      flex: 1,
      textAlign: 'center',
      fontSize: HugeFontSize,
      // nudge it down because of the text above.
      marginTop: circleSize / 12,
    },
  };
};

export default MiniDashboard;
