import React from 'react';
import { Text, View, ScrollView, Platform } from 'react-native';
import Masthead from 'components/Masthead';
import { sizes, colors } from 'theme';
import { moderateScale } from 'utils/scaling';
import { PAGE_FRAME_MAX_WIDTH, GUIDELINE_WIDTH_WIDE } from 'utils/constants';

const PageFrameCenter = ({children, ...props}) => {
  const liveStyles = {
    body: {
      width: getbodyWidth(),
    },
  };

  const shouldShowMasthead = !!props.shouldShowMasthead;
  let shouldShowVertScroll;
  if (typeof props.showsVerticalScrollIndicator !== 'undefined') {
    shouldShowVertScroll = props.showsVerticalScrollIndicator;
  } else {
    shouldShowVertScroll = Platform.OS === 'web';
  }

  return (
    <ScrollView showsVerticalScrollIndicator={shouldShowVertScroll} style={styles.root}>
      {
        shouldShowMasthead ? <Masthead /> : null
      }
      <View style={styles.wrap}>
        <View style={[styles.body, liveStyles.body, props.style]}>{children}</View>
      </View>
    </ScrollView>
  );
};

const getbodyWidth = () => {
  let mainWidth = moderateScale(sizes.contentBoxWidth);

  if (mainWidth > PAGE_FRAME_MAX_WIDTH) {
    mainWidth = PAGE_FRAME_MAX_WIDTH;
  }

  const shouldClamp = mainWidth < GUIDELINE_WIDTH_WIDE;

  if (shouldClamp) {
    mainWidth = '100%';
  }

  return mainWidth;
};

const styles = {
  root: {
    flex: 1,
    backgroundColor: colors.white,
  },
  wrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    flex: 1,
    // default padding. Can be overwritten if needed.
    padding: sizes.base,
  },
};

export default PageFrameCenter;
