import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { sizes, colors } from 'theme';
import siteCopy from 'utils/siteCopy';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import BarChartNoData from 'components/BarChart/BarChartNoData';
import NavProvider from 'navigator/NavProvider';
import { saveIsActive } from 'slices/activeSession.slice';
import SocketProvider from 'utils/SocketController/SocketProvider';
import { CHART_TYPES, SOCKET_ACTIONS, IS_PORTAL } from 'utils/constants';

const BarChartNoDataWrap = ({
  children,
  shouldNavigateToHome = true,
  shouldActivateSession = true,
}) => {
  const { portalCode } = useSelector((state) => state.app);
  const Navigator = useContext(NavProvider);
  const dispatch = useDispatch();
  const socketProvider = useContext(SocketProvider);
  const socket = socketProvider.getSocket();

  const toggleSessionIsActivePortal = (bool = false) => {
    socket.emit(SOCKET_ACTIONS.dispatchProxy, {
      shortCode: portalCode,
      // todo: consider adding these strings to constants?
      slice: 'activeSession',
      reducer: 'saveIsActive',
      value: bool,
    });
  };

  const toggleSessionIsActive = (bool = false) => {
    dispatch(saveIsActive(bool));
  };

  return (
    <View style={styles.root}>
      {children ? children : <BarChartNoData />}
      <View style={styles.overlay}>
        <View style={styles.backdrop} />
        <View>
          <Text style={styles.overlayText}>{siteCopy.noDataNotice}</Text>
          <ButtonPrimary
            title={siteCopy.noDataStartSessionCTA}
            onPress={() => {
              if (shouldNavigateToHome) {
                Navigator.navigate('Calibrate');
              }
              if (shouldActivateSession) {
                if (IS_PORTAL) {
                  toggleSessionIsActivePortal(true);
                } else {
                  toggleSessionIsActive(true);
                }
              }
            }}
            style={styles.btn}
          />
        </View>
      </View>
    </View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  backdrop: {
    backgroundColor: colors.white,
    opacity: 0.9,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  overlayText: {
    color: colors.gray,
    fontStyle: 'italic',
    marginBottom: sizes.base / 2,
  },
  btn: {},
};

export default BarChartNoDataWrap;
