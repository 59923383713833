import React from 'react';
import { colors, sizes, charts } from 'theme';
import { View, Text } from 'react-native';
import { getDataForDays, roundNicely } from 'utils/dataUtils';

import { CHART_DATA_TYPES, DAYS_IN_WEEK, CHART_TYPES } from 'utils/constants';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from 'victory-native';

const getTickTxt = (data, numDays) => {
  const monthAndDay = `${data.monthShort} ${data.day}`;
  return numDays > DAYS_IN_WEEK ? monthAndDay : data.weekdayShort;
};

const BarChartDays = ({ indexPerDay, chartDataType=CHART_TYPES.week, numDays = DAYS_IN_WEEK }) => {
  // dataType will be either calories or minutesActive.
  const dataType = chartDataType === CHART_DATA_TYPES.minutesActive ? 'minutes' : 'calories';

  // refetch daysData with the right number of days, because indexPerDay might not have enough.
  const daysData = getDataForDays(numDays).map((data, idx) => {
    const record = indexPerDay[data.dayKey] || {};
    const calories = record.calories || 0;
    const minutes = roundNicely((record.secondsActive || 0) / 60);

    const rest =
      chartDataType === CHART_DATA_TYPES.minutesActive
        ? {
            // note: #victoryChartLabel: This label makes the victorychart tooltip work
            label: `${minutes} Minutes`,
            minutes: minutes,
          }
        : {
            // note: #victoryChartLabel: This label makes the victorychart tooltip work
            label: `${calories} Calories`,
            calories: calories,
          };

    return {
      ...data,
      // note: #dontCloberExistingProps use an underscore for this value so it doesn't clober our day key which means something different
      _day: idx + 1,
      ...rest,
    };
  });

  return (
    <View style={styles.root}>
      <VictoryChart
        domainPadding={{
          x: charts.domainPadding,
          y: charts.domainPadding,
        }}>
        <VictoryBar
          labelComponent={<VictoryTooltip renderInPortal={false} />}
          data={daysData}
          x="_day"
          y={dataType}
          style={{ data: { fill: colors.red } }}
        />
        <VictoryAxis
          tickFormat={daysData.map((date) => getTickTxt(date, numDays))}
          fixLabelOverlap={true}
        />
      </VictoryChart>
    </View>
  );
};

const styles = {
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default BarChartDays;
