import React from 'react';
import { Text, View, Image } from 'react-native';
import { colors, sizes, images } from 'theme';
import { imgRatios } from 'theme/images';
import { calcDrawerWidth } from 'utils/scaling';
import { LinearGradient } from 'expo-linear-gradient';

// unicode to get the TM symbol
const tm = '\u{2122}';

const strs = {
  slogan: `Sit Better. Live Better.${tm}`,
  companyName: `QOR360`,
};

const Masthead = ({ style }) => {
  return (
    <View style={[styles.root, style]}>
      <View style={styles.mastheadImgWrap}>
        <Image source={images.chairBg} style={styles.mastheadImg} />
        <LinearGradient
          style={styles.backgroundGradient}
          colors={['rgba(0,0,0,0.2)', 'transparent', 'rgba(0,0,0,0.8)']}
        />
      </View>
      <View style={styles.mastheadTextWrap}>
        <Text style={[styles.mastheadTitle, styles.mastheadText]}>{strs.companyName}</Text>
        <Text style={[styles.mastheadSubTitle, styles.mastheadText]}>{strs.slogan}</Text>
      </View>
    </View>
  );
};

const styles = {
  root: {
    width: '100%',
  },
  mastheadImgWrap: {
    height: calcDrawerWidth() * imgRatios.chairBg,
  },
  backgroundGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  mastheadImg: {
    resizeMode: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  mastheadTextWrap: {
    position: 'absolute',
    bottom: 0,
    padding: sizes.base,
  },
  mastheadText: {
    color: colors.white,
  },
  mastheadTitle: {
    fontSize: sizes.lg,
    fontWeight: sizes.fontWeightBold,
  },
  mastheadSubTitle: {
    fontSize: sizes.md,
  },
};

export default Masthead;
